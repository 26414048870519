import React, { useState } from 'react';
import { Container, Box, Typography, Grid, TextField, Button, CssBaseline, Link } from '@mui/material';
import PageHeader from '../../components/header/PageHeader';
import NavBar from '../../components/appBar/AppBar';
import NewNavBar from '../../components/appBar/NewNavBar';

const RequestPasswordReset = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission, e.g., send the email or username to the server
    console.log('Request password reset for:', emailOrUsername);
  };

  return (
    <>
      <div
        style={{
          background: 'linear-gradient(to right, #FCCF00,#002252, #002252)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '160px',
            left: '-125px',
            width: '250px',
            height: '250px',
            background: 'rgba(255, 255, 255, 0.2)',
            transform: 'rotate(45deg)',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '-250px',
            left: '20px',
            width: '600px',
            height: '300px',
            background: 'rgba(255, 255, 255, 0.1)',
            transform: 'rotate(45deg)',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '100px',
            right: '-200px',
            width: '300px',
            height: '400px',
            background: 'rgba(255, 255, 255, 0.1)',
            transform: 'rotate(45deg)',
            zIndex: 1,
          }}
        />
        <NewNavBar />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
          }}
        >
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 2,
                borderRadius: 5,
                padding: 4,
                backgroundColor: 'white',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  mt: 3,
                }}
              >
                <span
                  style={{
                    borderBottom: '3px solid #002252',
                    mr: '-4px',
                    pr: '4px',
                    color: '#FCCF00',
                  }}
                >
                  Forgot {"\u00A0"}
                </span>
                <span
                  style={{
                    borderBottom: '3px solid #FCCF00',
                    color: '#002252',
                    ml: '-4px',
                    pl: '4px',
                  }}
                >
                  Password
                </span>
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3, width: '100%' }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      fullWidth
                      id="emailOrUsername"
                      name="emailOrUsername"
                      label="Email or Username"
                      autoComplete="username"
                      onChange={(e) => {
                        setEmailOrUsername(e.target.value);
                        setError('');
                      }}
                      error={!!error}
                      helperText={error}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 3,
                    background:
                      "#002252",
                  }}
                >
                  REQUEST PASSWORD RESET
                </Button>
                <Grid container justifyContent="center">
                  <Grid item>
                    <span style={{fontSize:"15px", color:'black'}}>Back to </span>
                    <Link
                      href="/"
                      variant="body2"
                      sx={{ color: "#002252", textDecoration: 'none' }}
                    >
                      Login
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default RequestPasswordReset;
