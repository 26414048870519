import React, { useState } from "react";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
} from "../../config/axiosConfig";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import StateSelect from "../../components/stateSelect/StateSelect";
import NewNavBar from "../../components/appBar/NewNavBar";

const CustomerReg = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    username: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    password: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactError, setContactError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [focusedField, setFocusedField] = useState(null);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    setFocusedField(name); // Update the focused field

    if (name === "password") {
      validatePassword(value);
    }
  };

  const handleFocusChange = async () => {
    if (focusedField === "username") {
      try {
        const authToken = getAuthToken();
        if (!authToken) {
          console.error("Authentication token not available.");
          return;
        }

        const response = await request(
          axiosInstance1,
          "GET",
          `/checkCustomer/${formData.username}`
        );
        console.log("Username check response:", response);

        if (response.data.exists) {
          setUsernameError("Username already exists.");
        } else {
          setUsernameError("");
        }
      } catch (error) {
        console.error("Error checking username:", error);
        setUsernameError("Error checking username");
      }
    }

    if (focusedField === "emailAddress") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.emailAddress)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }

    if (focusedField === "contactNumber") {
      const contactNumberRegex = /^[0-9]{10}$/;
      if (!contactNumberRegex.test(formData.contactNumber)) {
        setContactError("Please enter a valid contact number.");
      } else {
        setContactError("");
      }
    }
  };

  const validatePassword = (password) => {
    const passwordPolicy = {
      minLength: 8,
      hasUpperCase: /[A-Z]/,
      hasLowerCase: /[a-z]/,
      hasNumber: /\d/,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
    };

    let errorMessage = "";
    if (password.length < passwordPolicy.minLength) {
      errorMessage += `Password must be at least ${passwordPolicy.minLength} characters long. `;
    }
    if (!passwordPolicy.hasUpperCase.test(password)) {
      errorMessage += "Password must contain at least one uppercase letter. ";
    }
    if (!passwordPolicy.hasLowerCase.test(password)) {
      errorMessage += "Password must contain at least one lowercase letter. ";
    }
    if (!passwordPolicy.hasNumber.test(password)) {
      errorMessage += "Password must contain at least one digit. ";
    }
    if (!passwordPolicy.hasSpecialChar.test(password)) {
      errorMessage += "Password must contain at least one special character. ";
    }

    setPasswordError(errorMessage);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic form validation
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.emailAddress ||
      !formData.username ||
      !formData.contactNumber ||
      !formData.address ||
      !formData.city ||
      !formData.state ||
      !formData.postalCode ||
      !formData.password
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance1,
        "POST",
        "/saveCustomer",
        formData
      );
      console.log(response);

      setSuccessMessage("Registration successful.");
      setErrorMessage("");
      console.log(successMessage);

      // Clear form after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        username: "",
        contactNumber: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        password: "",
      });
    } catch (error) {
      setAuthHeader(null);
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    }
  };

  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      emailAddress: "",
      username: "",
      contactNumber: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      password: "",
    });
    setSuccessMessage("");
    setErrorMessage("");
    setPasswordError("");
  };

  return (
    <>
      <NewNavBar />
      <Container component="main">
        <CssBaseline />
        <Paper
          sx={{
            width: "90%",
            maxWidth: 800,
            padding: 3,
            margin: "auto",
            marginTop: 5,
          }}
        >
          <Box
            sx={{
              background: "linear-gradient(to right,#002252, #FCCF00)",
              padding: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontWeight: "bold",
                marginBottom: 2,
                margin: "auto",
              }}
            >
              Customer Registration
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
          >
            <form onSubmit={handleSubmit} onBlur={handleFocusChange}>
              <Grid container spacing={2}>
                {/* First Name */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>First Name:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Last Name */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Last Name:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Username */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Username:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    error={!!usernameError}
                    helperText={usernameError}
                  />
                </Grid>
                {/* Email Address */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    Email Address:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="emailAddress"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Grid>
                {/* Contact Number */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    Contact Number:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    error={!!contactError}
                    helperText={contactError}
                  />
                </Grid>
                {/* Address */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Address:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="address"
                    name="address"
                    multiline
                    rows={2}
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                {/* City */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>City:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </Grid>
                {/* State */}
                <Grid item xs={12} sm={6}>
                <StateSelect formData={formData} handleChange={handleChange} />
                </Grid>
                {/* Postal Code */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Postal Code:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Password */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Password:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Grid>
              </Grid>
              {/* Success message */}
              {successMessage && (
                <Typography sx={{ color: "green", textAlign: "center" }}>
                  {successMessage}
                </Typography>
              )}
              {/* Error message */}
              {errorMessage && (
                <Typography sx={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 2,
                  background:
                    "#002252",
                }}
              >
                Submit
              </Button>
              <Button
                onClick={handleClearForm}
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                  mb: 2,
                  borderRadius: 2,
                }}
              >
                Clear Form
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default CustomerReg;
