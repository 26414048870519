import React from 'react';
import Carousel from 'react-material-ui-carousel'; // Import Carousel component
import { Paper, Button } from '@mui/material'; // Import Paper and Button from Material-UI

// Define Example functional component
function Example(props) {
    // Array of items for the carousel
    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        },
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        }
    ];

    return (
        <Carousel
            navButtonsAlwaysVisible={false} // Make navigation buttons always visible
            animation="slide"
            autoPlay="true"
            duration={500} // Optional: Set animation type
        >
            {/* Map through items and render each item using the Item component */}
            {items.map((item, i) => <Slider key={i} item={item} />)}
        </Carousel>
    );
}

// Define Item functional component to render individual items
function Slider(props) {
    return (
        <Paper className='flex flex-col items-center justify-center pt-10 mx-0 mb-16 shadow-none'>
            {/* Display name and description of the item */}
            <div className='flex flex-row w-full h-[200px]'>
                <div className='w-full bg-[#FCCF00] h-[180px] flex-col justify-center items-start flex px-3'>
                
                    <div className='flex flex-row items-center justify-center w-full'>
                        <div className='pr-12'>
                            <img src='homeIcons.svg' alt='' width={100} height={100} />
                        </div>
                        <div className='flex flex-col text-black'>
                            <div className='text-2xl font-black md:text-2xl lg:text-3xl xl:text-4xl'>Track Your Tasks</div>
                            <div className='pt-4 text-sm font-bold md:text-lg xl:text-xl'>As  a hire a electrician, you get many benefits </div>
                        </div>
                        <div className='flex-col justify-center hidden h-full pl-32 md:flex'>
                            <button className='w-[130px] py-2 text-xs font-bold rounded-sm bg-[#FFFFFF] text-[#002252] md:text-sm md:w-[150px] md:font-semibold lg:text-base lg:w-[180px] xl:w-[220px] xl:text-lg 2xl:w-[280px] 2xl:text-xl'>TRACK TASKS</button>
                        </div>
                    </div>
                   
                        <button className='md:hidden mt-3 w-[130px] py-2 text-xs font-bold rounded-sm bg-[#FFFFFF] text-[#002252]'>TRACK TASKS</button>
                   
                </div>

            </div>
        </Paper>

    );
}

export default Example; // Export Example component
