import * as React from "react";

import { request, setAuthHeader ,axiosInstance1 } from "../../config/axiosConfig";
import LoginForm from "../login/LoginForm";
import Home from "../home/Home";
import AdminDashboard from "../dashboard/Dashboard";

export default class AppContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToShow: "login",
    };
  }

  onLogin = (e, username, password) => {
    e.preventDefault();
    request(axiosInstance1,"POST", "/login", {
      login: username,
      password: password,
    })
    .then((response) => {
      console.log("Login response:", response); // Check response data in console
      if (!response.data) {
        throw new Error("Invalid response data");
      }
      // Assuming response contains user data including id, firstName, lastName, companyId
      localStorage.setItem('user', JSON.stringify(response.data)); // Store entire user object
      localStorage.setItem('userid', response.data.id);
      localStorage.setItem('firstName', response.data.firstName);
      localStorage.setItem('lastName', response.data.lastName);
      localStorage.setItem('companyId', response.data.companyId);
      setAuthHeader(response.data.token);
      // Redirect to adminDash
      // this.props.history.push("/adminDash");
      this.setState({ componentToShow: "adminDash" });
    })
    .catch((error) => {
      console.error("Login error:", error); // Log any errors
      setAuthHeader(null);
      this.setState({ componentToShow: "login" });
    });
  };
  

  onRegister = (event, firstName, lastName, username, password) => {
    event.preventDefault();
    request(axiosInstance1,"POST", "/register", {
      firstName: firstName,
      lastName: lastName,
      login: username,
      password: password,
    })
      .then((response) => {
        setAuthHeader(response.data.token);
        this.setState({ componentToShow: "adminDash" });
      })
      .catch((error) => {
        setAuthHeader(null);
        this.setState({ componentToShow: "login" });
      });
  };

  render() {
    return (
      <>
        <p></p>
        {this.state.componentToShow === "login" && (
          <LoginForm onLogin={this.onLogin} onRegister={this.onRegister} />
        )}
        {this.state.componentToShow === "adminDash" && <AdminDashboard />}
      </>
    );
  }
}
