import React from "react";
import "./App.css";

const courses = [
  {
    id: 1,
    title: "Unconscious Bias",
    duration: "25mins",
    price: "$15.90",
    type: "Free",
    provider: "cloud academy",
    rating: 4.5,
    reviews: 12435,
    isPopular: true,
  },
  {
    id: 2,
    title: "Communication",
    duration: "50mins",
    price: "$15.90",
    type: "Paid",
    provider: "Khan Academy",
    rating: 4.8,
    reviews: 30456,
    isBestSeller: true,
  },
  {
    id: 3,
    title: "Critical Thinking",
    duration: "1 hour 12mins",
    price: "$10.90",
    type: "Paid",
    provider: "Khan Academy",
    rating: 4.7,
    reviews: 28435,
    isBestSeller: true,
  },
];

const certificationCourses = [
  {
    id: 4,
    title: "Team Work",
    duration: "25mins",
    price: "$19.90",
    type: "Paid",
    provider: "cloud academy",
    rating: 4.8,
    reviews: 24100,
    isBestSeller: true,
  },
  {
    id: 5,
    title: "Leadership Skills",
    duration: "50mins",
    price: "$18.90",
    type: "Paid",
    provider: "Khan Academy",
    rating: 4.9,
    reviews: 78338,
    isBestSeller: true,
  },
  {
    id: 6,
    title: "Statistics",
    duration: "1 hour 12mins",
    price: "$10.90",
    type: "Paid",
    provider: "Khan Academy",
    rating: 4.8,
    reviews: 25434,
  },
];

const tutors = [
  {
    id: 1,
    name: "Robert James",
    specialization: "UI/UX Designer",
    courses: 56,
    rating: 4.9,
    reviews: 75135,
  },
  {
    id: 2,
    name: "Jessica Thomas",
    specialization: "Graphic Designer",
    courses: 62,
    rating: 4.9,
    reviews: 57333,
  },
  {
    id: 3,
    name: "Selena Mathew",
    specialization: "Full Stack Developer",
    courses: 37,
    rating: 4.8,
    reviews: 60966,
  },
  {
    id: 4,
    name: "Tom Henry",
    specialization: "SQL/Tableau",
    courses: 42,
    rating: 4.8,
    reviews: 38973,
  },
];

function Student() {
  return (
    <div className="App font-sans">
      <header className="bg-teal-800 text-white py-6">
        <nav className="container mx-auto flex justify-between items-center px-6">
          <div className="text-2xl font-bold">CourseMania</div>
          <ul className="flex space-x-4">
            <li>
              <a href="#home" className="hover:text-gray-300">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="hover:text-gray-300">
                About
              </a>
            </li>
            <li>
              <a href="#courses" className="hover:text-gray-300">
                Courses
              </a>
            </li>
            <li>
              <a href="#tutors" className="hover:text-gray-300">
                Tutors
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:text-gray-300">
                Contact
              </a>
            </li>
          </ul>
          <div className="flex items-center space-x-4">
            <button className="bg-teal-700 py-2 px-4 rounded hover:bg-teal-600">
              Login
            </button>
            <button className="bg-teal-600 py-2 px-4 rounded hover:bg-teal-500">
              Register
            </button>
            <div className="relative">
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2">
                1
              </span>
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 3h18l-2 13H5L3 3z"
                />
              </svg>
            </div>
          </div>
        </nav>
        <div className="text-center mt-8">
          <h1 className="text-4xl font-semibold">
            Explore What Professionals Like You Are Learning The Most
          </h1>
          <button className="mt-6 bg-teal-700 py-2 px-4 rounded hover:bg-teal-600">
            Visit Courses
          </button>
        </div>
      </header>

      <section className="my-8">
        <div className="container mx-auto flex justify-center space-x-8 px-6">
          <img src="udemy.png" alt="Udemy" className="h-12" />
          <img src="khanacademy.png" alt="Khan Academy" className="h-12" />
          <img src="cloudacademy.png" alt="Cloud Academy" className="h-12" />
          <img src="coursera.png" alt="Coursera" className="h-12" />
        </div>
      </section>

      <section className="my-8">
        <div className="container mx-auto px-6">
          <h2 className="text-2xl font-semibold mb-4">Popular Courses</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {courses.map((course) => (
              <div
                key={course.id}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h3 className="text-xl font-semibold">{course.title}</h3>
                <p>Duration: {course.duration}</p>
                <p>Price: {course.price}</p>
                <p>Provider: {course.provider}</p>
                <p>
                  Rating: {course.rating} ({course.reviews} reviews)
                </p>
                <button className="mt-4 bg-teal-700 py-2 px-4 rounded hover:bg-teal-600">
                  {course.type === "Free" ? "Enroll Now" : "Buy Now"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="my-8">
        <div className="container mx-auto px-6">
          <h2 className="text-2xl font-semibold mb-4">
            Certifications Courses
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {certificationCourses.map((course) => (
              <div
                key={course.id}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h3 className="text-xl font-semibold">{course.title}</h3>
                <p>Duration: {course.duration}</p>
                <p>Price: {course.price}</p>
                <p>Provider: {course.provider}</p>
                <p>
                  Rating: {course.rating} ({course.reviews} reviews)
                </p>
                <button className="mt-4 bg-teal-700 py-2 px-4 rounded hover:bg-teal-600">
                  Buy Now
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="my-8">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">Instructed Courses</h2>
          <p className="mb-6">
            Want Someone To Instruct You? No Worries, Here We Introduce Our
            CourseMania’s Online Tutors To Assist & Guide You In Your
            Professional Path
          </p>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {tutors.map((tutor) => (
              <div key={tutor.id} className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-semibold">{tutor.name}</h3>
                <p>Specialization: {tutor.specialization}</p>
                <p>Courses: {tutor.courses}</p>
                <p>
                  Rating: {tutor.rating} ({tutor.reviews} reviews)
                </p>
              </div>
            ))}
          </div>
          <button className="mt-8 bg-teal-700 py-2 px-4 rounded hover:bg-teal-600">
            Find A Tutor
          </button>
        </div>
      </section>

      <footer className="bg-teal-800 text-white py-6">
        <div className="container mx-auto px-6 flex justify-between">
          <div className="space-y-2">
            <a href="#about" className="block hover:text-gray-300">
              About Us
            </a>
            <a href="#contact" className="block hover:text-gray-300">
              Contact Us
            </a>
            <a href="#udemy-business" className="block hover:text-gray-300">
              Udemy Business
            </a>
            <a href="#teach" className="block hover:text-gray-300">
              Teach On Udemy
            </a>
            <a href="#careers" className="block hover:text-gray-300">
              Careers
            </a>
            <a href="#help" className="block hover:text-gray-300">
              Help And Support
            </a>
            <a href="#privacy" className="block hover:text-gray-300">
              Privacy Policy
            </a>
          </div>
          <div className="space-y-2">
            <a href="#facebook" className="block hover:text-gray-300">
              Facebook
            </a>
            <a href="#twitter" className="block hover:text-gray-300">
              Twitter
            </a>
            <a href="#instagram" className="block hover:text-gray-300">
              Instagram
            </a>
            <a href="#linkedin" className="block hover:text-gray-300">
              LinkedIn
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Student;
