import React from 'react';
import { Typography, Card, CardContent, Grid } from '@mui/material';

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    color: '#333',
  },
  card: {
    marginBottom: '20px',
  },
};

const OurServices = () => {
  return (
    <div>
      {/* <PageHeader />
      <NavBar /> */}
      <div style={styles.container}>
        <Typography variant="h2" style={{ color: '#c51383', marginBottom: '20px' }}>
          Our Services
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card style={styles.card}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Gas Line Services
                </Typography>
                <Typography variant="body1">
                  - Gas line installation <br />
                  - Gas line maintenance <br />
                  - Gas line repair <br />
                  - Gas appliance installation <br />
                  - Safety inspections
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card style={styles.card}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Electrical Services
                </Typography>
                <Typography variant="body1">
                  - Electrical system installation <br />
                  - Electrical system troubleshooting <br />
                  - Electrical upgrades <br />
                  - Lighting installation <br />
                  - Outlet installation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OurServices;
