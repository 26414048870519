// StateSelect.js
import React from 'react';
import { Grid, InputLabel, Select, MenuItem } from '@mui/material';

const australianStates = [
  { id: "NSW", name: "New South Wales" },
  { id: "VIC", name: "Victoria" },
  { id: "QLD", name: "Queensland" },
  { id: "SA", name: "South Australia" },
  { id: "WA", name: "Western Australia" },
  { id: "TAS", name: "Tasmania" },
  { id: "ACT", name: "Australian Capital Territory" },
  { id: "NT", name: "Northern Territory" },
];

const StateSelect = ({ formData, handleChange }) => {
  return (
    <Grid item xs={12} sm={12}>
      <InputLabel sx={{ fontSize: "2vh" }}>State:</InputLabel>
      <Select
        size="small"
        name="state"
        displayEmpty
        required
        value={formData.state}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value="">
          <em>Select</em>
        </MenuItem>
        {australianStates.map((state) => (
          <MenuItem key={state.id} value={state.name}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default StateSelect;
