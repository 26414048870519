import React from 'react';
import Carousel from 'react-material-ui-carousel'; // Import Carousel component
import { Paper, Button } from '@mui/material'; // Import Paper and Button from Material-UI

// Define Example functional component
function Example(props) {
  // Array of items for the carousel
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!"
    },
    {
      name: "Random Name #2",
      description: "Hello World!"
    },
    {
      name: "Random Name #2",
      description: "Hello World!"
    },
    {
      name: "Random Name #2",
      description: "Hello World!"
    }
  ];

  return (
    <Carousel
      navButtonsAlwaysVisible={true} // Make navigation buttons always visible
      animation="slide"
      autoPlay="false"
      duration={500} // Optional: Set animation type
    >
      {/* Map through items and render each item using the Item component */}
      {items.map((item, i) => <Item key={i} item={item} />)}
    </Carousel>
  );
}

// Define Item functional component to render individual items
function Item(props) {
  return (
    <Paper className='flex flex-col items-center justify-center mx-16 mb-16 shadow-none'>
      {/* Display name and description of the item */}
      <div className='flex flex-row w-full h-[400px] md:h-[450px] lg:h-[400px] lg:justify-between sm:h-[350px] xl:h-[500px] items-center justify-center'>
        <div className='hidden sm:flex'>
          <div className='md:w-[280px] w-[300px] h-[350px] sm:w-[230px] sm:h-[300px] lg:h-[365px] lg:w-[265px] md:h-[400px] xl:h-[450px] xl:w-[350px] mx-3 border-2 border-[#002252] rounded-md flex flex-col justify-end items-center'>
            <div className='absolute flex flex-col items-center h-[500px] w-full justify-center'>
            <div className='sm:pt-56'>
              <img src='person.svg' alt='' className='sm:mt-28 md:mt-48' /></div>
              <div className='text-sm md:text-base font-lg sm:text-xs  text-white w-[300px] pt-10 px-10 md:mt-3 text-center sm:pt-10 xl:text-lg xl:px-2'><span className='text-xl md:text-3xl'>"</span>
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.<span className='text-xl md:text-3xl'>"</span>
              </div>
              <p className='flex flex-row justify-center mt-3 text-base font-bold text-white md:text-lg lg:text-xl sm:text-sm sm:mt-0 sm:pb-32 md:mb-36 xl:text-2xl xl:mb-44'>Samson Perera</p>
            </div>
            <div className='w-full bg-[#002252] rounded-t-3xl h-2/3'></div>
          </div>
        </div>

        

        <div className='md:w-[280px] md:h-[400px] lg:w-[265px] w-[300px] h-[350px] lg:h-[365px] xl:h-[450px] xl:w-[350px] sm:w-[230px] sm:h-[300px] mx-3 border-2 border-[#002252] rounded-md flex flex-col justify-end items-center'>
          <div className='absolute flex flex-col items-center h-[500px] w-full justify-center'>
            <img src='person.svg' alt='' className='mt-32 sm:pt-56 sm:mt-28 md:mt-48' />
            <div className='text-sm md:text-base lg:text-base font-lg md:mt-3  text-white w-[300px] sm:text-xs pt-10 px-10  text-center sm:pt-10 xl:text-lg xl:px-2'><span className='text-xl md:text-3xl'>"</span>
              Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.<span className='text-xl md:text-3xl'>"</span>
            </div>
            <p className='flex flex-row justify-center mt-3 text-base font-bold text-white lg:text-xl sm:text-sm sm:mt-0 sm:pb-32 md:text-lg md:mb-36 xl:text-2xl xl:mb-44'>Samson Perera</p>
          </div>
          <div className='w-full bg-gradient-to-tr from-[#FCCF00] to-[#002252] rounded-t-3xl h-2/3'></div>
        </div>

        <div className='hidden lg:flex'>
          <div className='md:w-[280px] w-[300px] h-[350px] sm:w-[230px] sm:h-[300px] lg:h-[365px] xl:h-[450px] xl:w-[350px] lg:w-[265px] md:h-[400px] mx-3 border-2 border-[#002252] rounded-md flex flex-col justify-end items-center'>
            <div className='absolute flex flex-col items-center h-[500px] w-full justify-center'>
              <div className='sm:pt-56'>
                <img src='person.svg' alt='' className='sm:mt-28 md:mt-48' /></div>
              <div className='text-sm md:text-base font-lg sm:text-xs  text-white w-[300px] pt-10 px-10 md:mt-3 text-center sm:pt-10 xl:text-lg xl:px-2'><span className='text-xl md:text-3xl'>"</span>
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.<span className='text-xl md:text-3xl'>"</span>
              </div>
              <p className='flex flex-row justify-center mt-3 text-base font-bold text-white md:text-lg lg:text-xl sm:text-sm sm:mt-0 sm:pb-32 md:mb-36 xl:text-2xl xl:mb-44'>Samson Perera</p>
            </div>
            <div className='w-full bg-gradient-to-tr from-[#FCCF00] to-[#002252] rounded-t-3xl h-2/3'></div>
          </div>
        </div>
      </div>
    </Paper>

  );
}

export default Example; // Export Example component
