import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance2,
  axiosInstance4,
  axiosInstance1,
} from "../../config/axiosConfig";
import { pdf } from "@react-pdf/renderer";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import ElectricReport from "./ElectricReport";
import GasReport from "./GasReport";
import SmokeReport from "./SmokeReport";
// import SmokeReport from "./SmokeReport";

const ReportsPage = () => {
  const { inquiryId } = useParams();

  const [inquiryData, setInquiryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checklistApproved, setChecklistApproved] = useState(null);
  const [checklistStatus, setChecklistStatus] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [approving, setApproving] = useState(null);
  const [rejecting, setRejecting] = useState(null);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [signature, setSignature] = useState(null);
  const reportRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const { inquiry } = location.state || {};
  const { customer } = location.state || {};

  const reportData = inquiry || customer;

  const [role, setRole] = React.useState(null);

  React.useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    setRole(user.roleId);
    //  console.log(user.roleId);
  }, []);

  useEffect(() => {
    // Fetch task data when component mounts
    fetchTaskData();
    
    
  }, [inquiryId]);

  useEffect(() => {
    fetchTaskData();
  }, [checklistStatus]);

  const transformData = (data) => {
    const result = {};

    data.forEach((item) => {
      const inquiryId = item.inquiryId;

      if (!result[inquiryId]) {
        result[inquiryId] = { inquiryId, titles: [] };
      }

      const titleIndex = result[inquiryId].titles.findIndex(
        (t) => t.title === item.title
      );

      if (titleIndex === -1) {
        result[inquiryId].titles.push({
          title: item.title,
          subTitles: [
            {
              subTitle: item.subTitle,
              descriptions: [
                { description: item.description, status: item.status, remarks: item.remarks, },
              ],
            },
          ],
        });
      } else {
        const subTitleIndex = result[inquiryId].titles[
          titleIndex
        ].subTitles.findIndex((st) => st.subTitle === item.subTitle);

        if (subTitleIndex === -1) {
          result[inquiryId].titles[titleIndex].subTitles.push({
            subTitle: item.subTitle,
            descriptions: [
              { description: item.description, status: item.status, remarks: item.remarks, },
            ],
          });
        } else {
          result[inquiryId].titles[titleIndex].subTitles[
            subTitleIndex
          ].descriptions.push({
            description: item.description,
            status: item.status,
            remarks: item.remarks,
          });
        }
      }
    });

    return Object.values(result)[0];
  };

  const fetchTaskData = async () => {
    setIsLoading(true);
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        `/getTaskListByInquiryID/${inquiryId}`
      );
      const transformedData = transformData(response.data);
      setInquiryData(transformedData);
      setChecklistApproved(response.data[0].inquiry.checklistApproved);
    } catch (error) {
      console.log("Error fetching customer data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = () => {
    updateReportStatus("Approved");
    setApproving(true);
  };

  const handleDecline = () => {
    updateReportStatus("Rejected");
    setRejecting(true);
  };

  const updateReportStatus = (status) => {
    let adjustedStatus;
    if (status.toLowerCase() === "approved") {
      adjustedStatus = "approve";
    } else if (status.toLowerCase() === "rejected") {
      adjustedStatus = "reject";
    } else {
      adjustedStatus = status.toLowerCase();
    }
    Swal.fire({
      title: `Are you sure you want to ${adjustedStatus} this report?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        setUpdating(true);
        updateStatusRequest(status);
      } else if (result.isDismissed) {
        setUpdating(null);
        setApproving(null);
        setRejecting(null);
      }
    });
  };

  const updateStatusRequest = async (status) => {
    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance2,
        "PUT",
        `/reportApprove/${inquiryId}`,
        { status: status }
      );
      setChecklistStatus("Updated");
      Swal.fire(
        "Updated!",
        `Report has been ${status.toLowerCase()}.`,
        "success"
      );
      setUpdating(null);
      setApproving(null);
      setRejecting(null);
    } catch (error) {
      console.error("Error updating report status:", error);
      Swal.fire("Error!", "Failed to update report status.", "error");
    }
  };

  // console.log("Type:", customer);

  const technicianId =
    inquiry?.technicianId?.technicianId || customer?.technicianId?.technicianId;

  // console.log("inquiry", technicianId);

  const fetchSignature = async () => {
    // console.log("fetchSignature started");
    try {
      // console.log("Request started");
      const response = await request(
        axiosInstance1,
        "GET",
        `/${technicianId}/signature`,
        {
          responseType: "text",
        }
      );
      if (response.data) {
        const base64Image = response.data;
            let mimeType = "image/jpeg";

            if (base64Image.startsWith("/9j/")) {
                mimeType = "image/jpeg";
            } else if (base64Image.startsWith("iVBORw0KGgo")) {
                mimeType = "image/png";
            } else if (base64Image.startsWith("R0lGODdh") || base64Image.startsWith("R0lGODlh")) {
                mimeType = "image/gif";
            } else if (base64Image.startsWith("UklGR")) {
                mimeType = "image/webp";
            }
            const signatureURL = `data:${mimeType};base64,${base64Image}`;
            setSignature(signatureURL);
        setSignature(signatureURL);
      } else {
        console.error("The fetched data is empty:", response.data);
      }
    } catch (error) {
      console.error("Error fetching signature", error);
    }
  };

  useEffect(() => {
    fetchSignature();
  }, []);

  const handleGeneratePdf = async () => {
    setIsProcessing(true);
    try {
      const pdfDoc = (() => {
        switch (reportData.type) {
          case "Gas":
            return (
              <GasReport
                data={reportData}
                result={inquiryData}
                signature={signature}
              />
            );
          case "Electrical":
            return (
              <ElectricReport
                data={reportData}
                result={inquiryData}
                signature={signature}
              />
            );
          case "Smoke":
            return (
              <SmokeReport
                data={reportData}
                result={inquiryData}
                signature={signature}
              />
            );
          default:
            return;
        }
        // return ()
      })();

      const pdfBlob = await pdf(pdfDoc).toBlob();
      setPdfBlob(pdfBlob);

      // Create a Blob URL and set it to the state
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfDataUrl(pdfUrl);

      setOpen(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
      Swal.fire("Error", "Failed to generate the PDF.", "error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSendEmail = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const fileName = `Report of Inq. ID: ${inquiryId}.pdf`;
      formData.append("inquiryId", inquiryId);
      formData.append("pdfFile", pdfBlob, fileName);

      setAuthHeader(getAuthToken());
      const response = await request(
        axiosInstance4,
        "POST",
        `/sendReportEmail`,
        formData
      );
      if (response.status === 200) {
        setOpen(false);
        Swal.fire(
          "Success",
          "The email has been sent successfully!",
          "success"
        );
      } else {
        Swal.fire("Error", "Failed to send the email.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to send the email.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      if (pdfDataUrl) {
        URL.revokeObjectURL(pdfDataUrl);
      }
    };
  }, [pdfDataUrl]);

  const handleClose = () => {
    setOpen(false);
    if (pdfDataUrl) {
      URL.revokeObjectURL(pdfDataUrl);
      setPdfDataUrl(null);
    }
  };

  console.log("data:", inquiryData);
  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "50%",
            margin: "auto",
            alignItems: "center",
            boxShadow: 1,
            borderRadius: 1,
            padding: 6,
            marginTop: 4,
            backgroundColor: "white",
          }}
        >
          <Grid
            container
            ref={reportRef}
            padding={4}
            paddingTop={6}
            overflow-auto
          >
            <Grid
              item
              sm={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#839192",
                border: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#839192",
                  padding: 1,
                  color: "#2C3E50",
                }}
              >
                Service Report
              </Typography>
            </Grid>
            <Grid container marginTop={0}>
              {inquiryData.titles?.map((title) => (
                <Grid
                  item
                  sm={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    variant="h7"
                    sx={{
                      fontWeight: "bold",
                      padding: 1,
                      border: 2,
                      backgroundColor: "#D5DBDB",
                      marginTop: 4,
                    }}
                  >
                    {title.title}
                  </Typography>
                  {title.subTitles?.map((subTitle) => (
                    <Box>
                      {subTitle.subTitle && (
                        <Typography
                          border={2}
                          borderTop={1}
                          paddingY={1}
                          paddingX={2}
                          fontWeight={700}
                        >
                          {subTitle.subTitle}
                        </Typography>
                      )}
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "23%",
                          marginBottom: 0,
                        }}
                        sm={12}
                      >
                        {subTitle.descriptions.map(
                          ({ description, status }, index) => (
                            <Grid
                              sm={12}
                              item
                              container
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              {status ? (
                                <>
                                  <Grid item sm={11}>
                                    <Typography
                                      border={1}
                                      paddingY={1}
                                      paddingX={2}
                                      borderTop={0}
                                      sx={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {description}
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1}>
                                    <Typography
                                      border={1}
                                      paddingY={1}
                                      paddingX={2}
                                      borderLeft={0}
                                      borderTop={0}
                                      sx={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {status}
                                    </Typography>
                                  </Grid>
                                </>
                              ) : (
                                <Grid item sm={12}>
                                  <Typography
                                    border={1}
                                    paddingY={1}
                                    paddingX={2}
                                    borderTop={0}
                                    sx={{
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {description}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: 3,
              }}
            >
              {role === 1 && checklistApproved === null && (
                <>
                  <Box marginTop={1}>
                    <button
                      className="justify-end w-32 py-2 font-semibold text-white bg-gray-700 rounded-full"
                      onClick={() => {
                        handleDecline();
                      }}
                      disabled={updating}
                    >
                      {rejecting ? "Rejecting" : "Reject"}
                    </button>
                  </Box>
                  <Box marginTop={1}>
                    <button
                      className="w-32 py-2 font-semibold text-white bg-[#002252] rounded-full justify-end ml-2"
                      onClick={() => {
                        handleApprove();
                      }}
                      disabled={updating}
                    >
                      {approving ? "Approving" : "Approve"}
                    </button>
                  </Box>
                </>
              )}

              {checklistApproved === "Approved" && (
                <Typography
                  variant="h6"
                  color="green"
                  sx={{ textAlign: "center", mt: 0, mr: 2 }}
                >
                  Approved
                </Typography>
              )}

              {checklistApproved === "Rejected" && (
                <Typography
                  variant="h6"
                  color="red"
                  sx={{ textAlign: "center", mt: 0, mr: 2 }}
                >
                  Rejected
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: 0,
            }}
          >
            {(checklistApproved === "Approved" ||
              checklistApproved === "Rejected") && (
              <button
                className="py-2.5 px-4 mr-4 font-semibold text-white bg-gray-800 rounded-full justify-end"
                onClick={handleGeneratePdf}
                disabled={isProcessing}
              >
                {isProcessing ? "Please wait..." : "Generate PDF"}
              </button>
            )}
          </Grid>

          <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogContent>
              <Typography variant="h6" marginBottom={2}>
                Report Preview
              </Typography>
              {pdfDataUrl && (
                <iframe
                  src={pdfDataUrl}
                  title="Report Preview"
                  style={{
                    width: "100%",
                    height: "500px",
                    border: "1px solid black",
                  }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <button
                className="justify-end px-4 py-2 mb-3 mr-2 font-semibold text-white bg-gray-800 rounded-full"
                onClick={handleSendEmail}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Please wait..." : "Send via Email"}
              </button>
              <button
                className="py-1.5 px-4 font-semibold text-gray-800 border-2 border-gray-800 bg-white rounded-full justify-end mb-3 mr-3"
                onClick={handleClose}
              >
                Close
              </button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </div>
  );
};

export default ReportsPage;
