import React, { useState, useEffect } from 'react';
import apple from '../../image/apple.png';
import android from '../../image/android.png'

function DownloadButtons() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isIos, setIsIos] = useState(false);
    const [isStandalone, setIsStandalone] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
        const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

        setIsIos(isIosDevice);
        setIsStandalone(isInStandaloneMode);

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleAndroidDownloadClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    const handleIosInstructions = () => {
        alert("To install this app on your iOS device, tap 'Share' and then 'Add to Home Screen'.");
    };

    return (
        <div>
            {/* {!isIos && (
            <div className='flex flex-row items-center justify-center w-full h-20 gap-20 px-10 bg-gray-200'>
            <div className='text-2xl font-semibold text-gray-800'> Download Mobile app for Android</div>
                    <button onClick={handleAndroidDownloadClick} disabled={!deferredPrompt} className='h-12 px-4 font-semibold text-white bg-[#002252] hover:text-yellow-700 rounded-lg'>
                    Download now
                </button>
                </div>
            )}
            {isIos && !isStandalone && (
                <div className='flex flex-row items-center justify-center w-full h-20 gap-20 px-10 bg-gray-200'>
                    <div className='text-2xl font-semibold text-gray-800'>Download app for IOS</div>
                    <button onClick={handleIosInstructions} className='h-12 px-4 font-semibold text-white bg-[#002252] hover:text-yellow-700 rounded-lg'>
                    Download now
                </button>
                </div>
            )} */}



                <div className='flex flex-row pb-2'>
                    <button onClick={handleAndroidDownloadClick} disabled={!deferredPrompt} className='h-16 px-4 bg-[#002252] hover:text-yellow-700 rounded-lg flex flex-row justify-center items-center'>
                    <img src={android} alt='' className='w-10 h-10 mr-2'/>
                    <div className='flex flex-col items-start'>
                        <div className='text-xs font-semibold text-white'>INSTALL FOR</div>
                        <div className='text-lg font-bold text-white'>Android</div>
                    </div>
                    </button>
                </div>


                <div className='flex flex-row'>
                <button onClick={handleIosInstructions} className='h-16 px-4 bg-[#002252] hover:text-yellow-700 rounded-lg flex flex-row justify-center items-center'>
                    <img src={apple} alt='' className='w-10 h-10 mr-2' />
                    <div className='flex flex-col items-start'>
                        <div className='text-xs font-semibold text-white'>INSTALL FOR</div>
                        <div className='text-lg font-bold text-white'>IOS</div>
                    </div>
                    </button>
                </div>

        </div>
    );
}

export default DownloadButtons;
