import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Collapse,
  IconButton,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { request, axiosInstance2 } from "../../config/axiosConfig";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import debounce from "lodash.debounce";

export default function Instructions() {
  const [technicianPayments, setTechnicianPayments] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [inquiryDetails, setInquiryDetails] = useState({});
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const MySwal = withReactContent(Swal);

  const [updatingPayment, setUpdatingPayment] = useState(false);
  const [dataLoading, setDataLoading] = useState(false); // Add loading state
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const size = 8;
  const [isInquiryDataLoading, setIsInquiryDataLoading] = useState(null);

  const bottomRef = useRef(null);

  useEffect(() => {
    fetchTechnicianPayment(page);
  }, [page, refreshTable]);

  useEffect(() => {
    if (dataLoading && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [dataLoading]);

  const fetchTechnicianPayment = async (pageNumber) => {
    try {
      setLoading(pageNumber === 0);
      setDataLoading(pageNumber !== 0);
      const response = await request(
        axiosInstance2,
        "GET",
        "/paginatedTechnicianPaymentList",
        null,
        { page: pageNumber, size: size }
      );
      const newTechnicianPayments = response.data.content;
      setHasMore(newTechnicianPayments.length > 0);
      setTechnicianPayments((prevTechnicianPayments) =>
        pageNumber === 0
          ? newTechnicianPayments
          : [...prevTechnicianPayments, ...newTechnicianPayments]
      );
    } catch (error) {
      console.error("Error fetching payment details: ", error);
    } finally {
      setLoading(null);
      setDataLoading(null);
    }
  };

  const debouncedScrollHandler = useMemo(
    () =>
      debounce((e) => {
        const tolerance = 1;
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <=
          e.target.clientHeight + tolerance;
        if (bottom && !dataLoading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 300),
    [dataLoading, hasMore]
  );

  useEffect(() => {
    return () => {
      debouncedScrollHandler.cancel();
    };
  }, [debouncedScrollHandler]);

  const handleRowClick = async (id) => {
    const newExpandedRows = { ...expandedRows, [id]: !expandedRows[id] };
    setExpandedRows(newExpandedRows);

    if (!expandedRows[id] && !inquiryDetails[id]) {
      setIsInquiryDataLoading(id);
      try {
        const response = await request(
          axiosInstance2,
          "GET",
          "/getCustomerInquiriesByGroupId",
          null,
          { id: id }
        );
        setInquiryDetails({ ...inquiryDetails, [id]: response.data });
        console.log("Selected technician inquiries: ", response.data);
      } catch (error) {
        console.error("Error fetching inquiry details:", error);
      } finally {
        setIsInquiryDataLoading(null);
      }
    }
  };

  const handleCalculatePayments = async () => {
    setIsCalculating(true);
    try {
      const response = await request(
        axiosInstance2,
        "POST",
        "/calculateTechnicianPayment"
      );
      setMessage("Technician payments are calculated successfully.");
      setIsSuccess(true);
      setRefreshTable(!refreshTable);
    } catch (error) {
      setMessage("Error occurred while calculating payments.");
      setIsSuccess(false);
    } finally {
      setIsCalculating(false);
    }
  };

  const handlePaymentDone = async (id) => {
    setUpdatingPayment(id);
    try {
      const response = await request(
        axiosInstance2,
        "PUT",
        `/donePayment/${id}`
      );

      // Show success message with SweetAlert
      MySwal.fire({
        icon: "success",
        title: "Accepted",
        text: "Payment done successfully",
      });

      setRefreshTable(!refreshTable);
    } catch (error) {
      console.log(error);

      // Show error message with SweetAlert
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error doing payment.",
      });
    } finally {
      setUpdatingPayment(null);
    }
  };

  return (
    <div>
      <div className="mt-4 ml-8">
        <div className="flex items-center mb-4">
          <div className="text-sm font-medium mr-2">
            Click the button to calculate weekly payment of technicians:
          </div>
          <button
            onClick={handleCalculatePayments}
            disabled={isCalculating}
            className="px-3 py-2 text-sm font-semibold text-white bg-[#002252] rounded-full"
          >
            {isCalculating ? "Saving, please wait..." : "Calculate"}
          </button>
        </div>
        {message && (
          <p
            className={`mt-4 ${isSuccess ? "text-green-500" : "text-red-500"}`}
          >
            {message}
          </p>
        )}
      </div>
      <div className="flex flex-col px-4 pt-3 lg:px-16 ">
        <div className="text-xl font-bold text-gray-800 sm:text-2xl lg:text-2xl p-2">
          Technician Payments List
        </div>
      </div>
      {loading ? ( // Show spinner while loading
        <div className="flex justify-center items-center mt-10">
          <CircularProgress />
        </div>
      ) : (
        <Grid display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <TableContainer
            elevation={0}
            component={Paper}
            sx={{
              marginTop: 2,
              maxHeight: "560px",
              maxWidth: "95%",
              overflowY: "scroll",
            }}
            onScroll={debouncedScrollHandler}
          >
            <Table
              sx={{
                minWidth: 650,
                maxWidth: "100%",
                // marginInline: 0.1,
                marginTop: 1,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  ></TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    ID
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Type of expertise
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Contact Number
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Start Date
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    End Date
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Amount
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    No of inquiries
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {technicianPayments.map((payment) => (
                  <React.Fragment key={payment.id}>
                    <TableRow>
                      <TableCell align="center" component="th" scope="row">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleRowClick(payment.id)}
                        >
                          {expandedRows[payment.id] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{payment.id}</TableCell>
                      <TableCell align="center">
                        {payment.technicianId.firstName +
                          " " +
                          payment.technicianId.lastName}
                      </TableCell>
                      <TableCell align="center">
                        {payment.technicianId.typeOfExpertise}
                      </TableCell>
                      <TableCell align="center">
                        {payment.technicianId.contactNumber}
                      </TableCell>
                      <TableCell align="center">{payment.weekStart}</TableCell>
                      <TableCell align="center">{payment.weekEnd}</TableCell>
                      <TableCell align="center">{payment.total}</TableCell>
                      <TableCell align="center">
                        {payment.noOfInquiries}
                      </TableCell>
                      <TableCell align="center">
                        {payment.paymentStatus === "Done" ? (
                          <button
                            type="button"
                            className="px-3 py-2 text-sm font-semibold text-white bg-green-500 border-2 border-green-500 rounded-full"
                            disabled
                          >
                            Paid
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
                            onClick={() => handlePaymentDone(payment.id)}
                            disabled={updatingPayment === payment.id}
                          >
                            {updatingPayment === payment.id
                              ? "Please wait..."
                              : "Payment Done"}
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                        sx={{ backgroundColor: "#eeeeee" }}
                      >
                        <Collapse
                          in={expandedRows[payment.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={0}>
                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                {isInquiryDataLoading ? (
                                  <Box ml={10} my={2}>Loading...</Box>
                                ) : (
                                  inquiryDetails[payment.id]?.map((inquiry) => (
                                    <Box key={inquiry.id} pl={18} mt={2} mb={3}>
                                      <Grid container spacing={4}>
                                        <Grid item xs={3}>
                                          <strong>Customer Name:</strong>
                                          {"  "}
                                          {inquiry.firstName +
                                            " " +
                                            inquiry.lastName}
                                        </Grid>
                                        <Grid item xs={3}>
                                          <strong>Paid Date:</strong>{" "}
                                          {inquiry.serviceDate}
                                        </Grid>
                                        <Grid item xs={2}>
                                          <strong>Service Price:</strong>{" "}
                                          {inquiry.servicePrice}
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                      </Grid>
                                    </Box>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {dataLoading && (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <CircularProgress />
                      <Typography variant="body2">Loading Data...</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <tr ref={bottomRef}></tr>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </div>
  );
}
