import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Button,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance1 } from "../../config/axiosConfig";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token"); 

    const verifyEmail = async () => {
      if (!token) {
        setMessage("No verification token provided.");
        setLoading(false);
        return;
      }

      try {
        const { data } = await axiosInstance1.get(`/verify?token=${token}`);
        if (data.includes("Invalid verification token.")) {
          setMessage("Invalid verification token.");
        } else if (data.includes("Email is already verified.")) {
          setMessage("Email is already verified.");
        } else {
          setMessage("Email verified successfully! You can now log in.");
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Verification failed. Please try again.";
        setMessage(errorMessage);
      } finally {
        setLoading(false);
        hasFetched.current = true;
      }
    };

    verifyEmail();
  }, [location]);

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(to right, #FCCF00,#002252, #002252)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "160px",
            left: "-125px",
            width: "250px",
            height: "250px",
            background: "rgba(255, 255, 255, 0.2)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-250px",
            left: "20px",
            width: "600px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            right: "-200px",
            width: "300px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 2,
                borderRadius: 5,
                padding: 4,
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  mt: 3,
                }}
              >
                <span
                  style={{
                    borderBottom: "3px solid #FCCF00",
                    color: "#002252",
                    ml: "-4px",
                    pl: "4px",
                  }}
                >
                  Email Verification
                </span>
              </Typography>

              <Container style={{ textAlign: "center", marginTop: "30px" }}>
                {loading ? (
                  <>
                    <CircularProgress />
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: "20px" }}
                    >
                      Verifying your email, please wait...
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ marginBottom: "30px" }}
                    >
                      {message}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        mb: 2,
                        borderRadius: 3,
                        background: "#002252",
                      }}
                      onClick={handleGoHome}
                    >
                      Go to Promender Home
                    </Button>
                  </>
                )}
              </Container>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
