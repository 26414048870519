import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import { request, axiosInstance1 } from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPopup, setOpenPopup] = useState(false); 
  const [timerProgress, setTimerProgress] = useState(0); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    const userType = localStorage.getItem("userType");

    if (!userType) {
      setResponseMessage("User type is not defined");
      return;
    }

    setIsSubmitting(true);
    try {
      let response;
      if (userType === "customer") {
        response = await request(
          axiosInstance1,
          "POST",
          "/forgotCustomerPassword",
          { username }
        );
      } else if (userType === "technician") {
        response = await request(
          axiosInstance1,
          "POST",
          "/forgotTechnicianPassword",
          { username }
        );
      } else {
        throw new Error("Unknown user type");
      }

      setResponseMessage(response.data);
      setUsername("");
      setOpenPopup(true); // Open the popup after successful response
    } catch (error) {
      console.error("Error handling forgot password:", error);
      setResponseMessage("An error occurred. Please try again.");
      setOpenPopup(true); // Open the popup for error message
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (openPopup) {
      const interval = setInterval(() => {
        setTimerProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);
            const userType = localStorage.getItem("userType");

            if (
              responseMessage ===
              "A confirmation email has been sent to your email address."
            ) {
              if (userType === "customer") {
                navigate("/customerLogin");
              } else if (userType === "technician") {
                navigate("/technicianLogin");
              }
            } else {
              navigate("/forgotPassword"); 
            }

            setOpenPopup(false); 
            return 0;
          }
          return Math.min(oldProgress + 100, 100); 
        });
      }, 2000); 

      return () => clearInterval(interval);
    }
  }, [openPopup, navigate, responseMessage]);

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          background: "linear-gradient(to right, #FCCF00, #002252, #002252)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "160px",
            left: "-125px",
            width: "250px",
            height: "250px",
            background: "rgba(255, 255, 255, 0.2)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-250px",
            left: "20px",
            width: "600px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            right: "-200px",
            width: "300px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
      </div>

      <div style={{ position: "relative", zIndex: 2, padding: "20px" }}>
        <Grid
          container
          spacing={8}
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10} sm={8} md={6} lg={4}>
            <div className="p-8 bg-white border border-gray-300 rounded-lg shadow-md">
              <Typography
                variant="h5"
                align="center"
                style={{
                  color: "#002252",
                  marginBottom: "16px",
                  fontWeight: "700",
                  fontSize: 24,
                }}
              >
                Forgot Password
              </Typography>

              <form onSubmit={handleSubmit}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  style={{ marginBottom: "20px" }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: "#002252",
                    color: "white",
                    borderRadius: "8px",
                    padding: "6px 20px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    display: "block", 
                    mx: "auto"
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.background = "#002252")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.background =
                      "linear-gradient(to right, #FCCF00, #002252)")
                  }
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Reset Password"}
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <DialogContentText>
              <Typography
                variant="body1"
                align="center"
                style={{
                  marginBottom: "16px",
                  color:
                    responseMessage ===
                    "A confirmation email has been sent to your email address."
                      ? "green"
                      : "red",
                }}
              >
                {responseMessage}
              </Typography>
            </DialogContentText>
            <LinearProgress
              variant="determinate"
              value={timerProgress}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ForgotPassword;
