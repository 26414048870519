import React, { useState } from "react";
import {Grid,Typography,Box,InputLabel,TextField,Button,MenuItem,Select} from "@mui/material";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { request } from "../../config/axiosConfig";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import Footer from "../../components/footer/Footer";
import NewNavBar from "../../components/appBar/NewNavBar";

function Contactus() {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    type: "Select",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !formData.fullName ||
      !formData.phoneNumber ||
      !formData.emailAddress ||
      !formData.password ||
      !formData.emailAddress ||
      !formData.type ||
      formData.type === "Select" ||
      !formData.licenseNumber ||
      !formData.address
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    request("POST", "", formData)
      .then((response) => {
        console.log(response);
        setSuccessMessage("Message send successful");
        setErrorMessage("");
        console.log("Success");
        setFormData({
          fullName: "",
          phoneNumber: "",
          emailAddress: "",
          type: "",
          message: "",
        });
      })
      .catch((error) => {
        setSuccessMessage("");
        setErrorMessage("An error occurred. Please try again.");
        console.log(error);
      });
  };

  const handleClearForm = () => {
    setFormData({
      fullName: "",
      phoneNumber: "",
      emailAddress: "",
      type: "",
      message: "",
    });
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <div>
      <NewNavBar activePage="Contact us" />
      <div className="relative w-screen px-2 mt-5 md:px-20 h-100px">
        <div className='w-full h-[200px] lg:h-[350px] sm:h-[300px] bg-gradient-to-r from-[#f8ebeb] via-[#F4B400] to-[#F4B400] rounded-2xl'></div>
        {/* <img src="aboutImage.jpg" alt="" className="object-cover w-full h-[250px] lg:h-[450px] sm:h-[400px]" /> */}
        <div className='absolute inset-0 flex items-center justify-center mt-3'>
        </div>
        <div className='absolute inset-0 flex flex-row items-center px-4 font-black text-center text-black md:justify-between md:px-48'>
          <div className='flex flex-col text-start'>
            <div className="text-2xl font-bold text-black md-4 md:mb-10 md:text-4xl">Contact Us</div>
            <div className="md:text-xl text-sm font-semibold text-gray-900 md:w-[550px]">We're proud to have helped keep thousands of homes safe. Read what our client have to say!</div>
            <div className="flex flex-row mt-4">
              <div className="md:w-[180px] w-[100px] rounded-full">
                <button className="w-full py-2 text-xs md:text-base font-bold text-white bg-[#002252] rounded-full">
                  BOOK NOW
                </button>
              </div>
            </div>
          </div>

          <div>
            <img src="ContactPic1.svg" alt="" width={200} height={200}/>
          </div>
        </div>
      </div>

      
      <Typography variant="h4"sx={{display: "flex",justifyContent: "center",alignItems: "center",fontWeight: "bold",mt: 3,}}>
        <span style={{borderBottom: "4px solid #002252",mr: "-4px",pr: "4px",color: "#FCCF00",}}>CONTACT {"\u00A0"}</span>
        <span style={{borderBottom: "4px solid #FCCF00",color: "#002252",ml: "-4px",pl: "4px"}}>US</span>
      </Typography>

      <Grid container spacing={0} sx={{ mt: 5, mb: 5 }}>
        <Grid item xs={12} sm={0.5}></Grid>
        <Grid item xs={12} sm={5.8} sx={{border: "2px solid rgba(0, 34, 82, 0.5)",width: "calc(40%)",height: "100%"}}>
          <Typography variant="h5" sx={{background: "linear-gradient(to right, #002252, #002252, #FCCF00)",color: "white",padding: 3,fontWeight: "bold",justifyContent: "center",alignItems: "center",display: "flex",}}>
            Contact Us
          </Typography>
          <Box sx={{ padding: "calc(5%)" }}>
            <form onSubmit={handleSubmit}>
              <InputLabel sx={{ fontSize: "2vh", textAlign: "left" }}>
                Full Name
              </InputLabel>
              <TextField size="small" required fullWidth id="fullName" name="fullName" autoComplete="given-name" value={formData.fullName} onChange={handleChange}/>
              <InputLabel sx={{ fontSize: "2vh", textAlign: "left",mt: 3 }}>
                Phone Number
              </InputLabel>
              <TextField size="small" required fullWidth id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
              <InputLabel sx={{ fontSize: "2vh", textAlign: "left", mt: 3 }}>
                Email Address
              </InputLabel>
              <TextField size="small" required fullWidth id="emailAddress" name="emailAddress" autoComplete="email" value={formData.emailAddress} onChange={handleChange}/>
              <InputLabel sx={{ fontSize: "2vh", textAlign: "left", mt: 3 }}>
                Type
              </InputLabel>
              <Select labelId="type" id="type" value={formData.type} onChange={handleChange} name="type" size="small" fullWidth>
                <MenuItem value="Select">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value="type1">type 1</MenuItem>
                <MenuItem value="type2">type 2</MenuItem>
              </Select>
              <InputLabel
                sx={{ fontSize: "2vh", textAlign: "left", marginTop: 3 }}>
                How Can We Help You?
              </InputLabel>
              <TextField required fullWidth id="message" name="message" value={formData.message} onChange={handleChange}/>
              <Button type="submit" fullWidth variant="contained" sx={{mt: 3,mb: 2,borderRadius: 2,background: 'linear-gradient(170deg, #FCCF00 9.03%, #002252 67.77%), radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(102, 102, 102, 0.2) 100%)' ,
                }}
              >
                Send Message
              </Button>
              <Button onClick={handleClearForm} fullWidth variant="outlined" sx={{mt: 1,mb: 2,borderRadius: 2,color: "#002252",borderColor: "#002252",
                }}
              >
                Clear Form
              </Button>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={0.2}></Grid>
        <Grid item xs={12} sm={5} sx={{justifyContent: "center",alignItems: "center",display: "column",textAlign: "center",}}>
          <Box sx={{ width: 260, height: 500 }} className="hidden sm:flex md:hidden">
            <img src="Basemap image.png" alt="instead of google map" style={{ width: "100%", height: "100%", objectFit: "cover" }}/>{" "}{/*Google Map*/}
          </Box>
          <Box sx={{ width: 375, height: 400 }} className="sm:hidden">
            <img src="Basemap image.png" alt="instead of google map" style={{ width: "100%", height: "100%", objectFit: "cover" }} />{" "}{/*Google Map*/}
          </Box>
          <Box sx={{ width: 305, height: 400 }} className="hidden md:flex lg:hidden">
            <img src="Basemap image.png" alt="instead of google map" style={{ width: "100%", height: "100%", objectFit: "cover" }} />{" "}{/*Google Map*/}
          </Box>
          <Box sx={{ width: 420, height: 400 }} className="hidden lg:flex xl:hidden">
            <img src="Basemap image.png" alt="instead of google map" style={{ width: "100%", height: "100%", objectFit: "cover" }} />{" "}{/*Google Map*/}
          </Box>
          <Box sx={{ width: 520, height: 400 }} className="hidden xl:flex">
            <img src="Basemap image.png" alt="instead of google map" style={{ width: "100%", height: "100%", objectFit: "cover" }} />{" "}{/*Google Map*/}
          </Box>
          <Grid item>
            <Typography variant="h6" sx={{display: "flex",alignItems: "center",fontWeight: "bold",color: "#002252",mt: 3,}}>
              <MdLocationPin style={{ marginRight: "5px" }} />
              19b Blake Street, Club Byron, Australia, 1011
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{display: "flex",alignItems: "center",fontWeight: "bold",color: "#002252",marginTop: 2, }}>
              <BsFillTelephoneFill style={{ marginRight: "5px" }} />
              +61 400 673 184
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{display: "flex",alignItems: "center",fontWeight: "bold",color: "#002252",marginTop: 2, }}>
              <MdEmail style={{ marginRight: "5px" }} />
              Info@promender.com.au
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={0.5}></Grid>

      <Footer/>
    </div>
  );
}

export default Contactus;