import React, { useState } from "react";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import Footer from "../../components/footer/Footer";
import NewNavBar from "../../components/appBar/NewNavBar";

function GasServices() {
  const [items, setItems] = useState([
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  ]);

  return (
    <div>
      <NewNavBar activePage="Our services" />
      {/* Heading */}
      <div className="relative w-screen bg-black h-100px">
        <div className='w-full h-[250px] lg:h-[350px] sm:h-[400px] bg-gradient-to-r from-[#FCD419] via-[#FCD419] to-[#ffffff]'></div>
        {/* <img src="aboutImage.jpg" alt="" className="object-cover w-full h-[250px] lg:h-[450px] sm:h-[400px]" /> */}
        <div className='absolute inset-0 flex items-center justify-center mt-3'>
          <div className="w-[1061px] h-full flex items-center justify-center">
            <div className='relative top-0 left-0 justify-start md:w-[200px] sm:w-[250px] lg:w-[1061px] h-full md:text-2xl font-bold text-black'>
              <div className='border-r-2 border-b-2 sm:w-[200px] lg:w-[200px] sm:mt-16 sm:ml-10 border-black pr-1'>OUR SERVICES</div>
            </div>
          </div>
        </div>
        <div className='absolute inset-0 flex flex-col items-center justify-center text-3xl font-black text-center text-black sm:text-5xl lg:text-6xl'>
          <div className=''>Gas Services</div>
        </div>
      </div>

      {/* Description */}
      <div className="flex flex-col px-10 mt-10 md:mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col w-full md:h-screen">
            <div className="w-full h-[450px]">
              <img
                src="GasPic1.svg"
                alt="Gas Service Description img1"
                className="md:w-[500px] md:h-[500px] w-[300px] h-[300px] sm:w-[500px] sm:h-[500px]"
              />
            </div>
            <div className="flex flex-col h-full mb-3 2xl:justify-between">
              <div className="text-lg font-bold md:text-2xl md:mr-10 md:mt-16 sm:mt-16 sm:text-xl">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.{" "}
              </div>
              <div className="mt-2 text-base sm:text-lg md:mr-10">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups. Lorem ipsum is placeholder text commonly used in
                the graphic, print, and publishing industries for previewing
                layouts and visual mockups.{" "}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:w-full md:h-screen">
            <div className="w-full md:hidden">
              <img
                src="GasPic2.svg"
                alt="Gas Service Description img2"
                className="w-[300px] h-[300px] sm:w-[500px] sm:h-[500px]"
              />
            </div>
            <div className="flex flex-col h-full 2xl:justify-between md:mb-3">
              <div className="text-lg font-bold sm:text-xl md:text-2xl md:mt-4 md:ml-10">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.{" "}
              </div>
              <div className="mt-2 text-sm md:ml-10 sm:text-lg md:text-base">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups. Lorem ipsum is placeholder text commonly used in
                the graphic, print, and publishing industries for previewing
                layouts and visual mockups.{" "}
              </div>
            </div>
            <div className="w-full h-[550px] hidden md:flex">
              <img
                src="GasPic2.svg"
                alt="Gas Service Description img2"
                className="w-[500px] h-[500px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Horizontal Line 2 */}
      <div className="w-full h-[227px] bg-[#FCCF00] mt-20 hidden mb-20 lg:flex items-center">
        <div className="grid w-full grid-cols-12">
          {/* Find A Technician Today! */}
          <div className="flex items-center col-span-9 sm:col-span-6">
            <div className="mx-auto text-4xl font-bold text-white">
              Find A Technician Today!
            </div>
          </div>
          {/* Find technician button */}
          <div className="flex justify-end col-span-3 sm:col-span-4">
            <div className="w-[300px]">
              <button className="w-full py-3 text-lg font-bold text-white bg-[#002252]">
                FIND A TECHNICIAN
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*mobile*/}
      <div className="lg:hidden sm:mt-16 w-full flex flex-col h-[180px] bg-[#FCCF00] items-center justify-center gap-4">
        <div className="text-3xl font-bold text-white sm:text-4xl">Find A Technician</div>
        <button className="bg-[#002252] text-white px-4 py-2 rounded-md sm:text-lg font-bold">Find a Technician</button>
      </div>

      {/* Unorderd List */}
      <div className="flex flex-col 2xl:min-h-screen bg-gray-100 mb-3 2xl:ml-10 2xl:w-[1450px] w-full">
        <main className="flex-grow px-6 py-6">
          <div className="flex flex-col space-y-4">
            <div className="px-2 py-4 2xl:px-6 ">
              <h2 className="text-2xl 2xl:text-4xl">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print.
              </h2>
              <p className="mt-2 2xl:mt-5 2xl:text-xl sm:text-lg">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups. Lorem ipsum is placeholder text commonly used in
                the graphic, print, and publishing industries for previewing
                layouts and visual mockups.
              </p>
              <ul className="mt-4 2xl:mt-5">
                {items.map((item, index) => (
                  <li key={index} className="flex mt-3">
                    <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
                    <span className="2xl:text-xl sm:text-lg">{item}</span>
                  </li>
                ))}
              </ul>
              <p class="2xl:text-xl 2xl:pt-5 sm:text-lg">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups. Lorem ipsum is placeholder text commonly used in
                the graphic, print, and publishing industries for previewing
                layouts and visual mockups.
              </p>
              <div className="flex flex-col items-center pt-4 2xl:flex-row">
                {" "}
                <div className="2xl:w-[300px] w-[200px]">
                  <button className="w-full py-3 2xl:text-lg font-bold text-white ml-3 sm:text-lg bg-gradient-to-tr from-[#FCCF00] to-[#002252] rounded-2xl">
                    ENQUIRE NOW
                  </button>
                </div>
                <div className="flex flex-col justify-center 2xl:ml-20">
                  <div className="flex flex-row pt-2">
                    <div className="flex flex-col justify-center pr-3">
                      <BsFillTelephoneFill className="2xl:w-16 w-10 h-10 2xl:h-16 text-[#002252]" />
                    </div>
                    <div className="flex flex-col">
                      <div className="2xl:text-lg sm:text-lg font-bold text-[#002252]">
                        Call us:
                      </div>
                      <div className="2xl:text-4xl text-2xl font-bold text-[#002252] sm:text-3xl">
                        +61 400 673 184
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Icon List */}
      <div className="mt-10 ml-10 font-bold sm:text-xl">
        The types of gas service ProMender provide include:
      </div>
      <div className="flex flex-col items-center gap-5 mt-10 ml-10 mr-10 md:hidden 2xl:grid md:grid-cols-6">
        {" "}
        <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
          <img src="ES_V1.png" alt="gas services icon 1" />
        </div>
        <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
          <img src="ES_V2.png" alt="gas services icon 2" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V3.png" alt="gas services icon 3" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V2.png" alt="gas services icon 4" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V1.png" alt="gas services icon 5" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V4.png" alt="gas services icon 6" />
        </div>
      </div>

      {/*Mobile*/}
      <div className="flex flex-col items-center w-full mt-10 xl:hidden">
        <div className="flex flex-row justify-between w-3/4">
          <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 1" />
          </div>
          <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 2" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-4">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V3.png" alt="gas services icon 3" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 4" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-4">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 5" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V4.png" alt="gas services icon 6" />
          </div>
        </div>
      </div>

      {/*large screens*/}
      <div className="xl:flex flex-col items-center h-[500px] hidden 2xl:hidden mt-16">
        <div className="flex flex-row justify-between w-3/4">
          <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 1" />
          </div>
          <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 2" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V3.png" alt="gas services icon 3" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-16">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 4" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 5" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V4.png" alt="gas services icon 6" />
          </div>
        </div>
      </div>


      {/* Horizontal Line 2 */}
      <div className="hidden w-full h-[227px] bg-[#002252] mt-20 mb-20 px-20 md:flex items-center">
        <div className="grid w-full md:grid-cols-12">
          {/* Find A Service Today! */}
          <div className="flex items-center c md:col-span-9 sm:col-span-6">
            <div className="mx-auto font-bold text-white md:text-4xl">
              Find A Service Today!
            </div>
          </div>

          {/* Find technician button */}
          <div className="flex justify-end md:col-span-3 sm:col-span-4">
            <div className="w-[300px]">
              <button className="w-full py-3 text-lg font-bold text-[#002252] bg-white">
                FIND A SERVICE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Mobile*/}
      <div className="mt-4 md:hidden w-full h-[200px] bg-[#002252] flex flex-col">
        <div className="flex flex-col items-center justify-center h-full text-xl font-bold text-white sm:text-2xl">Find A Service Today!</div>
        <div className="flex flex-col items-center h-full">
          <button className="text-xl font-bold text-[#002252] px-2 py-2 rounded-md w-2/4 bg-white ">Find A Service</button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GasServices;
