import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';


export default function OurServicesMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkStyle = {
    color: 'black',
    textDecoration: 'none', // Remove underline
  };

  const buttonStyle = {
    color: 'black',
    fontWeight: "bold",
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={buttonStyle} // Apply button style
      >
        Our Services
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={"/gasServices"} style={linkStyle}>Gas Services</Link> {/* Apply link style */}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={"/electricalServices"} style={linkStyle}>Electrical Services</Link> {/* Apply link style */}
        </MenuItem>
      </Menu>
    </div>
  );
}
