import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../image/logo.PNG';
import { RiArrowDropDownLine } from "react-icons/ri";
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { CgMenuLeft } from "react-icons/cg";
import SideBarImg from '../../image/home3.jpeg';

function NewNavBar({ activePage }) {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:1080px)");

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <div className='sticky top-0 w-full bg-white z-[99999]'>
      {/* Contact us header banner */}
      <div className='flex flex-row w-full py-2 bg-[#002252] justify-center gap-2'>
        <div className='text-lg font-bold text-white'>Contact us on </div>
        <div className='text-lg font-bold text-white'>+61 400 673 184 </div>
      </div>

      {/* Navigation bar */}
      <div position="static" className='bg-white'>
        <div>
          {isMobile && (
            <div className='flex flex-row justify-between w-full h-full px-1 py-1'>
              <IconButton onClick={toggleDrawer(true)} className=''>
                <CgMenuLeft className='w-9 h-9 text-[#002252]' />
              </IconButton>
              <img src={logo} alt='Logo' className='w-[200px] h-[60px]' />
            </div>
          )}
          {!isMobile && (
            <div className='flex flex-row items-center justify-between w-full h-20 shadow-lg'>
              <div className='flex flex-row justify-center h-full px-2 py-2'>
                <img src={logo} alt='logo icon' className='rounded-lg' />
              </div>
              <div className='flex flex-row items-center justify-start h-full gap-4 pr-10 text-lg font-semibold lg:pr-0 xl:text-xl'>
                <NavLink to="/" exact className={`px-2 py-2 text-gray-800 no-underline ${activePage === 'home' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`}>
                  Home
                </NavLink>
                <NavLink to="/aboutus" className={`px-2 py-2 text-gray-800 no-underline ${activePage === 'About us' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`}>
                  About us
                </NavLink>
                <NavLink to="/instructions" className={`px-2 py-2 text-gray-800 no-underline ${activePage === 'Instructions & Guidelines' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`}>
                  Instructions & Guidelines
                </NavLink>
                <NavLink to="/contactus" className={`px-2 py-2 text-gray-800 no-underline ${activePage === 'Contact us' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`}>
                  Contact us
                </NavLink>
                <div className='relative'>
                  <button className={`flex flex-row items-center gap-1 py-2 pl-2 text-gray-800 ${activePage === 'Our services' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`} onClick={() => toggleDropdown('services')}>
                    Our Services <RiArrowDropDownLine className='w-10 h-full' />
                  </button>
                  {openDropdown === 'services' && (
                    <div className='absolute right-0 mt-2 bg-white border border-gray-300 shadow-lg w-[500px] flex flex-row'>
                      <div className='w-1/2 text-black'>
                        <img src={SideBarImg} alt='SideBar Image' />
                      </div>
                      <div className='flex flex-col w-1/2'>
                        <NavLink to="/electricalServices" className='block px-4 py-2 text-gray-800 no-underline hover:bg-[#002252] hover:text-white'>
                          Electrical Service
                        </NavLink>
                        <NavLink to="/gasServices" className='block px-4 py-2 text-gray-800 no-underline hover:bg-[#002252] hover:text-white'>
                          Gas Service
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
                <NavLink to="/technicianReg" className={`px-2 py-2 text-gray-800 no-underline ${activePage === 'Technician Registration' ? 'text-yellow-700' : 'text-gray-800 hover:text-yellow-700'}`}>
                  Technician Registration
                </NavLink>
                <div className='relative pr-2'>
                  <button className='px-1 w-[140px] rounded-lg py-2 bg-[#002252] h-12 text-white' onClick={() => toggleDropdown('signin')}>
                    Sign in
                  </button>
                  {openDropdown === 'signin' && (
                    <div className='absolute right-0 w-48 mt-2 bg-white border border-gray-300 shadow-lg'>
                      <NavLink to="/customerLogin" className='block px-4 py-2 text-gray-800 no-underline hover:bg-[#002252] hover:text-white'>
                        Customer
                      </NavLink>
                      <NavLink to="/technicianLogin" className='block px-4 py-2 text-gray-800 no-underline hover:bg-[#002252] hover:text-white'>
                        Technician
                      </NavLink>
                      <NavLink to="/adminLogin" className='block px-4 py-2 text-gray-800 no-underline hover:bg-[#002252] hover:text-white'>
                        Admin
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Drawer for mobile view */}
      {isMobile && (
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <List>
            <ListItem button component={NavLink} to="/homePage" exact onClick={toggleDrawer(false)}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={NavLink} to="/aboutus" onClick={toggleDrawer(false)}>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={NavLink} to="/" onClick={toggleDrawer(false)}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={NavLink} to="/aboutus" onClick={toggleDrawer(false)}>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={NavLink} to="/instructions" onClick={toggleDrawer(false)}>
              <ListItemText primary="Instructions & Guidelines" />
            </ListItem>
            <ListItem button component={NavLink} to="/contactus" onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button component={NavLink} to="/electricalServices" onClick={toggleDrawer(false)}>
              <ListItemText primary="Electrical Service" />
            </ListItem>
            <ListItem button component={NavLink} to="/gasServices" onClick={toggleDrawer(false)}>
              <ListItemText primary="Gas Service" />
            </ListItem>
            <ListItem button component={NavLink} to="/technicianReg" onClick={toggleDrawer(false)}>
              <ListItemText primary="Technician Registration" />
            </ListItem>
            <ListItem button component={NavLink} to="/customerLogin" onClick={toggleDrawer(false)}>
              <ListItemText primary="User Login" />
            </ListItem>
            <ListItem button component={NavLink} to="/technicianLogin" onClick={toggleDrawer(false)}>
              <ListItemText primary="Technician Login" />
            </ListItem>
            <ListItem button component={NavLink} to="/adminLogin" onClick={toggleDrawer(false)}>
              <ListItemText primary="Admin Login" />
            </ListItem>
          </List>
        </Drawer>
      )}
    </div>
  );
}

export default NewNavBar;
