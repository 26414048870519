import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../image/logo.PNG";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    paddingHorizontal: 35,
    paddingVertical: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 150,
    height: 50,
  },
  title: {
    fontSize: 24,
    fontFamily: "Helvetica", // Using Helvetica which is a default font
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#003366",
    color: "white",
    padding: 20,
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    marginHorizontal: 10,
  },
  footerIcon: {
    marginHorizontal: 5,
    fontSize: 10,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  verticalLine: {
    height: "100%",
    width: 1,
    backgroundColor: "white",
    marginHorizontal: 10,
  },
  icon: {
    fontFamily: "FontAwesome",
    fontSize: 12,
    color: "white",
    marginHorizontal: 5,
  },
  tableHeader: {
    display: "table",
    width: "auto",
    backgroundColor: "#003366",
    fontSize: 11,
    fontWeight: "extrabold",
    color: "white",
    padding: 8,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  row: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  row2: {
    flexDirection: "row",
    borderBottom: 1,
    borderTop: 1,
    borderColor: "#bfbfbf",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 1,
    borderColor: "#bfbfbf",
    marginBottom: 12,
  },
  content1: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#bfbfbf",
    // border: '2px solid red'
  },
  rowResult1: {
    padding: 5,
    color: "black",
    fontSize: 10,
    // border: '2px solid red'
  },
  description: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    borderBottom: 1,
    borderColor: "#bfbfbf",
    // border: '2px solid red'
  },
  column1: {
    width: "80%",
  },
  result: {
    width: "30%,",
    fontSize: 10,
    alignItems: "center",
    padding: 5,
  },
  column2: {
    width: "70%",
    borderLeft: 1,
    borderColor: "#bfbfbf",
  },
  subTopic: {
    backgroundColor: "#fffb00",
    opacity: "0.2",
    padding: 5,
  },
  subTopicText: {
    fontSize: 12,
    opacity: "1",
    color: "#8f8408",
  },
  signatureSection: {
    flexDirection: "column",
    backgroundColor: "#d7dbdd",
    // opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
    borderRadius: 5,
    padding: 5,
  },
  signature: {
    width: 200,
    height: 80,
    objectFit: "contain",
    alignSelf: "flex-end",
    marginTop: 5,
    marginBottom: 6,
  },
  signatureContainer: {
    fontSize: 10,
    color: "#626567",
    alignSelf: "flex-end",
    alignItems: "center",
    // border: '2px solid red',
    marginBottom: 18,
    marginLeft: 6,
    marginRight: 40,
  },
  noSignature: {
    marginBottom: 15,
    marginTop: 15,
    marginRight: 5,
    color: "black",
  },
});

const ElectricReport = ({ data, result, signature }) => (
  <Document title="Electric Safety Check Report">
    <Page size={[656, 910]} style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Image style={styles.logo} src={logo} alt="Company Logo" />
        <Text style={styles.title}>Electric Safety Check</Text>
      </View>
      <View
        style={{
          marginTop: 5,
          backgroundColor: "#fffb00",
          opacity: "0.2",
          padding: 10,
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <Text style={{ fontSize: 14, opacity: "1", color: "#8f8408" }}>
          Residential Tenancies Regulations 2021
        </Text>
        <Text
          style={{ fontSize: 9, marginTop: 5, opacity: "1", color: "#424949" }}
        >
          This electrical safety check is for electrical safety purposes only
          and is in accordance with the requirements of the Residential
          Tenancies Regulations 2021 and is prepared in accordance with section
          2 of the Australian/ New Zealand Standard AS/NZS 3019, Electrical
          installations Periodic verification to confirm that the installation
          is not damaged or has not deteriorated so as to impair electrical
          safety; and to identify installation defects and departures from the
          requirements that may give rise to danger
        </Text>
      </View>

      {/*Section A*/}
      <View>
        <Text style={styles.tableHeader}>A. Installation Address</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={{ width: "30%" }}>
              <Text style={[styles.content1, { borderRight: 0 }]}>
                {" "}
                Address:
              </Text>
            </View>
            <View>
              <Text
                style={[
                  styles.result,
                  { borderLeft: 1, borderColor: "#bfbfbf" },
                ]}
              >
                {data.address}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "30%" }}>
              <Text style={[styles.content1, { borderRight: 0 }]}>
                Date of previous Safety Check: (if any)
              </Text>
            </View>
            <View>
              <Text
                style={[
                  styles.result,
                  { borderLeft: 1, borderColor: "#bfbfbf" },
                ]}
              >
                {" "}
                -{" "}
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/*Section B*/}
      <View>
        <Text style={styles.tableHeader}>
          B. Extent of The Installation and Limitations of The Inspection and
          Testing
        </Text>
        <View style={styles.table}>
          <View style={styles.description}>
            <Text>
              Details of those parts of the installation and limitations of the
              safety check covered by this certificate Tick those parts of the
              installation included in the safety check – strike out those parts
              of the installation if not applicable – mark NI if not included in
              the safety check – add additional information if required
            </Text>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Main Switchboard</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[0].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}> Other living areas</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[8].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Main earthing system</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[1].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}> Laundry</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[9].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Kitchen</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[2].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Garage</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[10].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Bathroom (main)</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[3].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Solar/battery system</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[11].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Other bathrooms/ensuites</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[4].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Electric water heater</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[12].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Bedroom (main)</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[5].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Dishwasher</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[13].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Other bedrooms</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[6].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Electric room/space heaters</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[14].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Living room</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[7].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Swimming pool equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[0].subTitles[0].descriptions[15].status}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/*Section C*/}
      <View>
        <Text style={styles.tableHeader}>
          B. Safety Check - Verified by Visual Inspection
        </Text>
        <View style={styles.table}>
          <View style={styles.description}>
            <Text>
              As far as practicable a VISUAL INSPECTION of the following items
              has been carried out per the requirements of section 3 and 4 of
              the Australian/New Zealand Standard AS/NZS 3019:2007 Electrical
              installations—Periodic Verification : strike out those parts of
              the installation if not applicable – mark NI if not included in
              the safety check – add additional information if required.
            </Text>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Consumers mains</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[0].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Space heaters</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[10].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Switchboards</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[1].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Cooking equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[11].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Exposed earth electrode</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[2].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Dishwasher</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[12].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Metallic water pipe bond</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[3].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Exhaust fans</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[13].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>RCDs(Safety switches)</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[4].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Celling fans</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[14].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>
                  Circuit protection(circuit breakers/ fuses)
                </Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[5].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Washing machine/dryer</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[15].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Socket-outlets</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[6].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Installation wiring</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[16].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Light fittings</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[7].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>
                  Solar and other renewable systems
                </Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[17].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Electric water heater</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[8].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Swimming pool equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[18].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Air conditioners</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[9].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Vehicle chargers</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[1].subTitles[0].descriptions[19].status}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/* Footer */}
      <Footer />
    </Page>

    <Page size={[656, 910]} style={styles.page}>
      {/*Section D*/}
      <View>
        <Text style={styles.tableHeader}>
          D. Safety Check - Verified by Testing
        </Text>
        <View style={styles.table}>
          <View style={styles.description}>
            <Text>
              As far as practicable TESTING of the following items has been
              carried out per the requirements of 4 of the Australian/New
              Zealand Standard AS/NZS 3019:2007 Electrical
              installations—Periodic Verification: strike out those parts of the
              installation if not applicable – mark NI if not included in the
              safety check – add additional information if required
            </Text>
          </View>

          <View style={styles.subTopic}>
            <Text style={styles.subTopicText}>
              Polarity and correct connection testing
            </Text>
          </View>

          <View style={[styles.row2]}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Consumers mains</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[0].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Air conditioners</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[6].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>
                  Circuit protection(circuit breakers/ fuses)
                </Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[1].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Cooking equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[7].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>RCDs(Safety switches)</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[2].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Dishwasher</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[8].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Socket-outlets</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[3].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>
                  Solar and other renewable systems
                </Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[9].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Light fittings</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[4].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Swimming pool equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[10].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Electric water heater</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[5].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Vehicle chargers</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[0].descriptions[11].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.subTopic}>
            <Text style={styles.subTopicText}>Earth continuity testing</Text>
          </View>

          <View style={[styles.row2]}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Mains earth conductor</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[0].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Air conditioners</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[7].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Metallic water pipe bond</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[1].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Cooking equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[8].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Socket-outlets</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[2].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Dishwasher</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[9].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Light fittings</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[3].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>
                  Solar and other renewable systems
                </Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[10].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Exhaust fans</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[4].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Swimming pool equipment</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[11].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Celling fans</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[5].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={styles.content1}>Vehicle chargers</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[12].status}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>Electric water heater</Text>
              </View>
              <View style={styles.result}>
                <Text>
                  {result?.titles[2].subTitles[1].descriptions[6].status}
                </Text>
              </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row" }}>
              <View style={styles.column2}>
                <Text style={[styles.content1, { color: "white" }]}>PASS</Text>
              </View>
              <View style={styles.result}>
                <Text></Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/*Section E*/}
      <View>
        <Text style={styles.tableHeader}>
          E. Observations and Recommendations for Any Actions to be Taken
        </Text>
        <View style={styles.table}>
          {result?.titles[4]?.subTitles[0]?.descriptions?.map((item, index) => (
            <View key={index} style={styles.row}>
              <Text style={[styles.content1, { borderRight: 0 }]}>
                {item.description}
              </Text>
            </View>
          ))}
        </View>
      </View>

      {/* Declaration Table */}
      <>
        <View style={styles.signatureSection}>
          <Text style={{ padding: 5, fontSize: 12, color: "#2e4053" }}>
            Signature of the Technician
          </Text>
          <View style={styles.signatureContainer}>
            {signature ? (
              <Image style={styles.signature} src={signature} alt="Signature" />
            ) : (
              <Text style={styles.noSignature}>No signature available</Text>
            )}
            <Text>Signed by the technician</Text>
          </View>
        </View>
      </>

      {/* Footer */}
      <Footer />
    </Page>
  </Document>
);

export default ElectricReport;
