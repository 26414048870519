import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#003366",
    color: "white",
    padding: 20,
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    marginHorizontal: 10,
  },
});

const Footer = () => {
  return (
    <View style={styles.footer}>
      <Text style={styles.footerText}>+61 400 673 184 | www.promender.com.au</Text>
      {/* <View style={styles.iconContainer}>
            <View style={styles.verticalLine} />
            <Text style={styles.footerIcon}>F</Text>
            <Text style={styles.footerIcon}>T</Text>
            <Text style={styles.footerIcon}>I</Text>
          </View> */}
    </View>
  );
};

export default Footer;
