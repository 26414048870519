import React, { useState } from "react";
import { request, setAuthHeader,axiosInstance1 } from "../../config/axiosConfig";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

const Quote = () => {
  const [formData, setFormData] = useState({
    region: "",
    service: "",
    name: "",
    company: "",
    address: "",
    contactNumber: "",
    email: "",
    notes: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log(formData);
    request(axiosInstance1,"POST", "/saveTechnician", formData)
      .then((response) => {
        setAuthHeader(response.data.token);
        console.log("Success");
      })
      .catch((error) => {
        setAuthHeader(null);
        console.log(error);
      });
  };

  return (
    <>
      <PageHeader />
      <NavBar />
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            mt: 3,
            textAlign: "left",
            width: "100%", // Set width to 100%
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "900",
              fontSize: "4vh",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Get a quote from your local Hire A Technician expert
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "30px", fontSize: "3vh" }}
          >
            Tell us a little bit about your project and we'll be in touch
          </Typography>
          <Grid container>{/* <Grid item xs={12}></Grid> */}</Grid>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={8} sm={8}>
                <InputLabel
                  sx={{ fontSize: "2vh", textAlign: "left", fontWeight: "600" }}
                >
                  Region
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Select
                  labelId="region"
                  id="region"
                  value={formData.region}
                  onChange={handleChange}
                  name="region"
                  size="small"
                  fullWidth
                >
                  <MenuItem value="Select">
                    <em>--Select--</em>
                  </MenuItem>
                  <MenuItem value="Region 1">Region 1</MenuItem>
                  <MenuItem value="Region 2">Region 2</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={8} sm={8}>
                <InputLabel
                  sx={{ fontSize: "2vh", textAlign: "left", fontWeight: "600" }}
                >
                  Service
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Select
                  labelId="service"
                  id="service"
                  value={formData.service}
                  onChange={handleChange}
                  name="service"
                  size="small"
                  fullWidth
                >
                  <MenuItem value="Select">
                    <em>--Select--</em>
                  </MenuItem>
                  <MenuItem value="Gas">Gas</MenuItem>
                  <MenuItem value="Electrical">Electrical</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={8} sm={8} marginTop={3}>
                <InputLabel
                  sx={{ fontSize: "2vh", textAlign: "left", fontWeight: "600" }}
                >
                  Your Details
                </InputLabel>
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "1vh" }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Your Name"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "2vh" }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Company Name (Optional)"
                  id="company"
                  name="company"
                  autoComplete="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "2vh" }}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  placeholder="Enter Your Address"
                  id="address"
                  name="address"
                  type="address"
                  autoComplete="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "2vh" }}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  placeholder="Phone"
                  id="contactNumber"
                  name="contactNumber"
                  autoComplete="phone"
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "2vh" }}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  placeholder="Email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8} sx={{ marginTop: "2vh" }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Notes"
                  id="notes"
                  name="notes"
                  autoComplete="off"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sm={8}
              sx={{
                justifyContent: "center",
                marginTop: "4vh",
                marginBottom: "8vh",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 2,
                  background:
                    "#002252",
                  width: "100%", // Set a fixed width
                }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Quote;
