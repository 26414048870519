import React, { useState, useEffect } from "react";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Container,
  Button,
  CssBaseline,
  MenuItem,
  Typography,
  Paper,
  Select,
} from "@mui/material";

const TechnicianProfile = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    username: "",
    typeOfExpertise: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [technician, setTechnician] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTechnician, setEditedTechnician] = useState({});

  useEffect(() => {
    // Fetch technician when component mounts
    fetchTechnician();
  }, []);

  useEffect(() => {
    setEditedTechnician(technician);
  }, [technician]);

  // extracting user is from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var userId = localStorage.getItem("userid");
    console.log("UerID:", userId);
  } else {
    console.log("Value does not exist in local storage.");
  }

  const fetchTechnician = async () => {
    try {
      console.log("Requesting technician data ...");
      const response = await request(
        axiosInstance1,
        "GET",
        `/getTechnicianDetailsByUserId/${userId}`
      );
      setTechnician(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching technicians:", error);
    }
  };

  // capitalize first letter
  const capitalizeFirstLetter = (str) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    setIsEditing(true);
    setEditedTechnician(technician);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedTechnician({});
  };

  const handleFieldChange = (fieldName, value) => {
    let updatedValue = "";
    if (
      fieldName === "emailAddress" ||
      fieldName === "contactNumber" ||
      fieldName === "username" ||
      fieldName === "typeOfExpertise"
    ) {
      updatedValue = value;
    } else {
      updatedValue = capitalizeFirstLetter(value);
    }

    setEditedTechnician((prevTechnician) => ({
      ...prevTechnician,
      [fieldName]: updatedValue,
    }));
  };

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const handleSaveClick = async (event) => {
    event.preventDefault();

    console.log("Edited Technician Data:", editedTechnician);

    setIsEditing(false);
    setTechnician(editedTechnician);

    // Basic form validation
    if (
      !editedTechnician.firstName ||
      !editedTechnician.lastName ||
      !editedTechnician.emailAddress ||
      !editedTechnician.username ||
      !editedTechnician.typeOfExpertise ||
      !editedTechnician.contactNumber ||
      !editedTechnician.address ||
      !editedTechnician.city ||
      !editedTechnician.state ||
      !editedTechnician.postalCode
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance1,
        "PUT",
        "/updateTechnician/" + userId,
        editedTechnician
      );
      console.log(response);
      // setTechnician(editedTechnician);

      setSuccessMessage("Registration successful.");
      setErrorMessage("");
      console.log(successMessage);

      // Clear form after successful submission
      setEditedTechnician({});
    } catch (error) {
      // setAuthHeader(null);
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    }
  };

  return (
    <>
      <div className="lg:px-[240px] px-[20px]">

        <div className="flex flex-col gap-1 pt-5 lg:flex-row">
          <div

            className="flex flex-col w-full lg:w-3/12 lg:h-[540px] h-full bg-white pt-8 items-center"

          >
            <div
              className="flex flex-col items-center w-full h-full"
            >
              <div
                className="text-3xl text-[#002252] font-semibold mb-4"
              >
                {technician.firstName + " " + technician.lastName}
              </div>
              <Grid container textAlign={"center"}>
                <Grid item xs={12}>
                  <div
                    className="text-lg"
                  >
                    {technician.city + " / " + technician.state}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg">
                    {technician.contactNumber}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg"
                  >
                    {technician.postalCode}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg"
                  >
                    {"Licenese No: " + technician.licenseNumber}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            className="flex flex-row w-full lg:w-9/12 lg:h-[540px] h-full bg-white pt-8 px-8"
          >
            <div
              className="flex flex-col w-full h-full"
            >
              <div
                className="text-3xl font-semibold text-[#002252]"
              >
                Technician Profile
              </div>

              <form onSubmit={handleSaveClick}>
                <div className="flex flex-col pt-8">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col items-center w-full lg:w-1/2 lg:flex-row"

                      >


                        <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                        >
                          First Name:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(
                                editedTechnician.firstName
                              ) || ""
                              : technician
                                ? capitalizeFirstLetter(technician.firstName)
                                : "Null"
                          }
                          size="small"
                          sx={{
                            fontSize: "2vh",
                            marginLeft: 1,
                            textTransform: "capitalize",
                            color: "black",
                          }}
                          onChange={(event) =>
                            handleFieldChange("firstName", event.target.value)
                          }
                        />
                      </div>

                      <div
                        className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                      >
                        <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                        >
                          Last Name:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(
                                editedTechnician.lastName
                              ) || ""
                              : technician
                                ? capitalizeFirstLetter(technician.lastName)
                                : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("lastName", event.target.value)
                          }
                        />
                      </div>

                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div
                        className="flex flex-col w-full h-full lg:items-center lg:flex-row lg:w-1/2"
                      >
                        <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                        >
                          Email:
                        </div>
                        <TextField
                        className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? editedTechnician.emailAddress || ""
                              : technician
                                ? technician.emailAddress
                                : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("emailAddress", event.target.value)
                          }
                        />
                      </div>

                      <div
                        className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                      >
                        <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                        >
                          User Name:
                        </div>
                        <TextField
                        className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? editedTechnician.username || ""
                              : technician
                                ? technician.username
                                : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("username", event.target.value)
                          }
                        />
                      </div>

                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                    <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        Expertise:
                      </div>
                        
                      <Select
                      className="w-full lg:w-4/6 "
                        labelId="typeOfExpertise"
                        id="typeOfExpertise"
                        value={
                          isEditing
                            ? editedTechnician.typeOfExpertise || ""
                            : technician
                              ? technician.typeOfExpertise || ""
                              : ""
                        }
                        onChange={(event) =>
                          handleFieldChange(
                            "typeOfExpertise",
                            event.target.value
                          )
                        }
                        name="typeOfExpertise"
                        size="small"
                     
                        disabled={!isEditing}
                      >
                        {/* <MenuItem value="Select">
                      <em>--Select--</em>
                    </MenuItem> */}
                        <MenuItem value="Gas">Gas</MenuItem>
                        <MenuItem value="Electrical">Electrical</MenuItem>
                        <MenuItem value="Smoke">Smoke</MenuItem>
                      </Select>
                       
                    </div>

                    <div
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                          className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        Address:
                      </div>
                      <TextField
                      className="w-full lg:w-4/6"
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(editedTechnician.address) ||
                            ""
                            : technician
                              ? capitalizeFirstLetter(technician.address)
                              : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("address", event.target.value)
                        }
                      />
                    </div>

                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                    <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        Contact:
                      </div>
                      <TextField
                        className="w-full lg:w-4/6 "
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? editedTechnician.contactNumber || ""
                            : technician
                              ? technician.contactNumber
                              : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("contactNumber", event.target.value)
                        }
                      />
                    </div>

                    <div
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        City:
                      </div>
                      <TextField
                        className="w-full lg:w-4/6 "
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(editedTechnician.city) || ""
                            : technician
                              ? capitalizeFirstLetter(technician.city)
                              : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("city", event.target.value)
                        }
                      />
                    </div>

                    </div>


                    <div className="flex flex-col w-full gap-5 lg:flex-row">

                    <div
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        State:
                      </div>
                      <TextField
                        className="w-full lg:w-4/6 "
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(editedTechnician.state) ||
                            ""
                            : technician
                              ? capitalizeFirstLetter(technician.state)
                              : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("state", event.target.value)
                        }
                      />
                    </div>

                    <div
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                    >
                      <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                      >
                        Postal Code:
                      </div>
                      <TextField
                        className="w-full lg:w-4/6 "
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(
                              editedTechnician.postalCode
                            ) || ""
                            : technician
                              ? capitalizeFirstLetter(technician.postalCode)
                              : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("postalCode", event.target.value)
                        }
                      />
                    </div>

                    </div>
                  </div>


                  <Grid sm={12} display={"flex"} justifyContent={"right"}>
                    {isEditing ? (
                      <>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            mt: 5,
                            mb: 2,
                            mr: 2,
                            borderRadius: 5,
                            fontSize: "2vh",
                            background: "#002252",
                            boxShadow: "none",
                            width: "8em",
                            "&:hover": {
                              backgroundColor: "#002252",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleSaveClick}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 5,
                            mb: 2,
                            borderRadius: 5,
                            backgroundColor: "transparent",
                            color: "black",
                            width: "8em",
                            fontSize: "2.1vh",
                            border: "2px solid black",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          mt: 5,
                          mb: 2,
                          borderRadius: 5,
                          fontSize: "2.1vh",
                          background: "#002252",
                          boxShadow: "none",
                          width: "8em",
                          "&:hover": {
                            backgroundColor: "#002252",
                            boxShadow: "none",
                          },
                        }}
                        onClick={handleEditClick}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicianProfile;
