import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { request, axiosInstance2 } from "../../config/axiosConfig";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Grid, Typography } from "@mui/material";
import debounce from "lodash.debounce";

export default function Reports() {
  const statuses = ["Pending", "Approved", "Accepted", "Paid", "Completed"];

  const [inquiaries, setInquiaries] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(statuses[2]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const size = 8;

  const bottomRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchInquiries(page);
  }, [page]);

  useEffect(() => {
    if (dataLoading && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [dataLoading]);

  const fetchInquiries = useCallback(async (pageNumber) => {
    setIsLoading(pageNumber === 0);
    setDataLoading(pageNumber !== 0);
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/paginatedCustomerInquiryListByStatus",
        null,
        { page: pageNumber, size: size }
      );
      const newInquiaries = response.data.content;
      setHasMore(newInquiaries.length > 0);
      setInquiaries((prevInquiaries) =>
        pageNumber === 0 ? newInquiaries : [...prevInquiaries, ...newInquiaries]
      );
    } catch (error) {
      console.error("Error fetching reports data:", error);
    } finally {
      setIsLoading(null);
      setDataLoading(null);
    }
  }, []);

  const debouncedScrollHandler = useMemo(
    () =>
      debounce((e) => {
        const tolerance = 1;
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <=
          e.target.clientHeight + tolerance;
        if (bottom && !dataLoading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 300),
    [dataLoading, hasMore]
  );

  useEffect(() => {
    return () => {
      debouncedScrollHandler.cancel();
    };
  }, [debouncedScrollHandler]);

  const openDetailsPopup = useCallback((technician, status, customer) => {
    setSelectedTechnician(technician);
    setSelectedCustomer(customer);
    setSelectedStatus(status);
    setCurrentStatus(status);
    setShowDetailsPopup(true);
  }, []);

  const closeDetailsPopup = useCallback(() => {
    setShowDetailsPopup(false);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setSelectedTechnician(null);
  }, []);

  const viewDetailsSpacing = useCallback(
    (label, value) => (
      <div className="flex flex-col w-1/2 gap-1">
        <div className="text-sm text-gray-500">{label}</div>
        <div className="text-sm">{value}</div>
      </div>
    ),
    []
  );

  return (
    <>
      <div className="flex flex-col px-4 pt-3 lg:px-16">
        <div className="p-2 text-xl font-bold text-gray-800 sm:text-2xl lg:text-2xl">
          Reports
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center mt-10">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <TableContainer
              elevation={0}
              component={Paper}
              sx={{
                marginTop: 2,
                maxHeight: "560px",
                maxWidth: "95%",
                overflowY: "scroll",
              }}
              onScroll={debouncedScrollHandler}
            >
              <Table
                sx={{
                  minWidth: 650,
                  maxWidth: "86%",
                  marginInline: 10,
                  marginTop: 1,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Inquiry ID
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Customer
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Service Type
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Technician
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Inquiry Status
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Details
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Report
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inquiaries.map((inquiry) => (
                    <TableRow>
                      <TableCell align="center">
                        {inquiry.customerInquiryId}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {inquiry.firstName + " " + inquiry.lastName}
                      </TableCell>
                      <TableCell align="center">{inquiry.type}</TableCell>
                      <TableCell align="center">
                        {inquiry.technicianId
                          ? `${inquiry.technicianId.firstName || ""} ${
                              inquiry.technicianId.lastName || ""
                            }`.trim() || "No Technician Assigned."
                          : "No Technician Assigned."}
                      </TableCell>
                      <TableCell align="center">
                        {inquiry.overallStatus}
                      </TableCell>
                      <TableCell align="center">
                        <button
                          className="px-3 py-2 text-xs font-semibold text-black border-2 border-black rounded-full"
                          onClick={() =>
                            openDetailsPopup(
                              inquiry.technicianId,
                              inquiry.overallStatus,
                              inquiry
                            )
                          }
                        >
                          View Details
                        </button>
                      </TableCell>
                      <TableCell align="center">
                        { inquiry.checklistStatus ?
                          (<button
                            className="w-32 py-2 font-semibold text-white bg-[#002252] rounded-full"
                            onClick={() => {
                              navigate(
                                `/portal/reportsPage/${inquiry.customerInquiryId}`,
                                {
                                  state: { inquiry },
                                }
                              );
                            }}
                          >
                            Report
                          </button>) : "Not submitted"
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                  {dataLoading && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                        <Typography variant="body2">Loading Data...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <tr ref={bottomRef}></tr>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>
      )}
      {showDetailsPopup && (
        <div className="fixed inset-0 flex items-center justify-center mt-5 bg-gray-500 bg-opacity-75">
          <div className="lg:ml-[280px] ml-[50px] pl-6 pr-6 bg-white rounded-lg w-full overflow-auto lg:h-[620px] h-[620px] flex flex-col lg:mr-[40px]">
            <div className="flex justify-end">
              <button
                className="mt-4 text-gray-500 lg:mt-0 hover:text-gray-700"
                onClick={closeDetailsPopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col w-full h-full px-2">
              <div className="flex flex-col w-full h-full mx-4 lg:flex-row">
                {/* Left Column */}
                <div className="flex flex-col w-1/3 h-full gap-6">
                  {/* Technician Details */}
                  <div className="flex flex-col w-full">
                    <div className="text-lg mb-4 font-semibold text-[#002252]">
                      Technician Details
                    </div>
                    {selectedTechnician ? (
                      <>
                        {/* Personal Details */}
                        <div>
                          <div className="mb-2 font-semibold text-md">
                            Personal Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "First Name",
                              selectedTechnician.firstName
                            )}
                            {viewDetailsSpacing(
                              "Last Name",
                              selectedTechnician.lastName
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-6 mb-4">
                            {viewDetailsSpacing(
                              "Email Address",
                              selectedTechnician.emailAddress
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-6 mb-4">
                            {viewDetailsSpacing(
                              "Phone",
                              selectedTechnician.contactNumber
                            )}
                          </div>
                        </div>

                        {/* Address Details */}
                        <div>
                          <div className="mb-2 font-semibold text-md">
                            Address Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "Address",
                              selectedTechnician.address
                            )}
                            {viewDetailsSpacing(
                              "City",
                              selectedTechnician.city
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "State",
                              selectedTechnician.state
                            )}
                            {viewDetailsSpacing(
                              "Postal Code",
                              selectedTechnician.postalCode
                            )}
                          </div>
                        </div>

                        {/* Expertise Details */}
                        <div className="flex flex-col">
                          <div className="mb-2 font-semibold text-md">
                            Expertise Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "Type of Expertise",
                              selectedTechnician.typeOfExpertise
                            )}
                            {viewDetailsSpacing(
                              "License Number",
                              selectedTechnician.licenseNumber
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-sm text-gray-600">
                        A technician has not been accepted yet.
                      </div>
                    )}
                  </div>
                </div>

                {/* Middle Column */}
                <div className="flex flex-col w-1/3 h-full gap-6 pt-[180px] lg:pt-0">
                  {/* Customer Details */}
                  <div className="flex flex-col w-full">
                    <div className="text-lg mb-4 font-semibold text-[#002252]">
                      Customer Details
                    </div>
                    {/* Personal Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Personal Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "First Name",
                          selectedCustomer.firstName
                        )}
                        {viewDetailsSpacing(
                          "Last Name",
                          selectedCustomer.lastName
                        )}
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Email Address",
                          selectedCustomer.emailAddress
                        )}
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Phone",
                          selectedCustomer.contactNumber
                        )}
                      </div>
                    </div>

                    {/* Address Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Address Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Address",
                          selectedCustomer.address
                        )}
                        {viewDetailsSpacing("City", selectedCustomer.city)}
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing("State", selectedCustomer.state)}
                        {viewDetailsSpacing(
                          "Postal Code",
                          selectedCustomer.postalCode
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="flex flex-col w-1/3 h-full gap-3 pt-[160px] lg:pt-0">
                  <div className="text-lg font-semibold text-[#002252]">
                    Inquiry Status
                  </div>
                  <div className="w-full p-2">
                    <div className="relative">
                      {statuses.map((status, index) => (
                        <React.Fragment key={index}>
                          <div className="relative flex items-center mb-3">
                            <div
                              className={`flex items-center justify-center h-4 w-4 rounded-full mr-3 ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "bg-green-500"
                                  : "bg-gray-500"
                              }`}
                            >
                              {index <= statuses.indexOf(currentStatus) && (
                                <FaCheck className="w-2 h-2 text-white" />
                              )}
                            </div>
                            <div
                              className={`text-base cursor-pointer ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "text-green-500"
                                  : "text-gray-500"
                              }`}
                              onClick={() => setSelectedStatus(status)}
                            >
                              {status}
                            </div>
                            {index < statuses.length - 1 && (
                              <div
                                className={`absolute left-1.5 top-5 h-6 w-0.5 ${
                                  index < statuses.indexOf(currentStatus)
                                    ? "bg-green-500"
                                    : "bg-gray-500"
                                }`}
                              ></div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="mt-6">
                      <div className="flex flex-row w-full gap-4 mb-4">
                        <div className="text-sm">
                          <strong>Type:</strong> {selectedCustomer.type}
                        </div>
                      </div>

                      <div className="flex flex-row w-full gap-4 mb-4">
                        <div className="text-sm">
                          <strong>Date:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceDate
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskDate
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeDate
                            : selectedStatus === "Paid"
                            ? selectedCustomer.acknowledgeDate
                            : selectedStatus === "Completed"
                            ? selectedCustomer.acknowledgeDate
                            : "Not Applicable"}
                        </div>
                        <div className="text-sm">
                          <strong>Time:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceTime
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskTime
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeTime
                            : selectedStatus === "Paid"
                            ? selectedCustomer.acknowledgeTime
                            : selectedStatus === "Completed"
                            ? selectedCustomer.acknowledgeDate
                            : "Not Applicable"}
                        </div>
                      </div>
                      <div className="text-sm">
                        <strong>Comments:</strong>{" "}
                        {selectedStatus === "Pending"
                          ? selectedCustomer.serviceDescription
                          : selectedStatus === "Approved"
                          ? selectedCustomer.taskDescription
                          : selectedStatus === "Accepted"
                          ? selectedCustomer.acknowledgeDescription
                          : selectedStatus === "Paid"
                          ? selectedCustomer.acknowledgeDescription
                          : selectedStatus === "Completed"
                          ? selectedCustomer.acknowledgeDate
                          : "Not Applicable"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
