import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ElectricalChecklist from "./ElectricalChecklist";
import GasChecklist from "./GasChecklist";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { axiosInstance1, axiosInstance2, getAuthToken, request, setAuthHeader } from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

const TechnicianChecklist = () => {
  const serviceType = localStorage.getItem("serviceType");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();

  const handleSubmit = async (checkedItems) => {
    console.log("Checked Items:", checkedItems);

    Swal.fire({
      title: `Are you sure you want to submit this report?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#002552",
      cancelButtonColor: "#797d7f",
      confirmButtonText: "Yes, confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);
        submitReport(checkedItems);
      } else if (result.isDismissed) {
        setIsSubmitting(false);
      }
    });
  };

  const submitReport = async (checkedItems) => {
    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance2,
        "POST",
        "/saveTasks",
        checkedItems,
      );
      console.log(response);

      setSuccessMessage("Checklist Submutted");
      setErrorMessage("");
      console.log(successMessage);

      MySwal.fire({
        icon: "success",
        title: "Successful",
        text: "Checklist Submitted!",
      }).then(() => {
        navigate("/portal/technicianDashboard");
      });

    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error submitting checklist.",
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          background: "linear-gradient(to right,#002252, #FCCF00)",
          padding: 2,
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "white", fontWeight: "bold", margin: "auto" }}
        >
          {serviceType === "Electrical"
            ? "Electrical Safety Checklist"
            : "Gas Safety Checklist"}
        </Typography>
      </Box>
      {serviceType === "Electrical" ? (
        <ElectricalChecklist onSubmit={handleSubmit} submitting={isSubmitting}/>
      ) : serviceType === "Gas" ? (
        <GasChecklist onSubmit={handleSubmit} submitting={isSubmitting}/>
      ) : (
        <Typography variant="h6">Invalid Service Type</Typography>
      )}
    </Box>
  );
};

export default TechnicianChecklist;
