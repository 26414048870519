import * as React from "react";
import classNames from "classnames";
import "./../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import NewNavBar from "../../components/appBar/NewNavBar";

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "login",
      firstName: "",
      lastName: "",
      login: "",
      password: "",
      onLogin: props.onLogin,
      onRegister: props.onRegister,
    };
  }

  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  onSubmitLogin = (e) => {
    this.state.onLogin(e, this.state.login, this.state.password);
  };

  onSubmitRegister = (e) => {
    this.state.onRegister(
      e,
      this.state.firstName,
      this.state.lastName,
      this.state.login,
      this.state.password
    );
  };

  render() {
    return (
      <>
        <NewNavBar />
        <div className="row justify-content-center">
          {/* <Header pageTitle="ProMender" logoSrc={logo} /> */}
          <div className="mt-4"></div>

          <div className="col-4">
            <ul
              className="mb-3 nav nav-pills nav-justified"
              id="ex1"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={classNames(
                    "nav-link",
                    this.state.active === "login" ? "active" : ""
                  )}
                  id="tab-login"
                  onClick={() => this.setState({ active: "login" })}
                >
                  Login
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={classNames(
                    "nav-link",
                    this.state.active === "register" ? "active" : ""
                  )}
                  id="tab-register"
                  onClick={() => this.setState({ active: "register" })}
                >
                  Register
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className={classNames(
                  "tab-pane",
                  "fade",
                  this.state.active === "login" ? "show active" : ""
                )}
                id="pills-login"
              >
                <form onSubmit={this.onSubmitLogin}>
                  <div className="mb-4 form-outline">
                    <input
                      type="login"
                      id="loginName"
                      name="login"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="loginName">
                      Username
                    </label>
                  </div>

                  <div className="mb-4 form-outline">
                    <input
                      type="password"
                      id="loginPassword"
                      name="password"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="loginPassword">
                      Password
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="mb-4 btn btn-primary btn-block"
                  >
                    Login
                  </button>
                </form>
              </div>
              <div
                className={classNames(
                  "tab-pane",
                  "fade",
                  this.state.active === "register" ? "show active" : ""
                )}
                id="pills-register"
              >
                <form onSubmit={this.onSubmitRegister}>
                  <div className="mb-4 form-outline">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="firstName">
                      First name
                    </label>
                  </div>

                  <div className="mb-4 form-outline">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="lastName">
                      Last name
                    </label>
                  </div>

                  <div className="mb-4 form-outline">
                    <input
                      type="text"
                      id="login"
                      name="login"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="login">
                      Username
                    </label>
                  </div>

                  <div className="mb-4 form-outline">
                    <input
                      type="password"
                      id="registerPassword"
                      name="password"
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                    <label className="form-label" htmlFor="registerPassword">
                      Password
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="mb-3 btn btn-primary btn-block"
                  >
                    Sign up
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
