import React, { useEffect, useState } from "react";
import CompletedInquiryCard from "../../components/dashboard/InquiryCard"; 
import CustomerViewDetailsPopup from "../../components/dashboard/CustomerViewDetails"; 
import {
  request,
  axiosInstance2,
} from "../../config/axiosConfig";

const CustomerCompletedInquiries = () => {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();

  const userId = localStorage.getItem("userid");

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/getCustomerCompletedInquiries",
        null,
        { userid: userId }
      );
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const openDetailsPopup = (technicianId, overallStatus, inquiryData) => {
    setShowDetailsPopup(true);
    setCurrentStatus(overallStatus);
    setSelectedTechnician(technicianId);
    setSelectedStatus(overallStatus);
    setSelectedCustomer(inquiryData);
  };

  const closeDetailsPopup = () => {
    setSelectedTechnician(null);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
  };

  return (
    <div className="flex flex-column justify-start w-full px-4 mt-4 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
      Completed Inquiries
      <div className="overflow-y-auto max-h-[500px] mb-4 mt-8">
        {customers.map((customerData, index) => (
          <CompletedInquiryCard
            key={index}
            inquiryData={customerData}
            openDetailsPopup={openDetailsPopup}
          />
        ))}
      </div>

      {showDetailsPopup && (
        <CustomerViewDetailsPopup
          closeDetailsPopup={closeDetailsPopup}
          selectedTechnician={selectedTechnician}
          overallStatus={currentStatus}
          selectedCustomer={selectedCustomer}
        />
      )}
    </div>
  );
};

export default CustomerCompletedInquiries;
