import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CheckBoxGroup from "../checklists/CheckBoxGroup";
import { electricalChecklistItems } from "./List";
import ClearIcon from "@mui/icons-material/Clear";

const ElectricalChecklist = ({ onSubmit, submitting }) => {
  const checklist = electricalChecklistItems;

  const midIndex1 = Math.ceil(checklist.subItems1.length / 2);
  const midIndex2 = Math.ceil(checklist.subItems2.length / 2);
  const midIndex3a = Math.ceil(checklist.subItems3.subItems3a.length / 2);
  const midIndex3b = Math.ceil(checklist.subItems3.subItems3b.length / 2);

  const firstColumn1 = checklist.subItems1.slice(0, midIndex1);
  const secondColumn1 = checklist.subItems1.slice(midIndex1);

  const firstColumn2 = checklist.subItems2.slice(0, midIndex2);
  const secondColumn2 = checklist.subItems2.slice(midIndex2);

  const firstColumn3a = checklist.subItems3.subItems3a.slice(0, midIndex3a);
  const secondColumn3a = checklist.subItems3.subItems3a.slice(midIndex3a);

  const firstColumn3b = checklist.subItems3.subItems3b.slice(0, midIndex3b);
  const secondColumn3b = checklist.subItems3.subItems3b.slice(midIndex3b);

  const initialCheckedItems = Object.fromEntries([
    ...checklist.subItems1.map((item) => [`subItems1-${item}`, false]),
    ...checklist.subItems2.map((item) => [`subItems2-${item}`, false]),
    ...checklist.subItems3.subItems3a.map((item) => [
      `subItems3a-${item}`,
      false,
    ]),
    ...checklist.subItems3.subItems3b.map((item) => [
      `subItems3b-${item}`,
      false,
    ]),
  ]);

  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const [smokeAlarmCheckedYes, setSmokeAlarmCheckedYes] = useState(false);
  const [smokeAlarmCheckedNo, setSmokeAlarmCheckedNo] = useState(false);
  const [observationsList, setObservationsList] = useState([""]);
  const [error, setError] = useState(false);

  const inquiryId = localStorage.getItem("InquiryId");
  const userId = localStorage.getItem("userid");

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked,
    }));
  };

  const handleSmokeAlarmChangeYes = () => {
    setSmokeAlarmCheckedYes(true);
    setSmokeAlarmCheckedNo(false);
  };

  const handleSmokeAlarmChangeNo = () => {
    setSmokeAlarmCheckedYes(false);
    setSmokeAlarmCheckedNo(true);
  };

  const handleObservationsChange = (index, event) => {
    const newObservations = [...observationsList];
    newObservations[index] = event.target.value;
    setObservationsList(newObservations);

    if (error && event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleAddObservation = () => {
    if (observationsList[observationsList.length - 1].trim() === "") {
      setError(true);
    } else {
      setObservationsList([...observationsList, ""]);
      setError(false);
    }
  };

  const handleRemoveObservation = () => {
    if (observationsList.length > 1) {
      const newObservations = observationsList.slice(0, -1);
      setObservationsList(newObservations);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = [];

    const addItem = (title, subTitle, item, status) => {
      dataToSubmit.push({
        user: { id: userId },
        inquiry: { customerInquiryId: inquiryId },
        type: "Electrical",
        title,
        subTitle,
        description: item,
        status,
      });
    };

    checklist.subItems1.forEach((item) => {
      const status = checkedItems[`subItems1-${item}`] ? "Yes" : "No";
      addItem(checklist.subTitle1, "", `${item}`, status);
    });
    checklist.subItems2.forEach((item) => {
      const status = checkedItems[`subItems2-${item}`] ? "Yes" : "No";
      addItem(checklist.subTitle2, "", `${item}`, status);
    });
    checklist.subItems3.subItems3a.forEach((item) => {
      const status = checkedItems[`subItems3a-${item}`] ? "Yes" : "No";
      addItem(checklist.subTitle3, checklist.subTitle3a, `${item}`, status);
    });
    checklist.subItems3.subItems3b.forEach((item) => {
      const status = checkedItems[`subItems3b-${item}`] ? "Yes" : "No";
      addItem(checklist.subTitle3, checklist.subTitle3b, `${item}`, status);
    });

    const description4 =
      "All smoke alarm are correctly installed and in working condition; and have been tested according to the manufacturer's instructions.";
    const status4 = smokeAlarmCheckedYes
      ? "Yes"
      : smokeAlarmCheckedNo
      ? "No"
      : "N/A";
    addItem(checklist.subTitle4, "", description4, status4);

    observationsList.forEach((observation) => {
      const description = observation;
      addItem(checklist.subTitle5, "", description, "");
    });

    onSubmit(dataToSubmit, handleClearForm());
  };

  const handleClearForm = () => {
    setCheckedItems("");
    setSmokeAlarmCheckedYes("");
    setSmokeAlarmCheckedNo("");
    setObservationsList([]);
  };

  return (
    <Box
      sx={{
        width: "60%",
        height: "50%",
        margin: "auto",
        alignItems: "center",
        boxShadow: 2,
        borderRadius: 5,
        padding: 6,
        backgroundColor: "white",
      }}
    >
      <Grid container>
        <Grid item sm={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", backgroundColor: "#FCCF00", padding: 1 }}
          >
            {checklist.subTitle1}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "23%",
              marginTop: 2,
            }}
          >
            <CheckBoxGroup
              items={firstColumn1.map((item) => `subItems1-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
            <CheckBoxGroup
              items={secondColumn1.map((item) => `subItems1-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle2}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10%",
              marginTop: 2,
            }}
          >
            <CheckBoxGroup
              items={firstColumn2.map((item) => `subItems2-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
            <CheckBoxGroup
              items={secondColumn2.map((item) => `subItems2-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle3}
          </Typography>
          <Typography variant="h7" sx={{ marginTop: 2, fontWeight: "bold" }}>
            {checklist.subTitle3a}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10%",
              marginTop: 2,
            }}
          >
            <CheckBoxGroup
              items={firstColumn3a.map((item) => `subItems3a-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
            <CheckBoxGroup
              items={secondColumn3a.map((item) => `subItems3a-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Box>

          <Typography variant="h7" sx={{ marginTop: 2, fontWeight: "bold" }}>
            {checklist.subTitle3b}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25%",
              marginTop: 2,
            }}
          >
            <CheckBoxGroup
              items={firstColumn3b.map((item) => `subItems3b-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
            <CheckBoxGroup
              items={secondColumn3b.map((item) => `subItems3b-${item}`)}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle4}
          </Typography>
          <Typography variant="h8">
            All smoke alarm are correctly installed and in working condition;
            and have been tested according to the manufacturer's instructions.
          </Typography>
          <Grid
            sm={2.2}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              paddingLeft: 1,
              marginTop: 1,
              borderRadius: 2,
            }}
          >
            <FormControlLabel
              label="Yes"
              control={
                <Checkbox
                  checked={smokeAlarmCheckedYes}
                  onChange={handleSmokeAlarmChangeYes}
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={smokeAlarmCheckedNo}
                  onChange={handleSmokeAlarmChangeNo}
                />
              }
              label="No"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle5}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Grid item xs={12} sm={9}>
              {observationsList.map((value, index) => (
                <div key={index}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your observations here"
                    value={value}
                    onChange={(event) => handleObservationsChange(index, event)}
                    sx={{ mb: 2 }}
                    error={error && index === observationsList.length - 1}
                    helperText={
                      error && index === observationsList.length - 1
                        ? "Field is empty"
                        : ""
                    }
                  />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={handleAddObservation}
                sx={{ color: "#002252", mb: 2 }}
              >
                + Add Observation
              </Button>
              {observationsList.length > 1 && (
                <Button onClick={handleRemoveObservation} sx={{ color: "red" }}>
                  - Remove Observation
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Button
          fullWidth
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            marginTop: 3,
            backgroundColor: "#002252",
            color: "#FFFFFF",
            border: "2px solid transparent", // Default border
            "&:hover": {
              backgroundColor: "#FFFFFF", // Background color on hover
              color: "#002252", // Text color on hover (blue)
              border: "2px solid #002252", // Border color on hover (blue)
            },
            "&:disabled": {
              color: "#abb2b9", 
              border: "none",
              backgroundColor: "#e5e7e9",
            },
          }}
          disabled={submitting}
        >
          {submitting ? "Please wait..." : "Submit"}
        </Button>

        <Button
          onClick={handleClearForm}
          fullWidth
          variant="outlined"
          sx={{
            mt: 1,
            mb: 2,
            borderRadius: 2,
          }}
        >
          Clear Form
        </Button>
      </Grid>
    </Box>
  );
};

export default ElectricalChecklist;
