import React, { useState, useEffect } from "react";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Container,
  Button,
  CssBaseline,
  Typography,
  Paper,
} from "@mui/material";

const CustomerProfile = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCustomerData, setEditedCustomerData] = useState({});

  useEffect(() => {
    // Fetch technician when component mounts
    fetchCustomerData();
  }, []);

  useEffect(() => {
    setEditedCustomerData(customerData);
  }, [customerData]);

  // extracting user is from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var customerId = localStorage.getItem("userid");
    console.log("UserID:", customerId);
  } else {
    console.log("Value does not exist in local storage.");
  }

  const fetchCustomerData = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getCustomerDetailsByUserId/${customerId}`
      );
      setCustomerData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  // capitalize first letter
  const capitalizeFirstLetter = (str) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    setIsEditing(true);
    setEditedCustomerData(customerData);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedCustomerData({});
  };

  const handleFieldChange = (fieldName, value) => {
    let updatedValue = "";
    if (
      fieldName === "emailAddress" ||
      fieldName === "contactNumber" ||
      fieldName === "username"
    ) {
      updatedValue = value;
    } else {
      updatedValue = capitalizeFirstLetter(value);
    }

    setEditedCustomerData((prevCustomerData) => ({
      ...prevCustomerData,
      [fieldName]: updatedValue,
    }));
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    console.log("Edited Customer Data:", editedCustomerData);

    setIsEditing(false);
    setCustomerData(editedCustomerData);

    // Basic form validation
    if (
      !editedCustomerData.firstName ||
      !editedCustomerData.lastName ||
      !editedCustomerData.emailAddress ||
      !editedCustomerData.username ||
      !editedCustomerData.contactNumber ||
      !editedCustomerData.address ||
      !editedCustomerData.city ||
      !editedCustomerData.state ||
      !editedCustomerData.postalCode
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance1,
        "PUT",
        `/updateCustomer/${customerId}`,
        editedCustomerData
      );
      console.log(response);

      setSuccessMessage("Updated successfully");
      setErrorMessage("");
      console.log(successMessage);

      setEditedCustomerData({});
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    }
  };

  return (
    <div className="lg:px-[240px] px-[20px]">
      <div className="flex flex-col gap-1 pt-5 lg:flex-row">
        <div
          className="flex flex-col w-full lg:w-3/12 lg:h-[540px] h-full bg-white pt-8 items-center"
        >
          <div
            className="flex flex-col items-center w-full h-full"
          >
            <div className="text-3xl text-[#002252] font-semibold mb-4"
            >
              {customerData.firstName + " " + customerData.lastName}
            </div>
            <Grid container textAlign={"center"}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "2.3vh", textTransform: "capitalize",  }}
                >
                  {customerData.city + " / " + customerData.state}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "2.3vh" }}>
                  {customerData.contactNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "2.3vh", textTransform: "capitalize" }}
                >
                  {customerData.postalCode}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          className="flex flex-row w-full lg:w-9/12 lg:h-[540px] h-full bg-white pt-8 px-8"
        >
          <div
            className="flex flex-col w-full h-full"
          >
            <div
              className="text-3xl font-semibold text-[#002252]"
            >
              Customer Profile
            </div>

            <form onSubmit={handleUpdate}>
              <div className="flex flex-col pt-8">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col w-full gap-5 lg:flex-row">
                  <div className="flex flex-col items-center w-full lg:w-1/2 lg:flex-row"
                  >
                    <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      First Name:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6"
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(
                              editedCustomerData.firstName
                            ) || ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.firstName)
                          : "Null"
                      }
                      size="small"
                      sx={{
                        fontSize: "2vh",
                        marginLeft: 1,
                        textTransform: "capitalize",
                        color: "black",
                      }}
                      onChange={(event) =>
                        handleFieldChange("firstName", event.target.value)
                      }
                    />
                  </div>

                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      Last Name:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6"
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(
                              editedCustomerData.lastName
                            ) || ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.lastName)
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("lastName", event.target.value)
                      }
                    />
                  </div>

                  </div>

                  <div className="flex flex-col w-full gap-5 lg:flex-row">
                  <div 
                      className="flex flex-col w-full h-full lg:items-center lg:flex-row lg:w-1/2"
                  >
                    <div 
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      Email:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6"
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? editedCustomerData.emailAddress || ""
                          : customerData
                          ? customerData.emailAddress
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("emailAddress", event.target.value)
                      }
                    />
                  </div>

                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div 
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      User Name:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6"
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? editedCustomerData.username || ""
                          : customerData
                          ? customerData.username
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("username", event.target.value)
                      }
                    />
                  </div>

                  </div>
                  
                  <div className="flex flex-col w-full gap-5 lg:flex-row">
                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      Address:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6 "
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(editedCustomerData.address) ||
                            ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.address)
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("address", event.target.value)
                      }
                    />
                  </div>

                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div 
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      Contact:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6"
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? editedCustomerData.contactNumber || ""
                          : customerData
                          ? customerData.contactNumber
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("contactNumber", event.target.value)
                      }
                    />
                  </div>

                  </div>

                  <div className="flex flex-col w-full gap-5 lg:flex-row">
                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      City:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6 "
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(editedCustomerData.city) || ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.city)
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("city", event.target.value)
                      }
                    />
                  </div>

                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div 
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      State:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6 "
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(editedCustomerData.state) ||
                            ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.state)
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("state", event.target.value)
                      }
                    />
                  </div>

                  </div>

                  <div className="flex flex-col w-full gap-5 lg:flex-row">
                  <div 
                      className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"
                  >
                    <div 
                        className="w-full text-lg font-semibold text-gray-600 lg:w-2/6"
                    >
                      Postal Code:
                    </div>
                    <TextField
                        className="w-full lg:w-4/6 "
                      disabled={!isEditing}
                      value={
                        isEditing
                          ? capitalizeFirstLetter(
                              editedCustomerData.postalCode
                            ) || ""
                          : customerData
                          ? capitalizeFirstLetter(customerData.postalCode)
                          : "Null"
                      }
                      size="small"
                      sx={{ fontSize: "2vh", marginLeft: 1 }}
                      onChange={(event) =>
                        handleFieldChange("postalCode", event.target.value)
                      }
                    />
                  </div>

                    <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2"></div>

                  </div>
                </div>
                <Grid sm={12} display={"flex"} justifyContent={"right"}>
                  {isEditing ? (
                    <>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          mt: 5,
                          mb: 2,
                          mr: 2,
                          borderRadius: 5,
                          fontSize: "2.1vh",
                          background:
                            "#002252",
                            boxShadow: "none",
                          width: "8em",
                          "&:hover": {
                            backgroundColor: "#002252",
                            boxShadow: "none",
                          },
                        }}
                        onClick={handleUpdate}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 5,
                          mb: 2,
                          borderRadius: 5,
                          backgroundColor: "transparent",
                          color: "black",
                          width: "8em",
                          fontSize: "2.1vh",
                          border: "2px solid black",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 5,
                        mb: 2,
                        borderRadius: 5,
                        fontSize: "2.1vh",
                        background:
                          "#002252",
                          boxShadow: "none",
                        width: "8em",
                        "&:hover": {
                          backgroundColor: "#002252",
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
