import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const CheckBoxGroup = ({ items, checkedItems, handleCheckboxChange }) => {
  return (
    <FormGroup>
    {items.map((item, index) => (
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            checked={checkedItems[item] || false}
            onChange={handleCheckboxChange}
            name={item} 
          />
        }
        label={item.split('-')[1]} 
      />
    ))}
  </FormGroup>
  
  );
};

export default CheckBoxGroup;
