import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function LoginMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonStyle = {
    background: 'linear-gradient(to right, #002252, #FCCF00)',
    color: 'white',
    fontWeight: "bold",
  };
  
  const navigate = useNavigate();

  const handleCustomerClick = () => {
    navigate('/customerLogin');
    handleClose(); // Close the dropdown after navigation
  };

  const handleTechnicianClick = () => {
    navigate('/technicianLogin');
    handleClose(); // Close the dropdown after navigation
  };

  const handleAdminClick = () => {
    navigate('/adminLogin');
    handleClose(); // Close the dropdown after navigation
  };

  const menuButtonStyle = {
    width: '100%',
    padding: '8px', // Smaller padding
    color: 'black', // Black text color
    fontWeight: 600, // Semi-bold text
    textTransform: 'none', // Prevent uppercase transformation
    fontSize: '14px', // Smaller font size
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={buttonStyle} // Apply button style
      >
        Login
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} style={{ width: "180px", height: "40px", padding: 0 }}>
          <Button onClick={handleCustomerClick} style={menuButtonStyle}>Customer</Button>
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ width: "180px", height: "40px", padding: 0 }}>
          <Button onClick={handleTechnicianClick} style={menuButtonStyle}>Technician</Button>
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ width: "180px", height: "40px", padding: 0 }}>
          <Button onClick={handleAdminClick} style={menuButtonStyle}>Admin</Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
