import * as React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";

function MainListItems() {
  const [role, setRole] = React.useState(null);

  React.useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    setRole(user.roleId);
    console.log(user.roleId);
  }, []);

  return (
    <>
      <React.Fragment>
        {/* AdminList */}
        {role === 1 && (
          <ListItemButton component={Link} to="adminDashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {/* {role === 1 && (
        <ListItemButton component={Link} to="orders">
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItemButton>
        )} */}
        {role === 1 && (
          <ListItemButton component={Link} to="customerList">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItemButton>
        )}
        {role === 1 && (
          <ListItemButton component={Link} to="technicianList">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Technicians" />
          </ListItemButton>
        )}
        {role === 1 && (
          <ListItemButton component={Link} to="technicianPaymentList">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Technician Payments" />
          </ListItemButton>
        )}
        {role === 1 && (
          <ListItemButton component={Link} to="reports">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        )}
        {role === 1 && (
          <ListItemButton component={Link} to="adminCompletedInq">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Completed Inquiries" />
          </ListItemButton>
        )}
        {/* {role === 1 && (
        <ListItemButton component={Link} to="/integrations">
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Integrations" />
        </ListItemButton>
        )} */}
        {/* custmoerlist  */}

        {role === 2 && (
          <ListItemButton component={Link} to="customerDashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}

        {role === 2 && (
          <ListItemButton component={Link} to="customerinq">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="New Inquiry" />
          </ListItemButton>
        )}

        {role === 2 && (
          <ListItemButton component={Link} to="customerCompletedInq">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Completed Inquiries" />
          </ListItemButton>
        )}

        {/* technicianList  */}

        {role === 3 && (
          <ListItemButton component={Link} to="technicianDashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {role === 3 && (
          <ListItemButton component={Link} to="technicianCompletedInq">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Completed Inquiries" />
          </ListItemButton>
        )}
      </React.Fragment>
    </>
  );
}

export default MainListItems;
