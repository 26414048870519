import axios from "axios";

export const getAuthToken = () => {
  return window.localStorage.getItem("auth_token");
};

export const setAuthHeader = (token) => {
  if (token !== null) {
    window.localStorage.setItem("auth_token", token);
  } else {
    window.localStorage.removeItem("auth_token");
  }
};

// axios.defaults.baseURL = "http://localhost:8185/";
// axios.defaults.headers.post["Content-Type"] = "application/json";

// Create an instance for the first base URL
export const axiosInstance1 = axios.create({
  baseURL: "https://promender-main-h4hge9f5f6f0hbcn.eastus-01.azurewebsites.net/",
  headers: {
    "Content-Type": "application/json",
  },
});

// Create an instance for the second base URL
export const axiosInstance2 = axios.create({
  baseURL: "https://promender-inquiry.azurewebsites.net/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstance3 = axios.create({
  baseURL: "https://promender-main-h4hge9f5f6f0hbcn.eastus-01.azurewebsites.net/",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosInstance4 = axios.create({
  baseURL: "https://promender-inquiry.azurewebsites.net/",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const request = (instance, method, url, data, params) => {
  let headers = {};

  if (getAuthToken() !== null && getAuthToken() !== "null") {
    headers = { Authorization: `Bearer ${getAuthToken()}` };
  }

  const config = {
    headers: headers,
    params: params,
  };

  switch (method.toUpperCase()) {
    case "GET":
      return instance.get(url, config);
    case "POST":
      return instance.post(url, data, config);
    case "PUT":
      return instance.put(url, data, config);
    case "DELETE":
      return instance.delete(url, config);
    // Add more HTTP methods as needed
    default:
      throw new Error("Unsupported HTTP method");
  }
};
