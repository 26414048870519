import React, { useState, useEffect } from "react";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
  axiosInstance2,
} from "../../config/axiosConfig";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const Inquiry = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    type: [],
    serviceDate: "",
    serviceTime: "",
    serviceDescription: "",
    callRequest: "",
    userId: {
      id: "",
    },
  });

  const types = ["Gas", "Electrical", "Smoke"];

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [editedCustomerData, setEditedCustomerData] = useState({});
  const [emailError, setEmailError] = useState("");
  const [isCustomerActive, setIsCustomerActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch customer data when component mounts
    fetchCustomerData();
  }, []);

  useEffect(() => {
    setEditedCustomerData(customerData);
  }, [customerData]);

  // extracting user id from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var customerId = localStorage.getItem("userid");
    console.log("UserID:", customerId);
  } else {
    console.log("Value does not exist in local storage");
  }

  const fetchCustomerData = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getCustomerDetailsByUserId/${customerId}`
      );

      const fetchedData = response.data;
      console.log("customer data: ", fetchedData)

      if (fetchedData.status === "Active") {
        setIsCustomerActive(true);
      } else {
        setIsCustomerActive(false);
      }

      const currentDate = dayjs().format("MM/DD/YYYY");

      const formattedData = {
        ...fetchedData,
        serviceDate: currentDate,
      };
      setFormData(formattedData);
      console.log("Filled data: ", formattedData);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleDatePickerChange = (date) => {
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormData({ ...formData, serviceDate: formattedDate });
  };

  const handleTimePickerChange = (time) => {
    const formattedtime = dayjs(time).format("hh:mm A");
    setFormData({ ...formData, serviceTime: formattedtime });
  };

  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "emailAddress") {
      setEmailError(validateEmail(value) ? "" : "Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleTypeChange = (event) => {
    const { name, checked } = event.target;
    let newSelectedTypes = formData.type ? [...formData.type] : [];

    if (checked) {
      newSelectedTypes.push(name);
    } else {
      newSelectedTypes = newSelectedTypes.filter((type) => type !== name);
    }
    setFormData({ ...formData, type: newSelectedTypes });
  };

  const handleTimeChange = (event, value) => {
    setFormData({ ...formData, serviceTime: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic form validation
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.emailAddress ||
      !formData.contactNumber ||
      !formData.address ||
      !formData.city ||
      !formData.state ||
      !formData.postalCode ||
      !formData.serviceDescription
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    setIsSubmitting(true);
    try {
      setAuthHeader(getAuthToken());

      const userID = localStorage.getItem("userid");
      console.log("UserID:", userID);

      const updatedFormData = {
        ...formData,
        serviceTime: formData.serviceTime,
        userId: {
          id: userID,
        },
      };

      console.log("Submitted form:", updatedFormData);

      const responseInq = await request(
        axiosInstance2,
        "POST",
        "/saveCustomerInquiry",
        updatedFormData
      );

      setSuccessMessage("Success! Your inquiry has been sent.");
      setErrorMessage("");

      // Clear form after successful submission
      setTimeout(() => {
        setFormData({
          firstName: "",
          lastName: "",
          emailAddress: "",
          contactNumber: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
          type: "",
          serviceDate: "",
          serviceTime: "",
          serviceDescription: "",
          callRequest: "",
          userId: {
            id: "",
          },
        });
        setSuccessMessage("");
        fetchCustomerData();
        setEmailError("");
      }, 3000); // Clear form and success message after 3 seconds
    } catch (error) {
      setAuthHeader(null);
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNumber: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      type: "",
      serviceDate: "",
      serviceTime: "",
      serviceDescription: "",
      callRequest: "",
      userId: {
        id: "",
      },
    });
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <Paper sx={{ width: "90%", maxWidth: 800, padding: 3, margin: "auto", marginTop: 5}}>
          <Box sx={{ background: "linear-gradient(to right,#002252, #FCCF00)", padding: 3, display: "flex", alignItems: "center",}}>
            <Typography variant="h5" sx={{ color: "white", fontWeight: "bold", marginBottom: 2, margin: "auto", }}>New Inquiry</Typography>
          </Box>
          {isCustomerActive ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} mt={1} padding={3}>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> First Name:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="firstName" name="firstName" value={formData.firstName} onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Last Name:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="lastName" name="lastName" value={formData.lastName} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Email Address:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="emailAddress" name="emailAddress" value={formData.emailAddress} onChange={handleChangeEmail} error={!!emailError} helperText={emailError} sx={{borderColor: emailError ? "red" : "",}}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Contact number:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleChange} inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 10,}} onInput={(e) => {e.target.value = e.target.value.replace(/[^0-9]/g, '');}}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Address:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="address" name="address" value={formData.address} onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> City:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="city" name="city" value={formData.city} onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> State:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="state" name="state" value={formData.state} onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Postal Code:
                  </InputLabel>
                  <TextField size="small" required fullWidth id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}></Grid>

                <Grid item xs={12} sm={12} mt={2} mb={1}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Type of Service:
                  </InputLabel>
                  <FormGroup row>
                    {types.map((type) => (
                      <FormControlLabel key={type}
                        control={
                          <Checkbox checked={formData.type? formData.type.includes(type): false} onChange={handleTypeChange} name={type}/>
                        }
                        label={type} sx={{ marginRight: 4 }}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Preferred Date:</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      size="small"
                      value={dayjs(formData.serviceDate)}
                      onChange={handleDatePickerChange}
                      minDate={dayjs()}  // Set the minimum date to today
                      format="DD/MM/YYYY"  // Set date format to dd/mm/yyyy
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Preferred Time:
                  </InputLabel>
                  <Select fullWidth displayEmpty name="time" type="serviceTime" value={formData.serviceTime} onChange={handleTimeChange}>
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Morning"}>Morning</MenuItem>
                    <MenuItem value={"Noon"}>Noon</MenuItem>
                    <MenuItem value={"Evening"}>Evening</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={-8} padding={3} style={{ paddingRight: "30px" }}>
                <Grid item xs={12} md={10} lg={15}>
                  <Box sx={{ mt: 4 }}>
                    <TextField label="Add comments here" multiline rows={8} fullWidth style={{ paddingTop: "5px" }} id="serviceDescription" name="serviceDescription" value={formData.serviceDescription} onChange={handleChange}/>                   
                  </Box>
                </Grid>
              </Grid>

              {successMessage && (
                <Typography sx={{ color: "green", textAlign: "center" }}>{successMessage}</Typography>
              )}

              {errorMessage && (
                <Typography sx={{ color: "red", textAlign: "center" }}>{errorMessage}</Typography>
              )}

              <Button className="bg-[#002252]" type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2, borderRadius: 2}} disabled={isSubmitting}>Submit</Button>
              <Button onClick={handleClearForm} fullWidth variant="outlined" sx={{ mt: 1, mb: 2, borderRadius: 2 }}>Clear Form</Button>
            </form>
          </Box>
          ):(
            <Typography variant="h6" color="error" align="center" sx={{ marginTop: 2 }}>
              Your account is inactive. Please verify your email to proceed.
            </Typography>
          )}
        </Paper>
      </Container>
    </>
  );
};

const types = ["-- Select --", "Gas", "Electrical"];

export default Inquiry;
