import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ImageModal = ({ isOpen, imageSrc, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={onClose}
    >
      <div
        className="card"
        onClick={(e) => {
          e.stopPropagation(); // Prevent closing modal when clicking inside
        }}
        // style={{ width: '950px', height: '550px' }} // Fixed size card
      >
        <div className="card-header d-flex justify-content-end">
          <button
            className="btn btn-danger"
            onClick={onClose}
          >
            Close
          </button>
        </div>
        <div className="p-0 card-body d-flex justify-content-center align-items-center">
          <img
            src={imageSrc}
            alt="Modal Content"
            className="object-contain w-[800px] img-fluid h-1/2"
            // style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} // Ensure image fits within the card

          />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
