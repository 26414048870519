import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../logo.png";
import { Link, useLocation } from "react-router-dom";
import LoginMenu from "./LoginMenu";
import RegistrationMenu from "./RegistrationMenu";
import OurServicesMenu from "./OurServicesMenu";

const buttons = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/aboutus" },
  { name: "Instructions & Guidelines", path: "/instructions" },
  { name: "Contact Us", path: "/contactus" },
];

const buttonList=[
  { name: "OurServices", path:"/ourServices"},
  { name: "TechnicianRegistration", path:"/technicianReg"}
];

const signInList = [
  { name: "Technician Sign in", path: "/technicianLogin" },
  { name: "Customer Sign in", path: "/customerLogin" },
  { name: "Admin Sign in", path: "/adminLogin" }
];

const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 6vh;
  flex-wrap: wrap;

  @media (max-width: 1500px) {
    gap: 10px;
  }
`;

export default function NavBar() {
  const [activeButton, setActiveButton] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  const handleButtonClick = (name) => {
    setActiveButton(name);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsDrawerOpen(open);
  };

  // Set active button based on the current path
  React.useEffect(() => {
    const currentPath = location.pathname;
    const active = buttons.find((button) => button.path === currentPath)?.name;
    if (active) {
      setActiveButton(active);
    }
  }, [location]);

  return (
    <>
      <AppBar position="static" style={{ background: "white" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {isMobile && (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to={"/"}>
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "200px", height: "50px" }}
            />
          </Link>
          {!isMobile && (
            <StyledButtonWrapper>
              {buttons.map(({ name, path }) => (
                <Button
                  key={name}
                  style={{
                    color: activeButton === name ? "#FCCF00" : "black",
                    borderBottom: activeButton === name ? "2px solid #FCCF00" : "",
                    fontWeight: "bold",
                  }}
                  component={Link}
                  to={path}
                  onClick={() => handleButtonClick(name)}
                >
                  {name}
                </Button>
              ))}

              <OurServicesMenu />
              <RegistrationMenu />
              <LoginMenu />
            </StyledButtonWrapper>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <List>
            {buttons.map(({ name, path }) => (
              <ListItem
                style={{
                  color: activeButton === name ? "#FCCF00" : "black",
                  borderBottom: activeButton === name ? "2px solid #FCCF00" : "",
                }}
                button
                key={name}
                component={Link}
                to={path}
                onClick={() => handleButtonClick(name)}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}

            {buttonList.map(({ name, path }) => (
              <ListItem
                style={{
                  color: activeButton === name ? "#FCCF00" : "black",
                  borderBottom: activeButton === name ? "2px solid #FCCF00" : "",
                }}
                button
                key={name}
                component={Link}
                to={path}
                onClick={() => handleButtonClick(name)}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}

            {signInList.map(({ name, path }) => (
              <ListItem
                style={{
                  color: activeButton === name ? "#FCCF00" : "black",
                  borderBottom: activeButton === name ? "2px solid #FCCF00" : "",
                }}
                button
                key={name}
                component={Link}
                to={path}
                onClick={() => handleButtonClick(name)}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
}
