import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import RadioButtonGroup from "../checklists/RadioButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";

const GasChecklist = ({ onSubmit, submitting }) => {
  const [gasCylinderInstall, setGasCylinderInstall] = useState("");
  const [leakageTest, setLeakageTest] = useState("");
  const [appliances, setAppliances] = useState([
    {
      description: "",
      value: "",
      ventilation: "",
      valueRemarks: "",
      ventilationRemarks: "",
    },
  ]);
  const [gasCylinderRemarks, setGasCylinderRemarks] = useState("");
  const [leakageTestRemarks, setLeakageTestRemarks] = useState("");

  const inquiryId = localStorage.getItem("InquiryId");
  const userId = localStorage.getItem("userid");

  const handleApplianceChange = (index, field, value) => {
    const newAppliances = [...appliances];
    newAppliances[index][field] = value;
    setAppliances(newAppliances);
  };

  const addAppliance = () => {
    setAppliances([
      ...appliances,
      {
        description: "",
        value: "",
        ventilation: "",
        valueRemarks: "",
        ventilationRemarks: "",
      },
    ]);
  };

  const RemoveAppliance = (indexToRemove) => {
    setAppliances((prevAppliances) =>
      prevAppliances.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = [
      {
        user: { id: userId },
        inquiry: { customerInquiryId: inquiryId },
        type: "Gas",
        title: "Gas Installation",
        subTitle: "",
        description:
          "LP Gas cylinders and associated components installed correctly.",
        status: gasCylinderInstall,
        remarks: gasCylinderRemarks,
      },
      {
        user: { id: userId },
        inquiry: { customerInquiryId: inquiryId },
        type: "Gas",
        title: "Gas Installation",
        subTitle: "",
        description: "Leakage Test",
        status: leakageTest,
        remarks: leakageTestRemarks,
      },
      ...appliances
        .filter((appliance) => appliance.description)
        .map((appliance) => [
          {
            user: { id: userId },
            inquiry: { customerInquiryId: inquiryId },
            type: "Gas",
            title: `${appliance.description}`,
            subTitle: "",
            description: "Appliance isolation value",
            status: appliance.value,
            remarks: appliance.valueRemarks
          },
          {
            user: { id: userId },
            inquiry: { customerInquiryId: inquiryId },
            type: "Gas",
            title: `${appliance.description}`,
            subTitle: "",
            description: "Adequate ventilation",
            status: appliance.ventilation,
            remarks: appliance.ventilationRemarks,
          },
        ])
        .flat(),
    ];
    onSubmit(dataToSubmit, handleClearForm());
  };

  const handleClearForm = () => {
    setLeakageTest("");
    setGasCylinderInstall("");
    setGasCylinderRemarks("");
    setLeakageTestRemarks("");
    setAppliances([
      {
        description: "",
        value: "",
        ventilation: "",
        valueRemarks: "",
        ventilationRemarks: "",
      },
    ]);
  };

  return (
    <Box
      sx={{
        width: "60%",
        height: "50%",
        margin: "auto",
        alignItems: "center",
        boxShadow: 2,
        borderRadius: 5,
        padding: 6,
        backgroundColor: "white",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", backgroundColor: "#FCCF00", padding: 1 }}
        >
          Gas Installation
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
        <RadioButtonGroup
          label="LP Gas cylinders and associated components (i.e. Regulators, pigtails) installed correctly:"
          value={gasCylinderInstall}
          onChange={setGasCylinderInstall}
          options={["Yes", "No", "N/A"]}
        />

        {gasCylinderInstall === "No" && (
          <Box marginBottom={2}>
            <TextField
              label="Remarks"
              value={gasCylinderRemarks}
              onChange={(e) => setGasCylinderRemarks(e.target.value)}
              fullWidth
              size="small"
              margin="none"
            />
          </Box>
        )}

        <RadioButtonGroup
          label="Leakage Test:"
          value={leakageTest}
          onChange={setLeakageTest}
          options={["Pass", "Fail"]}
        />

        {leakageTest === "Fail" && (
          <Box marginBottom={2}>
            <TextField
              label="Remarks"
              value={leakageTestRemarks}
              onChange={(e) => setLeakageTestRemarks(e.target.value)}
              fullWidth
              margin="none"
              size="small"
            />
          </Box>
        )}

        {appliances.map((appliance, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 3,
            }}
          >
            <Grid container alignItems="center" sx={{ position: "relative" }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#FCCF00",
                  padding: 1,
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Gas Appliance Installation
                </Typography>
                {index !== 0 && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    onClick={() => RemoveAppliance(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <TextField
              sx={{ fontSize: "30px", marginTop: 2 }}
              size="small"
              label="Appliance"
              value={appliance.description}
              onChange={(e) =>
                handleApplianceChange(index, "description", e.target.value)
              }
            />
            <RadioButtonGroup
              label="Appliance isolation value:"
              value={appliance.value}
              onChange={(value) => handleApplianceChange(index, "value", value)}
              options={["Pass", "Fail", "N/A"]}
            />
            {appliance.value === "Fail" && (
              <Box marginBottom={2}>
                <TextField
                  label="Remarks"
                  value={appliance.valueRemarks}
                  onChange={(e) =>
                    handleApplianceChange(index, "valueRemarks", e.target.value)
                  }
                  fullWidth
                  margin="none"
                  size="small"
                />
              </Box>
            )}

            <RadioButtonGroup
              label="Adequate ventilation:"
              value={appliance.ventilation}
              onChange={(value) =>
                handleApplianceChange(index, "ventilation", value)
              }
              options={["Pass", "Fail"]}
            />
            {appliance.ventilation === "Fail" && (
              <Box marginBottom={2}>
                <TextField
                  label="Remarks"
                  value={appliance.ventilationRemarks}
                  onChange={(e) =>
                    handleApplianceChange(
                      index,
                      "ventilationRemarks",
                      e.target.value
                    )
                  }
                  fullWidth
                  margin="none"
                  size="small"
                />
              </Box>
            )}
          </Box>
        ))}
        <Box sx={{ alignSelf: "flex-start" }}>
          <Button sx={{ color: "#002252" }} onClick={addAppliance}>
            + Add Appliance Installation
          </Button>
        </Box>
      </Box>
      <Button
        fullWidth
        type="submit"
        onClick={handleSubmit}
        sx={{
          marginTop: 3,
          backgroundColor: "#002252", // Initial background color
          color: "#FFFFFF", // Initial text color
          border: "2px solid transparent", // Default border
          "&:hover": {
            backgroundColor: "#FFFFFF", // Background color on hover
            color: "#002252", // Text color on hover (blue)
            border: "2px solid #002252", // Border color on hover (blue)
          },
          "&:disabled": {
            color: "#abb2b9", 
            border: "none",
            backgroundColor: "#e5e7e9",
          },
        }}
        disabled={submitting}
      >
        {submitting ? "Please wait..." : "Submit"}
      </Button>
      <Grid
        sm={12}
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
      >
        <Button
          onClick={handleClearForm}
          fullWidth
          variant="outlined"
          sx={{
            mt: 1,
            mb: 2,
            borderRadius: 2,
          }}
        >
          Clear Form
        </Button>
      </Grid>
    </Box>
  );
};

export default GasChecklist;
