import React, { useState, useEffect } from "react";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Container,
  Button,
  CssBaseline,
  Typography,
  Paper,
} from "@mui/material";

const AdminProfile = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    login: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [admin, setAdmin] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAdmin, setEditedAdmin] = useState({});

  useEffect(() => {
    // Fetch technician when component mounts
    fetchAdmin();
  }, []);

  useEffect(() => {
    setEditedAdmin(admin);
  }, [admin]);

  // extracting user is from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var userId = localStorage.getItem("userid");
    console.log("UerID:", userId);
  } else {
    console.log("Value does not exist in local storage.");
  }

  const fetchAdmin = async () => {
    try {
      // console.log("Requesting technician data ...");
      const response = await request(
        axiosInstance1,
        "GET",
        "/getAdminDetailsByUserId/" + userId
      );
      setAdmin(response.data);
      console.log("Admin Data: ", response.data);
    } catch (error) {
      console.error("Error fetching technicians:", error);
    }
  };

  // capitalize first letter
  const capitalizeFirstLetter = (str) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    setIsEditing(true);
    setEditedAdmin(admin);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedAdmin({});
  };

  const handleFieldChange = (fieldName, value) => {
    let updatedValue = "";
    if (
      fieldName === "username" 
    ) {
      updatedValue = value;
    } else {
      updatedValue = capitalizeFirstLetter(value);
    }

    setEditedAdmin((prevAdmin) => ({
      ...prevAdmin,
      [fieldName]: updatedValue,
    }));
  };

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const handleSaveClick = async (event) => {
    event.preventDefault();

    console.log("Edited Admin Data:", editedAdmin);

    setIsEditing(false);
    setAdmin(editedAdmin);

    // Basic form validation
    if (
      !editedAdmin.firstName ||
      !editedAdmin.lastName ||
      !editedAdmin.login
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance1,
        "PUT",
        "/updateAdmin/" + userId,
        editedAdmin
      );
      console.log(response);
      // setTechnician(editedTechnician);

      setSuccessMessage("Registration successful.");
      setErrorMessage("");
      console.log(successMessage);

      // Clear form after successful submission
      setEditedAdmin({});
    } catch (error) {
      // setAuthHeader(null);
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    }
  };

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Paper
            sx={{
              width: "60%",
              maxWidth: 700,
              padding: 3,
              margin: "auto",
              marginTop: 5,
              marginRight: 3,
              paddingBottom: 5,
              height: 540,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "8vh",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#002252",
                  marginBottom: 2,
                  textTransform: "capitalize",
                  fontSize: "4.2vh",
                  fontWeight: 600,
                }}
              >
                {admin.firstName + " " + admin.lastName}
              </Typography>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "125%",
              maxWidth: 1000,
              padding: 4,
              margin: "auto",
              marginTop: 5,
              paddingBottom: 2,
              marginBottom: 5,
              height: 540,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#002252",
                  fontWeight: 500,
                  textAlign: "left",
                  fontSize: "3.5vh",
                }}
              >
                Admin Profile
              </Typography>

              <form onSubmit={handleSaveClick}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Grid container spacing={3} marginTop={1}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "2.2vh",
                          color: "GrayText",
                          alignSelf: "center",
                          fontWeight: 600,
                        }}
                      >
                        First Name:
                      </InputLabel>
                      <TextField
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(
                                editedAdmin.firstName
                              ) || ""
                            : admin
                            ? capitalizeFirstLetter(admin.firstName)
                            : "Null"
                        }
                        size="small"
                        sx={{
                          fontSize: "2vh",
                          marginLeft: 1,
                          textTransform: "capitalize",
                          color: "black",
                        }}
                        onChange={(event) =>
                          handleFieldChange("firstName", event.target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "2.2vh",
                          color: "GrayText",
                          alignSelf: "center",
                          fontWeight: 600,
                        }}
                      >
                        Last Name:
                      </InputLabel>
                      <TextField
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? capitalizeFirstLetter(
                                editedAdmin.lastName
                              ) || ""
                            : admin
                            ? capitalizeFirstLetter(admin.lastName)
                            : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("lastName", event.target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <InputLabel
                        sx={{
                          fontSize: "2.2vh",
                          color: "GrayText",
                          alignSelf: "center",
                          fontWeight: 600,
                        }}
                      >
                        User Name:
                      </InputLabel>
                      <TextField
                        disabled={!isEditing}
                        value={
                          isEditing
                            ? editedAdmin.login || ""
                            : admin
                            ? admin.login
                            : "Null"
                        }
                        size="small"
                        sx={{ fontSize: "2vh", marginLeft: 1 }}
                        onChange={(event) =>
                          handleFieldChange("username", event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid sm={12} display={"flex"} justifyContent={"right"} alignSelf={"flex-end"}>
                    {isEditing ? (
                      <>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            mt: 5,
                            mb: 2,
                            mr: 2,
                            borderRadius: 5,
                            fontSize: "2.1vh",
                            background: "#002252",
                            boxShadow: "none",
                            width: "8em",
                            "&:hover": {
                              backgroundColor: "#002252",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleSaveClick}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 5,
                            mb: 2,
                            borderRadius: 5,
                            backgroundColor: "transparent",
                            color: "black",
                            width: "8em",
                            fontSize: "2.1vh",
                            border: "2px solid black",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          mt: 5,
                          mb: 2,
                          borderRadius: 5,
                          fontSize: "2.1vh",
                          background: "#002252",
                          boxShadow: "none",
                          width: "8em",
                          "&:hover": {
                            backgroundColor: "#002252",
                            boxShadow: "none",
                          },
                        }}
                        onClick={handleEditClick}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default AdminProfile;
