import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../image/logo.PNG";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    paddingHorizontal: 35,
    paddingVertical: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 150,
    height: 50,
  },
  title: {
    fontSize: 24,
    fontFamily: "Helvetica", // Using Helvetica which is a default font
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#003366",
    color: "white",
    padding: 20,
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    marginHorizontal: 10,
  },
  footerIcon: {
    marginHorizontal: 5,
    fontSize: 10,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  verticalLine: {
    height: "100%",
    width: 1,
    backgroundColor: "white",
    marginHorizontal: 10,
  },
  icon: {
    fontFamily: "FontAwesome",
    fontSize: 12,
    color: "white",
    marginHorizontal: 5,
  },
  tableHeader: {
    display: "table",
    width: "auto",
    backgroundColor: "#003366",
    fontSize: 11,
    fontWeight: "extrabold",
    color: "white",
    padding: 8,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 1,
    borderColor: "#bfbfbf",
    marginBottom: 7,
  },
  DetailsTable: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: 10,
  },

  tableCol: {
    fontSize: 10,
    // borderWidth: 1,
    borderColor: "#bfbfbf",
    padding: 5,
  },
  row1: {
    flexDirection: "row",
    color: "#424949",
    // border: '2px solid red'
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  row2: {
    flexDirection: "row",
    color: "#424949",
    // border: '2px solid red'
  },
  col1: {
    borderRight: 1,
    borderColor: "#bfbfbf",
    width: "25%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col2: {
    width: "29%",
    color: "black",
    // border: '2px solid red'
    padding: 5,
  },
  col3: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "#bfbfbf",
    width: "23%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col4: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "#bfbfbf",
    width: "23%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col5: {
    borderRight: 1,
    borderColor: "#bfbfbf",
    width: "23.6%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col6: {
    borderColor: "#bfbfbf",
    width: "76.4%",
    color: "black",
    // border: '2px solid red'
    padding: 5,
  },
  content1: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    // border: '2px solid red'
  },
  row1Result: {
    padding: 5,
    color: "black",
    fontSize: 10,
    // border: '2px solid red'
  },
  content2: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    // border: '2px solid red'
  },
  row2Result: {
    padding: 5,
    color: "black",
    fontSize: 10,
    // border: '2px solid red'
  },
  content3: {
    padding: 5,
    color: "#424949",
    width: "12%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row3Result: {
    padding: 5,
    color: "black",
    width: "100%",
    fontSize: 10,
    // border: '2px solid red'
  },
  tableRow2: {
    flexDirection: "row",
    borderTop: 1,
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  content5: {
    padding: 5,
    color: "#424949",
    width: "15%",
    fontSize: 10,
    // border: '2px solid red'
    borderRight: 1,
    borderColor: "#bfbfbf",
  },
  row5Result: {
    padding: 5,
    color: "#424949",
    width: "85%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  tableRow1: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  content2_1: {
    padding: 5,
    color: "#424949",
    width: "70%",
    fontSize: 10,
    // border: '2px solid red'
  },
  rowResult2_1: {
    padding: 5,
    color: "black",
    width: "10%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row4: {
    flexDirection: "column",
    backgroundColor: "#fffb00",
    opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
    borderRadius: 5,
    padding: 4,
  },
  content4: {
    paddingTop: 5,
    paddingLeft: 5,
    color: "#8f8408",
    width: "50%",
    fontSize: 12,
    // border: '2px solid red',
    opacity: "12",
  },
  row4Result: {
    padding: 5,
    paddingBottom: 8,
    color: "#424949",
    width: "100%",
    fontSize: 10,
    // border: '2px solid red',
    opacity: "1",
  },
  signatureSection: {
    flexDirection: "column",
    backgroundColor: "#d7dbdd",
    // opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
  },
  signature: {
    width: 260,
    height: 100,
    objectFit: "contain",
    alignSelf: "flex-end",
    marginTop: 5,
    marginBottom: 6,
  },
  signatureContainer: {
    fontSize: 10,
    color: "#626567",
    alignSelf: "flex-end",
    alignItems: "center",
    // border: '2px solid red',
    marginBottom: 18,
    marginLeft: 6,
    marginRight: 40,
  },
  noSignature: {
    marginBottom: 15,
    marginTop: 15,
    marginRight: 5,
    color: "black",
  },
});

const GasReport = ({ data, result, signature }) => {
  const firstPageApplianceSections = 2;
  const subsequentPageApplianceSections = 4;

  // Slice appliances for first page and remaining pages
  const appliances = result?.titles.slice(1) || [];
  const firstPageAppliances = appliances.slice(0, firstPageApplianceSections);
  const remainingAppliances = appliances.slice(firstPageApplianceSections);

  let isDeclarationTableOnNewPage = false;

  // Function to split remaining appliances into pages of 4 each
  const chunkedAppliances = [];
  for (
    let i = 0;
    i < remainingAppliances.length;
    i += subsequentPageApplianceSections
  ) {
    chunkedAppliances.push(
      remainingAppliances.slice(i, i + subsequentPageApplianceSections)
    );
  }

  if (
    remainingAppliances.length % subsequentPageApplianceSections === 0 &&
    firstPageAppliances.length > 0
  ) {
    isDeclarationTableOnNewPage = true;
  }

  return (
    <Document title="Gas Safety Check Report">
      <Page size={[656, 790]} style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} alt="Company Logo" />
          <Text style={styles.title}>Gas Safety Check</Text>
        </View>

        <>
          {/* Details Section */}
          <View style={styles.DetailsTable}>
            <View style={styles.row1}>
              <View style={styles.col1}>
                <Text style={styles.text1}>Date:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>{data.taskDate}</Text>
              </View>

              <View style={styles.col3}>
                <Text style={styles.text1}>Order Id:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>{data.customerInquiryId}</Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                <Text style={styles.text1}>Licensed/Registered person:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>
                  {data.technicianId.technicianId}
                </Text>
              </View>

              <View style={styles.col3}>
                <Text style={styles.text1}></Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}></Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                <Text style={styles.text1}>Check completed by:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>
                  {data.technicianId.firstName +
                    " " +
                    data.technicianId.lastName}
                </Text>
              </View>

              <View style={styles.col3}>
                <Text style={styles.text1}></Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}></Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                <Text style={styles.text1}>Client name:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>
                  {data.firstName + " " + data.lastName}
                </Text>
              </View>

              <View style={styles.col3}>
                <Text style={styles.text1}>Contact no:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>{data.contactNumber}</Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col5}>
                <Text style={styles.text1}>Street address:</Text>
              </View>
              <View style={styles.col6}>
                <Text style={styles.text2}>{data.address}</Text>
              </View>
            </View>

            <View style={styles.row2}>
              <View style={styles.col1}>
                <Text style={styles.text1}>Suburb:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}></Text>
              </View>

              <View style={styles.col3}>
                <Text style={styles.text1}>Postcode:</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.text2}>{data.postalCode}</Text>
              </View>
            </View>
          </View>
        </>

        <>
          {/* Gas Installation Section */}
          <Text style={styles.tableHeader}>Gas Installation</Text>
          <View style={styles.table}>
            {/* Row 1 */}
            <View>
              <View style={styles.tableRow1}>
                <View style={styles.content2_1}>
                  <Text>
                    LP Gas cylinders and associated components (i.e. Regulators,
                    pigtails) installed correctly:
                  </Text>
                </View>
                <View style={styles.rowResult2_1}>
                  <Text>
                    {result.titles[0].subTitles[0].descriptions[0].status}
                  </Text>
                </View>
                {/* <View style={styles.rowResult2_1}>
                <Text>NO</Text>
              </View>
              <View style={styles.rowResult2_1}>
                <Text>N/A</Text>
              </View> */}
              </View>

              <View style={styles.tableRow1}>
                <View style={styles.content3}>
                  <Text>Comments:</Text>
                </View>
                <View style={styles.row3Result}>
                  <Text>
                    {result.titles[0].subTitles[0].descriptions[0].remarks}
                  </Text>
                </View>
              </View>

              {/* Row 2: Gas installation leakage test */}
              <View style={styles.tableRow1}>
                <View style={styles.content2}>
                  <Text>Gas installation leakage test:</Text>
                </View>
                <View style={styles.row2Result}>
                  <Text>
                    {result.titles[0].subTitles[0].descriptions[1].status}
                  </Text>
                </View>
                {/* <View style={styles.row2Result}>
                <Text>Fail</Text>
              </View> */}
              </View>

              {/* Comments */}
              <View style={styles.tableRow1}>
                <View style={styles.content3}>
                  <Text>Comments:</Text>
                </View>
                <View style={styles.row3Result}>
                  <Text>
                    {result.titles[0].subTitles[0].descriptions[1].remarks}
                  </Text>
                </View>
              </View>
              {/* Appliance Row */}
              {/* <View style={styles.tableRow2}>
              <View style={styles.content5}>
                <Text>Appliance 1:</Text>
              </View>
              <View style={styles.row5Result}>
                <Text></Text>
              </View>
            </View> */}
            </View>
          </View>
        </>

        {/* Gas Appliance Section */}

        {firstPageAppliances.length > 0 && (
          <View style={styles.row4}>
            <Text style={styles.content4}>Gas appliance</Text>
            <Text style={styles.row4Result}>
              Regulation 12(4) of the Gas Safety (Gas Installation) Regulations
              2018 prescribes AS4575 as the standard for Type A appliance
              servicing work on an appliance that is part of a standard gas
              installation.
            </Text>
          </View>
        )}

        {firstPageAppliances.map((appliance, index) => (
          <>
            {/* Gas Appliance Installation Section */}
            <Text style={styles.tableHeader}>Gas Appliance Installation</Text>
            <View style={styles.table}>
              <View style={styles.row}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "48%",
                    borderRight: 1,
                    borderColor: "#bfbfbf",
                  }}
                >
                  <View style={styles.content1}>
                    <Text>Appliance isolation valve: </Text>
                  </View>
                  <View style={styles.row1Result}>
                    <Text>{appliance.subTitles[0].descriptions[0].status}</Text>
                  </View>
                  {/* <View style={styles.row1Result}>
                <Text>No</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>N/A</Text>
              </View> */}
                </View>

                <View style={{ flexDirection: "row", width: "52%" }}>
                  <View style={styles.content1}>
                    <Text>Electrically safe: </Text>
                  </View>
                  {/* <View style={styles.row1Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>No</Text>
              </View> */}
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.content3}>
                  <Text>Comments:</Text>
                </View>
                <View style={styles.row3Result}>
                  <Text>{appliance.subTitles[0].descriptions[0].remarks}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "48%",
                    borderRight: 1,
                    borderColor: "#bfbfbf",
                  }}
                >
                  <View style={styles.content2}>
                    <Text>Adequate ventilation:</Text>
                  </View>
                  <View style={styles.row2Result}>
                    <Text>{appliance.subTitles[0].descriptions[1].status}</Text>
                  </View>
                  {/* <View style={styles.row2Result}>
                <Text>No</Text>
              </View> */}
                </View>

                <View style={{ flexDirection: "row", width: "52%" }}>
                  <View style={styles.content2}>
                    <Text>Adequate clearances to combustible surfaces:</Text>
                  </View>
                  {/* <View style={styles.row2Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row2Result}>
                <Text>No</Text>
              </View> */}
                </View>
              </View>

              {/* <View style={styles.row}>
                <View style={{ flexDirection: "row", width: "52%" }}>
                  <View style={{ fontSize: 10, padding: 5, color: "#424949" }}>
                    <Text>Completed service in accordance with AS 4575 :</Text>
                  </View>
                  <View style={styles.row1Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>No</Text>
              </View>
                </View>

                <View>
                  <Text style={{ fontSize: 10, padding: 5, color: "#424949" }}>
                    (VBA online system report)
                  </Text>
                </View>
              </View> */}

              <View style={styles.row}>
                <View style={styles.content3}>
                  <Text>Comments:</Text>
                </View>
                <View style={styles.row3Result}>
                  <Text>{appliance.subTitles[0].descriptions[1].remarks}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.content5}>
                  <Text>Appliance {index + 1}:</Text>
                </View>
                <View style={styles.row5Result}>
                  <Text>{appliance.title}</Text>
                </View>
              </View>
            </View>
          </>
        ))}

        {firstPageAppliances.length < 1 && (
          <>
            <View style={styles.signatureSection}>
              <Text style={{ padding: 5, fontSize: 12, color: "#2e4053" }}>
                Signature of Gas Fitter
              </Text>
              <View style={styles.signatureContainer}>
                {signature ? (
                  <Image
                    style={styles.signature}
                    src={signature}
                    alt="Signature"
                  />
                ) : (
                  <Text style={styles.noSignature}>No signature available</Text>
                )}
                <Text>Signed by gasfitter</Text>
              </View>
            </View>
          </>
        )}

        {/* Footer */}
        <Footer />
      </Page>

      {/* Subsequent Pages */}
      {chunkedAppliances.map((applianceChunk, pageIndex) => (
        <Page key={pageIndex} size={[656, 790]} style={styles.page}>
          {/* Header */}
          <View style={styles.header}>
            <Image style={styles.logo} src={logo} alt="Company Logo" />
          </View>

          {/* Gas Appliance Installation Sections (up to 4 per page) */}
          {applianceChunk.map((appliance, index) => (
            <View key={index}>
              <Text style={styles.tableHeader}>Gas Appliance Installation</Text>
              <View style={styles.table}>
                <View style={styles.row}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "48%",
                      borderRight: 1,
                      borderColor: "#bfbfbf",
                    }}
                  >
                    <View style={styles.content1}>
                      <Text>Appliance isolation valve: </Text>
                    </View>
                    <View style={styles.row1Result}>
                      <Text>
                        {appliance.subTitles[0].descriptions[0].status}
                      </Text>
                    </View>
                    {/* <View style={styles.row1Result}>
                <Text>No</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>N/A</Text>
              </View> */}
                  </View>

                  <View style={{ flexDirection: "row", width: "52%" }}>
                    <View style={styles.content1}>
                      <Text>Electrically safe: </Text>
                    </View>
                    {/* <View style={styles.row1Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>No</Text>
              </View> */}
                  </View>
                </View>

                <View style={styles.row}>
                <View style={styles.content3}>
                  <Text>Comments:</Text>
                </View>
                <View style={styles.row3Result}>
                  <Text>{appliance.subTitles[0].descriptions[0].remarks}</Text>
                </View>
              </View>

                <View style={styles.row}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "48%",
                      borderRight: 1,
                      borderColor: "#bfbfbf",
                    }}
                  >
                    <View style={styles.content2}>
                      <Text>Adequate ventilation:</Text>
                    </View>
                    <View style={styles.row2Result}>
                      <Text>
                        {appliance.subTitles[0].descriptions[1].status}
                      </Text>
                    </View>
                    {/* <View style={styles.row2Result}>
                <Text>No</Text>
              </View> */}
                  </View>

                  <View style={{ flexDirection: "row", width: "52%" }}>
                    <View style={styles.content2}>
                      <Text>Adequate clearances to combustible surfaces:</Text>
                    </View>
                    {/* <View style={styles.row2Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row2Result}>
                <Text>No</Text>
              </View> */}
                  </View>
                </View>

                {/* <View style={styles.row}>
                  <View style={{ flexDirection: "row", width: "52%" }}>
                    <View
                      style={{ fontSize: 10, padding: 5, color: "#424949" }}
                    >
                      <Text>
                        Completed service in accordance with AS 4575 :
                      </Text>
                    </View>
                    <View style={styles.row1Result}>
                <Text style={{}}>Yes</Text>
              </View>
              <View style={styles.row1Result}>
                <Text>No</Text>
              </View>
                  </View>

                  <View>
                    <Text
                      style={{ fontSize: 10, padding: 5, color: "#424949" }}
                    >
                      (VBA online system report)
                    </Text>
                  </View>
                </View> */}

                <View style={styles.row}>
                  <View style={styles.content3}>
                    <Text>Comments:</Text>
                  </View>
                  <View style={styles.row3Result}>
                    <Text>
                      {appliance.subTitles[0].descriptions[1].remarks}
                    </Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.content5}>
                    <Text>
                      Appliance{" "}
                      {index +
                        1 +
                        firstPageApplianceSections +
                        pageIndex * subsequentPageApplianceSections}
                      :
                    </Text>
                  </View>
                  <View style={styles.row5Result}>
                    <Text>{appliance.title}</Text>
                  </View>
                </View>
              </View>
            </View>
          ))}

          {pageIndex === chunkedAppliances.length - 1 &&
            !isDeclarationTableOnNewPage && (
              <>
                <View style={styles.signatureSection}>
                  <Text style={{ padding: 5, fontSize: 12, color: "#2e4053" }}>
                    Signature of Gas Fitter
                  </Text>
                  <View style={styles.signatureContainer}>
                    {signature ? (
                      <Image
                        style={styles.signature}
                        src={signature}
                        alt="Signature"
                      />
                    ) : (
                      <Text style={styles.noSignature}>
                        No signature available
                      </Text>
                    )}
                    <Text>Signed by gasfitter</Text>
                  </View>
                </View>
              </>
            )}

          {/* Footer */}
          <Footer />
        </Page>
      ))}

      {isDeclarationTableOnNewPage ? (
        <Page size={[656, 790]} style={styles.page}>
          {/* Render the header only if the declaration moves to a new page */}
          <View style={styles.header}>
            <Image style={styles.logo} src={logo} alt="Company Logo" />
          </View>

          {/* Declaration Table */}
          <>
            <View style={styles.signatureSection}>
              <Text style={{ padding: 5, fontSize: 12, color: "#2e4053" }}>
                Signature of Gas Fitter
              </Text>
              <View style={styles.signatureContainer}>
                {signature ? (
                  <Image
                    style={styles.signature}
                    src={signature}
                    alt="Signature"
                  />
                ) : (
                  <Text style={styles.noSignature}>No signature available</Text>
                )}
                <Text>Signed by gasfitter</Text>
              </View>
            </View>
          </>

          {/* Footer */}
          <Footer />
        </Page>
      ) : (
        <></>
      )}
    </Document>
  );
};

export default GasReport;
