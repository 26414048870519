import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from 'axios';
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
  axiosInstance3,
} from "../../config/axiosConfig";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Select,
  MenuItem,
  Typography,
  Paper, // Import Paper component
} from "@mui/material";
import StateSelect from "../../components/stateSelect/StateSelect";
import NewNavBar from "../../components/appBar/NewNavBar";

const TechnicianReg = () => {
  const [formData, setFormData] = useState({
    typeOfExpertise: "Select",
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    confirmPassword: "",
    emailAddress: "",
    licenseNumber: "",
    city: "",
    state: "",
    postalCode: "",
    contactNumber: "",
    address: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactError, setContactError] = useState("");
  const [focusedField, setFocusedField] = useState(null);

  const [licenseCopyFile, setLicenseCopyFile] = useState(null);
  const [licenseCopyThumbnail, setLicenseCopyThumbnail] = useState(null);
  
  const [technicianPhoto, setTechnicianPhoto] = useState(null);
  const [technicianPhotoThumbnail, setTechnicianPhotoThumbnail] = useState(null);

  const [technicianSignature, setTechnicianSignature] = useState(null);
  const [technicianSignatureThumbnail, setTechnicianSignatureThumbnail] = useState(null);


  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const handleFileChange = (event, setFile, setThumbnail) => {
    const file = event.target.files[0];
    if (file) {
        setFile(file);
        const thumbnailUrl = URL.createObjectURL(file);
        setThumbnail(thumbnailUrl);
    }
};

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "password") {
      const passwordValidationError = validatePassword(value);
      setPasswordError(passwordValidationError);
    }

    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setConfirmPasswordError("Passwords do not match.");
      } else {
        setConfirmPasswordError("");
      }
    }

    setFocusedField(name); // Update the focused field
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const errorMessages = [];
    if (password.length < minLength) {
      errorMessages.push(
        `Password must be at least ${minLength} characters long.`
      );
    }
    if (!hasUpperCase) {
      errorMessages.push(
        "Password must contain at least one uppercase letter."
      );
    }
    if (!hasLowerCase) {
      errorMessages.push(
        "Password must contain at least one lowercase letter."
      );
    }
    if (!hasNumber) {
      errorMessages.push("Password must contain at least one digit.");
    }
    if (!hasSpecialChar) {
      errorMessages.push(
        "Password must contain at least one special character."
      );
    }

    return errorMessages.join("\n");
  };

  const handleFocusChange = async () => {
    if (focusedField === "username") {
      try {
        const authToken = getAuthToken();
        if (!authToken) {
          console.error("Authentication token not available");
          return;
        }

        const response = await request(
          axiosInstance1,
          "GET",
          `/checkTechnician/${formData.username}`
        );

        if (response.data.exists) {
          setUsernameError("Username already exists");
        } else {
          setUsernameError("");
        }
      } catch (error) {
        console.error("Error checking username:", error);
        setUsernameError("Error checking username");
      }
    }

    if (focusedField === "emailAddress") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.emailAddress)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }

    if (focusedField === "contactNumber") {
      const contactNumberRegex = /^[0-9]{10}$/;
      if (!contactNumberRegex.test(formData.contactNumber)) {
        setContactError("Please enter a valid contact number.");
      } else {
        setContactError("");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic form validation
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.username ||
      !formData.password ||
      !formData.emailAddress ||
      !formData.typeOfExpertise ||
      formData.typeOfExpertise === "Select" ||
      !formData.licenseNumber ||
      !formData.city ||
      !formData.state ||
      !formData.postalCode ||
      !formData.contactNumber ||
      !formData.address ||
      !formData.confirmPassword
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (passwordError || confirmPasswordError) {
      setErrorMessage(passwordError || confirmPasswordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

     // Check if required files are selected
  if (!licenseCopyFile) {
    setErrorMessage("Please upload the license copy.");
    return;
  }

  if (!technicianPhoto) {
    setErrorMessage("Please upload the technician photo.");
    return;
  }

  if (!technicianSignature) {
    setErrorMessage("Please upload the technician signature.");
    return;
  }

    // Destructure formData to exclude confirmPassword
    const {
      confirmPassword, // Remove this property
      ...dataToSubmit
    } = formData;

    const formDataWithFile = new FormData();
    formDataWithFile.append("technician", JSON.stringify(dataToSubmit));
    if (licenseCopyFile) {
      formDataWithFile.append("licenseCopyFile", licenseCopyFile);
    }
    if (technicianPhoto) {
      formDataWithFile.append("technicianPhoto", technicianPhoto);
    }
    if (technicianSignature) {
      formDataWithFile.append("technicianSignature", technicianSignature);
    }

    setIsSubmitting(true);
    try {
      setAuthHeader(getAuthToken());

      const response = await request(
          axiosInstance3,
          "POST",
          "/saveTechnician",
          formDataWithFile
      );

      Swal.fire({
          icon: 'success',
          title: 'Registration Successful',
          text: 'You have been successfully registered.',
          confirmButtonText: 'OK',
      }).then(() => {
          navigate('/');
      });

      // Clear form after successful submission
      setFormData({
          typeOfExpertise: "",
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          emailAddress: "",
          licenseNumber: "",
          city: "",
          state: "",
          postalCode: "",
          contactNumber: "",
          address: "",
      });
      setLicenseCopyFile(null);
      document.getElementById("licenseCopyFile").value = "";
      setTechnicianPhoto(null);
      document.getElementById("technicianPhoto").value = "";
      setTechnicianSignature(null);
      document.getElementById("technicianSignature").value = "";
  } catch (error) {
      setAuthHeader(null);
      setSuccessMessage("");
    //  alert(error.response.data);
      // Check if the error message is "Login already exists"
      if (error.response && error.response.data === "Login already exists") {
          setErrorMessage("Username already exists. Please choose a different username.");
      } else {
          setErrorMessage("An error occurred. Please try again.");
      }
      console.log(error);
  } finally {
      setIsSubmitting(false);
  }
  };

  const handleClearForm = () => {
    setFormData({
      // Clear form data
      typeOfExpertise: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      emailAddress: "",
      licenseNumber: "",
      city: "",
      state: "",
      postalCode: "",
      contactNumber: "",
      address: "",
    });
    setLicenseCopyFile(null);
    document.getElementById("licenseCopyFile").value = "";
    setTechnicianPhoto(null);
    document.getElementById("technicianPhoto").value = "";
    setTechnicianSignature(null);
    document.getElementById("technicianSignature").value = "";
    setSuccessMessage("");
    setErrorMessage("");
  };





  //address lookup

  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [addressValidationMessage, setAddressValidationMessage] = useState('');
  const [isAddressValid, setIsAddressValid] = useState(true);

  const handleChangeadd = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'address') {
      checkBusinessAddress(value);
    }
  };

  const checkBusinessAddress = (query) => {
    const key = 'LX3BYJ7TMW6KRDFHCPE8'; //replace with actual API key

    if (!query || query.trim() === '') {
      setIsAddressValid(false);
      setAddressValidationMessage('Please enter a business address.');
      setSuggestedAddresses([]);
      return;
    }

    const apiUrl = `https://api.addressfinder.io/api/au/address/autocomplete/?key=${key}&q=${encodeURIComponent(
      query
    )}&format=json&source=gnaf%2Cpaf`;

    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data && response.data.completions) {
          setSuggestedAddresses(response.data.completions);
          setAddressValidationMessage('');
        }
      })
      .catch((error) => {
        setIsAddressValid(false);
        setAddressValidationMessage('Error occurred. Try again.');
        console.error('Error fetching address suggestions:', error);
      });
  };

  const selectAddress = (address) => {
    setFormData({
      ...formData,
      address: address.full_address,
    });
    setSuggestedAddresses([]);
  };

  // Define the styles in JS objects
  const dropdownStyle = {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    maxHeight: '150px',
    overflowY: 'auto',
    zIndex: 1000,
  };

  const listItemStyle = {
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: '#f1f1f1',
  };

  const listItemHoverStyle = {
    backgroundColor: '#f0f0f0',
  };


  return (
    <>
      <NewNavBar activePage="Technician Registration" />
      <Container component="main">
        <Paper
          sx={{
            width: "90%",
            maxWidth: 800,
            padding: 3,
            margin: "auto",
            marginTop: 5,
          }}
        >
          <Box
              sx={{
                backgroundColor: "#002252",
                padding: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "8px", // Adds rounded corners
              }}
            >
            <div
              className="text-lg font-bold text-center text-white lg:text-2xl"
            >
              Technician Registration
            </div>
          </Box>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
            className="pt-10"
          >
            <form onSubmit={handleSubmit} onBlur={handleFocusChange}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> First Name:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="firstName"
                    name="firstName"
                    autoComplete="given-name"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Last Name:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="lastName"
                    name="lastName"
                    autoComplete="family-name"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: '2vh' }}>
                    <span style={{ color: 'red' }}>*</span> Address:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChangeadd}
                    sx={{ height: 100 }} 
                    multiline
                    rows={4}
                  />
                  {!isAddressValid && (
                    
                    <span style={{ color: 'red', marginTop: '10px', display: 'block' }}>
                        {addressValidationMessage}
                    </span>
                  )}
                  {suggestedAddresses.length > 0 && (
                    <div style={dropdownStyle}>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {suggestedAddresses.map((address, index) => (
                          <li
                            key={index}
                            onClick={() => selectAddress(address)}
                            style={listItemStyle}
                            onMouseOver={(e) => (e.target.style.backgroundColor = listItemHoverStyle.backgroundColor)}
                            onMouseOut={(e) => (e.target.style.backgroundColor = listItemStyle.backgroundColor)}
                          >
                            {address.full_address}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Grid>
                {/* Right column */}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> City:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StateSelect
                    formData={formData}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Postal Code:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Email Address:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="emailAddress"
                    name="emailAddress"
                    autoComplete="email"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Contact Number:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    error={!!contactError}
                    helperText={contactError}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> User Name:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    autoComplete="username"
                    value={formData.username}
                    onChange={handleChange}
                    error={!!usernameError}
                    helperText={usernameError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Password:
                  </InputLabel>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                    error={!!passwordError}
                    helperText={passwordError.split("\n").map((text, index) => (
                      <span key={index}>
                        {text}
                        <br />
                      </span>
                    ))}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Confirm Password:
                  </InputLabel>
                  <TextField
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                  />
                </Grid>

          
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Type of Expertise:
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    labelId="typeOfExpertise"
                    id="typeOfExpertise"
                    value={formData.typeOfExpertise}
                    onChange={handleChange}
                    name="typeOfExpertise"
                    size="small"
                    fullWidth
                  >
                    <MenuItem value="Select">
                      <em>--Select--</em>
                    </MenuItem>
                    <MenuItem value="Gas">Gas</MenuItem>
                    <MenuItem value="Electrical">Electrical</MenuItem>
                    <MenuItem value="Smoke">Smoke</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> License Number:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="licenseNumber"
                    name="licenseNumber"
                    autoComplete="off"
                    value={formData.licenseNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> License Copy:
                  </InputLabel>
                  <Typography variant="body2" sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}>
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="licenseCopyFile"
                    name="licenseCopyFile"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(event, setLicenseCopyFile,setLicenseCopyThumbnail)
                    }
                  />
                     {licenseCopyThumbnail && (
                    <img
                      src={licenseCopyThumbnail}
                      alt="License Copy"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Photo of the Technician:
                  </InputLabel>
                  <Typography variant="body2" sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}>
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="technicianPhoto"
                    name="technicianPhoto"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(event, setTechnicianPhoto,setTechnicianPhotoThumbnail)
                    }
                  />
                  {technicianPhotoThumbnail && (
                    <img
                      src={technicianPhotoThumbnail}
                      alt="Technician Profile"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Image of the Signature:
                  </InputLabel>
                  <Typography variant="body2" sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}>
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="technicianSignature"
                    name="technicianSignature"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(event, setTechnicianSignature,setTechnicianSignatureThumbnail)
                    }
                  />
                      {technicianSignatureThumbnail && (
                    <img
                      src={technicianSignatureThumbnail}
                      alt="Technician Signature"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              {/* Success message */}
              {successMessage && (
                <Typography sx={{ color: "green", textAlign: "center" }}>
                  {successMessage}
                </Typography>
              )}
              {/* Error message */}
              {errorMessage && (
                <Typography sx={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}
                            <Button
                className="bg-[#002252]" 
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 2,
                  backgroundColor: "#002252", // Ensure background color is blue in sx
                  color: "#FFFFFF", // Set text color to white
                  "&:hover": {
                    backgroundColor: "#001d40", // Optional: Darker blue on hover
                  },
                }}
                disabled={isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? "Saving, please wait..." : "Submit"} {/* Change button text */}
              </Button>
              <Button
                onClick={handleClearForm}
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                  mb: 2,
                  borderRadius: 2,
                  borderColor: "#FCCF00", // Set outline color to yellow
                  color: "#FCCF00", // Set text color to yellow to match the outline
                  "&:hover": {
                    borderColor: "#e6ba00", // Optional: Slightly darker yellow on hover
                    color: "#e6ba00", // Optional: Change text color on hover to match outline
                  },
                }}
              >
                Clear Form
              </Button>

            </form>
          </div>
        </Paper>
      </Container>
    </>
  );
};
export default TechnicianReg;
