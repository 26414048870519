import React, { useState, useEffect } from "react";
import {
  request,
  axiosInstance2,
  axiosInstance1,
} from "../../config/axiosConfig"; // Assuming you have a request function for API calls
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Stripe from "react-stripe-checkout";
import CustomerViewDetailsPopup from "../../components/dashboard/CustomerViewDetails";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  Collapse,
  Table,
  TableBody,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function CustomerDashboardPage() {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isCustomerActive, setIsCustomerActive] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedGroup, setExpandedGroup] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchCustomers();
    fetchCustomerData();
  }, []);

  // extracting user id from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var customerId = localStorage.getItem("userid");
    console.log("UserID:", customerId);
  } else {
    console.log("Value does not exist in local storage");
  }

  const fetchCustomerData = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getCustomerDetailsByUserId/${customerId}`
      );

      const fetchedData = response.data;

      if (fetchedData.status === "Active") {
        setIsCustomerActive(true);
      } else {
        setIsCustomerActive(false);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const [totalInquiries, setTotalInquiries] = useState(0);
  const [pendingInquiries, setPendingInquiries] = useState(0);
  const [ongoingInquiries, setOngoingInquiries] = useState(0);
  const [completeInquiries, setCompleteInquiries] = useState(0);

  const userId = localStorage.getItem("userid");
  const user = localStorage.getItem("firstName");
  const welcomeUser = user.charAt(0).toUpperCase() + user.slice(1);

  const MySwal = withReactContent(Swal);

  //getDashboardData  ----> card data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const totInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalCustomerInquiries",
          null,
          { userid: userId }
        );
        // console.log("Total Inquaries :", totInqResponse.data);
        setTotalInquiries(totInqResponse.data);

        const penbInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalPendingCustomerInquiries",
          null,
          { userid: userId }
        );
        // console.log("Pending Inquaries :", penbInqResponse.data);
        setPendingInquiries(penbInqResponse.data);

        const ongoInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalOngoingCustomerInquiries",
          null,
          { userid: userId }
        );
        // console.log("Ongoing Inquaries :", ongoInqResponse.data);
        setOngoingInquiries(ongoInqResponse.data);

        const comInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalCompleteCustomerInquiries",
          null,
          { userid: userId }
        );
        // console.log("Completed Inquaries :", comInqResponse.data);
        setCompleteInquiries(comInqResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]); // re-run effect when userId changes

  const fetchCustomers = async () => {
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/getCustomerInquiriesByUserid",
        null,
        { userid: userId }
      );
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  // console.log("customers", customers);

  const openDetailsPopup = (techId, status, customer) => {
    setShowDetailsPopup(true);
    setSelectedTechnician(techId);
    setCurrentStatus(status);
    setSelectedStatus(status);
    setSelectedCustomer(customer);
    console.log("techID", status);
  };

  const closeDetailsPopup = () => {
    setSelectedTechnician(null);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
  };

  const toggleDropdown = (groupId) => {
    setExpandedGroup((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };

  const groupedInquiries = customers.reduce((groups, customer) => {
    const groupId = customer.groupId.groupId;
    if (!groups[groupId]) {
      groups[groupId] = [];
    }
    groups[groupId].push(customer);
    return groups;
  }, {});

  const isAllAccepted = (group) =>
    group.every((inquiry) => inquiry.overallStatus === "Accepted");

  const calculateTotalPrice = (group) =>
    group.reduce((total, inquiry) => total + inquiry.servicePrice, 0);

  const handleGroupPayment = async (stripetoken, group) => {
    setIsSubmitting(true);

    const paymentData = {
      token: stripetoken.id,
      inquiries: group.map((inquiry) => ({
        inquiryId: inquiry.customerInquiryId,
        price: inquiry.servicePrice,
      })),
    };

    try {
      const response = await request(
        axiosInstance2,
        "POST",
        "api/payment/charge",
        paymentData
      );
      console.log("Payment response: ", response);

      fetchCustomers(); // Refresh data
      MySwal.fire({
        icon: "success",
        title: "Success",
        text: "Your payment has been processed successfully.",
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });

      console.log("Error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div div className="flex flex-col bg-gray-300">
      <div className="flex flex-col px-4 pt-10 lg:px-16 ">
        <div className="text-2xl font-bold text-gray-800 sm:text-3xl lg:text-4xl">
          Welcome back {welcomeUser}
        </div>
        <div className="text-sm sm:text-base">
          Check your last activity today
        </div>
      </div>

      {isCustomerActive ? (
        <>
          <div className="flex flex-col items-center w-full h-auto gap-4 mt-10 lg:mt-1 lg:px-16">
            {/* Mobile View (1 column of 4 boxes) */}
            <div className="flex flex-col w-full gap-4 px-4 lg:hidden">
              {/* Box 1 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Total Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {totalInquiries}
                  </div>
                </div>
              </div>
              {/* Box 2 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Pending Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {pendingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 3 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Ongoing Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {ongoingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 4 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Completed Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {completeInquiries}
                  </div>
                </div>
              </div>
            </div>

            {/* Large Screen View (1 row of 4 boxes) */}
            <div className="hidden w-full gap-4 px-1 lg:flex">
              {/* Box 1 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Total Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {totalInquiries}
                  </div>
                </div>
              </div>
              {/* Box 2 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Pending Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {pendingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 3 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Ongoing Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {ongoingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 4 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Completed Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {completeInquiries}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-start w-full px-4 mt-2 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
            Ongoing Inquiries
          </div>

          <div className="overflow-y-auto max-h-[380px] mb-4">
            {Object.keys(groupedInquiries).map((groupId) => {
              const group = groupedInquiries[groupId];
              const totalPrice = calculateTotalPrice(group);
              const allAccepted = isAllAccepted(group);
              return (
                <div
                  key={groupId}
                  className="flex flex-col items-center w-full px-2 mb-4 lg:px-16"
                >
                  {/* Group Card */}
                  <div className="flex flex-col justify-center w-full p-4 bg-white rounded-lg">
                    {/* Common Details */}
                    <div className="flex flex-col justify-between w-full lg:flex-row">
                      <div className="text-xl font-bold">
                        Group ID: {groupId}
                      </div>
                      <div className="flex flex-row text-xl font-bold">
                        <div className="pr-1">
                          {groupedInquiries[groupId][0].firstName}{" "}
                          {groupedInquiries[groupId][0].lastName}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between mt-2 lg:flex-row">
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                          <div className="text-sm font-bold lg:text-base">
                            Order Date:
                          </div>
                          <div className="pl-2 text-sm lg:text-base font-base">
                            {groupedInquiries[groupId][0].inquiryDate}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-sm font-bold lg:text-base">
                            Preferred Date:
                          </div>
                          <div className="pl-2 text-sm lg:text-base font-base">
                            {groupedInquiries[groupId][0].serviceDate}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-sm font-bold lg:text-base">
                            Preferred Time:
                          </div>
                          <div className="pl-2 text-sm lg:text-base font-base">
                            {groupedInquiries[groupId][0].serviceTime}
                          </div>
                        </div>
                      </div>

                      {/* Toggle Button */}
                      <div className="flex flex-col justify-between">
                        <div className="flex self-start">
                          {allAccepted && (
                            <Stripe
                              stripeKey="pk_test_51PFvzpH1kBStibFhDlZ2WsGR5xsss4BnVDhILzyHYGPAxnBDFACabeDWTeVkcxTS2WISZVTJvxAckVb4r5vGYtl700amqeVqDF"
                              token={(token) =>
                                handleGroupPayment(token, group)
                              }
                              amount={totalPrice * 100}
                              description={`Payment for group ID: ${groupId}`}
                              currency="AUD"
                              closed={() => setIsSubmitting(false)}
                            >
                              <button
                                className="w-32 py-2 mt-2 font-semibold text-white bg-[#002252] rounded-full"
                                disabled={isSubmitting}
                                onClick={() => setIsSubmitting(true)}
                              >
                                {isSubmitting
                                  ? "Processing..."
                                  : "Pay With Card"}
                                {/* (Total: ${totalPrice}) */}
                              </button>
                            </Stripe>
                          )}
                        </div>
                        <div className="align-bottom mt-2 flex justify-end">
                          <button
                            className="px-3"
                            onClick={() => toggleDropdown(groupId)}
                          >
                            {expandedGroup[groupId] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Dropdown for Inquiries */}
                    {expandedGroup[groupId] && (
                      <div className="mt-4">
                        {groupedInquiries[groupId].map(
                          (customerData, index) => (
                            <div
                              key={index}
                              className="flex flex-col p-2 mt-2 bg-gray-100 rounded-lg"
                            >
                              {/* Individual Inquiry Details */}
                              <div className="flex flex-row justify-between">
                                <div>
                                  <div className="flex flex-row items-center">
                                    <div className="text-sm font-bold lg:text-base">
                                      Order ID:
                                    </div>
                                    <div className="pl-2 text-sm lg:text-base font-base">
                                      {customerData.customerInquiryId}
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center">
                                    <div className="text-sm font-bold lg:text-base">
                                      Service Type:
                                    </div>
                                    <div className="pl-2 text-sm lg:text-base font-base">
                                      {customerData.type}
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center mt-2">
                                    <div className="text-sm font-bold lg:text-base">
                                      Status:
                                    </div>
                                    <div className="ml-2 px-3 py-0.5 text-sm font-bold lg:text-base font-base bg-yellow-700 rounded-full">
                                      {customerData.overallStatus}
                                    </div>
                                  </div>
                                </div>

                                {/* View Details Button */}
                                <div className="flex items-center">
                                  <button
                                    className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
                                    onClick={() =>
                                      openDetailsPopup(
                                        customerData.technicianId,
                                        customerData.overallStatus,
                                        customerData
                                      )
                                    }
                                  >
                                    View Details
                                  </button>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {showDetailsPopup && (
                    <CustomerViewDetailsPopup
                      closeDetailsPopup={closeDetailsPopup}
                      selectedTechnician={selectedTechnician}
                      overallStatus={currentStatus}
                      selectedCustomer={selectedCustomer}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <Typography
          variant="h6"
          color="error"
          align="center"
          sx={{ marginTop: 2 }}
        >
          Your account is inactive. Please verify your email to proceed.
        </Typography>
      )}
      <br></br>
    </div>
  );
}

export default CustomerDashboardPage;
