import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "../../components/imageModal/ImageModal";
import CircularProgress from "@mui/material/CircularProgress";
import {
  request,
  setAuthHeader,
  axiosInstance1,
  getAuthToken,
} from "../../config/axiosConfig";
import Swal from "sweetalert2";
import { Grid, Typography } from "@mui/material";
import debounce from "lodash.debounce";

export default function Instructions() {
  const [technicians, setTechnicians] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [updatingTechnicianId, setUpdatingTechnicianId] = useState(null);
  const [dataLoading, setDataLoading] = useState(false); // Add loading state
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const size = 8;

  const bottomRef = useRef(null);

  const openModal = useCallback((imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setModalImageSrc("");
  }, []);

  useEffect(() => {
    fetchTechnicians(page);
  }, [page, refreshTable]);

  const prevDataLoadingRef = useRef(false);

  useEffect(() => {
    if (!prevDataLoadingRef.current && dataLoading && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
    prevDataLoadingRef.current = dataLoading;
  }, [dataLoading]);

  const fetchTechnicians = async (pageNumber) => {
    try {
      setLoading(pageNumber === 0);
      setDataLoading(pageNumber !== 0);
      const response = await request(
        axiosInstance1,
        "GET",
        "/paginatedTechnicians",
        null,
        { page: pageNumber, size: size }
      );
      const newTechnicians = response.data.content;
      setHasMore(newTechnicians.length > 0);
      setTechnicians((prevTechnicians) =>
        pageNumber === 0
          ? newTechnicians
          : [...prevTechnicians, ...newTechnicians]
      );
    } catch (error) {
      console.error("Error fetching technicians:", error);
    } finally {
      setLoading(null);
      setDataLoading(null);
    }
  };

  const debouncedScrollHandler = useMemo(
    () =>
      debounce((e) => {
        const tolerance = 1;
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <=
          e.target.clientHeight + tolerance;
        if (bottom && !dataLoading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 300),
    [dataLoading, hasMore]
  );

  useEffect(() => {
    return () => {
      debouncedScrollHandler.cancel();
    };
  }, [debouncedScrollHandler]);

  const setActiveStatus = async (technicianId) => {
    try {
      setAuthHeader(getAuthToken());

      await request(axiosInstance1, "PUT", `/activeTechnician/${technicianId}`);

      setRefreshTable(!refreshTable);
      setUpdatingTechnicianId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const setInActiveStatus = async (technicianId) => {
    try {
      setAuthHeader(getAuthToken());

      await request(
        axiosInstance1,
        "PUT",
        `/inactiveTechnician/${technicianId}`
      );

      setRefreshTable(!refreshTable);
      setUpdatingTechnicianId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (statusAction, technicianId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to change the status to ${
        statusAction === "activate" ? "Active" : "Inactive"
      }.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      setUpdatingTechnicianId(technicianId);
      if (result.isConfirmed) {
        if (statusAction === "activate") {
          setActiveStatus(technicianId);
        } else {
          setInActiveStatus(technicianId);
        }

        Swal.fire(
          "Status Changed!",
          `The technician's status has been changed to ${
            statusAction === "activate" ? "Active" : "Inactive"
          }.`,
          "success"
        );
      } else {
        setUpdatingTechnicianId(null);
      }
    });
  };

  const openDetailsPopup = useCallback((technician) => {
    setSelectedTechnician(technician);
    setShowDetailsPopup(true);
  }, []);

  const closeDetailsPopup = useCallback(() => {
    setShowDetailsPopup(false);
  }, []);

  const TechnicianImage = React.memo(({ label, imageSrc, onClick }) => (
    <div className="flex flex-col w-1/3 h-full">
      <div className="mb-2 text-base font-semibold">{label}</div>
      {imageSrc && (
        <img
          src={`data:image/jpeg;base64,${imageSrc}`}
          alt={label}
          className="w-auto rounded cursor-pointer h-4/5"
          onClick={() => onClick(`data:image/jpeg;base64,${imageSrc}`)}
        />
      )}
    </div>
  ));

  const TechnicianDetail = React.memo(({ title, details }) => (
    <div className="flex flex-col w-1/3">
      <div className="mb-4 text-lg font-semibold">{title}</div>
      {details.map(({ label, value }) => (
        <div key={label} className="flex flex-col mb-4">
          <div className="text-base font-semibold">{label}</div>
          <div className="text-sm">{value}</div>
        </div>
      ))}
    </div>
  ));

  return (
    <>
      <div>
        <div className="flex flex-col px-4 pt-3 lg:px-16 ">
          <div className="p-2 text-xl font-bold text-gray-800 sm:text-2xl lg:text-2xl">
            Technician List
          </div>
        </div>
        {loading ? ( // Show spinner while loading
          <div className="flex items-center justify-center mt-10">
            <CircularProgress />
          </div>
        ) : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <TableContainer
              elevation={0}
              component={Paper}
              sx={{
                marginTop: 2,
                maxHeight: "560px",
                maxWidth: "95%",
                overflowY: "scroll",
              }}
              onScroll={debouncedScrollHandler}
            >
              <Table
                sx={{
                  minWidth: 650,
                  maxWidth: "86%",
                  marginInline: 10,
                  marginTop: 1,
                }}
                size="medium"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Name
              </TableCell> */}
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      ID
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Username
                    </TableCell>
                    {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Email
              </TableCell> */}
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Contact Number
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Type of Expertise
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {technicians.map((technician) => (
                    <TableRow key={technician.technicianId}>
                      {/* <TableCell component="th" scope="row" align="center">
                  {technician.firstName}
                </TableCell> */}
                      <TableCell align="center">
                        {technician.technicianId}
                      </TableCell>
                      <TableCell align="center">
                        {technician.username}
                      </TableCell>
                      {/* <TableCell align="center">
                  {technician.emailAddress}
                </TableCell> */}
                      <TableCell align="center">
                        {technician.contactNumber}
                      </TableCell>
                      <TableCell align="center">
                        {technician.typeOfExpertise}
                      </TableCell>
                      <TableCell align="center">
                        <p
                          className={
                            technician.status === "Active"
                              ? "bg-green-400 p-1 rounded-full font-semibold m-0 align-center"
                              : technician.status === "Inactive"
                              ? "bg-red-400 p-1 rounded-full font-semibold m-0 align-center"
                              : technician.status === "Pending"
                              ? "bg-yellow-700 p-1 rounded-full font-semibold m-0 align-center"
                              : ""
                          }
                        >
                          {technician.status}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <button
                          type="button"
                          className="px-3 py-2 mr-4 text-sm font-semibold text-black border-2 border-black rounded-full"
                          onClick={() => openDetailsPopup(technician)}
                        >
                          View Details
                        </button>
                        {technician.verified === "Inctive" && (
                            <Typography  color="error">
                            email not verified.    
                          </Typography>
                        )}
                        {technician.verified === "Active" && (
                          <>
                            {technician.status === "Pending" && (
                              <button
                                type="button"
                                className="w-32 py-2 font-semibold text-white bg-[#008000] rounded-full"
                                onClick={() =>
                                  handleStatusChange("activate", technician.technicianId)
                                }
                                disabled={updatingTechnicianId === technician.technicianId}
                              >
                                {updatingTechnicianId === technician.technicianId
                                  ? "Approving"
                                  : "Approve"}
                              </button>
                            )}
                            {technician.status === "Active" && (
                              <button
                                type="button"
                                className="w-32 py-2 font-semibold text-white bg-[#FF0000] rounded-full"
                                onClick={() =>
                                  handleStatusChange("inactivate", technician.technicianId)
                                }
                                disabled={updatingTechnicianId === technician.technicianId}
                              >
                                {updatingTechnicianId === technician.technicianId
                                  ? "Updating"
                                  : "Inactive"}
                              </button>
                            )}
                            {technician.status === "Inactive" && (
                              <button
                                type="button"
                                className="w-32 py-2 font-semibold text-white bg-[#008000] rounded-full"
                                onClick={() =>
                                  handleStatusChange("activate", technician.technicianId)
                                }
                                disabled={updatingTechnicianId === technician.technicianId}
                              >
                                {updatingTechnicianId === technician.technicianId
                                  ? "Updating"
                                  : "Active"}
                              </button>
                            )}
                          </>
                        )}

                      </TableCell>
                    </TableRow>
                  ))}
                  {dataLoading && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                        <Typography variant="body2">Loading Data...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <tr ref={bottomRef}></tr>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </div>
      <div></div>

      {showDetailsPopup && (
        <div className="fixed inset-0 flex items-center justify-center mt-5 bg-gray-500 bg-opacity-75">
          <div className="flex flex-col lg:w-[900px] w-full h-[550px] p-4 bg-gray-100 rounded shadow-lg">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closeDetailsPopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-center mb-4 text-2xl font-bold">
              Technician Details
            </div>
            <div className="flex flex-col w-full h-full gap-4 overflow-auto">
              <div className="flex flex-col items-center w-full gap-5 p-4 bg-white rounded shadow lg:flex-row lg:h-1/2">
                <TechnicianImage
                  label="Profile Picture"
                  imageSrc={selectedTechnician.technicianPhoto}
                  onClick={openModal}
                />
                <TechnicianImage
                  label="Signature"
                  imageSrc={selectedTechnician.technicianSignature}
                  onClick={openModal}
                />
                <TechnicianImage
                  label="License Copy"
                  imageSrc={selectedTechnician.licenseCopy}
                  onClick={openModal}
                />
              </div>
              <div className="flex flex-col items-center w-full gap-4 p-2 bg-white rounded shadow lg:p-4 lg:flex-row">
                <TechnicianDetail
                  title="Personal"
                  details={[
                    {
                      label: "First Name",
                      value: selectedTechnician.firstName,
                    },
                    { label: "Last Name", value: selectedTechnician.lastName },
                    { label: "Username", value: selectedTechnician.username },
                    {
                      label: "Email Address",
                      value: selectedTechnician.emailAddress,
                    },
                    {
                      label: "Contact Number",
                      value: selectedTechnician.contactNumber,
                    },
                  ]}
                />
                <TechnicianDetail
                  title="Residence"
                  details={[
                    { label: "Address", value: selectedTechnician.address },
                    { label: "City", value: selectedTechnician.city },
                    { label: "State", value: selectedTechnician.state },
                    {
                      label: "Postal Code",
                      value: selectedTechnician.postalCode,
                    },
                  ]}
                />
                <TechnicianDetail
                  title="Expertise"
                  details={[
                    {
                      label: "Type of Expertise",
                      value: selectedTechnician.typeOfExpertise,
                    },
                    {
                      label: "License Number",
                      value: selectedTechnician.licenseNumber,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        imageSrc={modalImageSrc}
        onClose={closeModal}
      />
    </>
  );
}
