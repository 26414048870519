import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function RegistrationMenu() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/technicianReg"); // Navigate to /technicianReg
  };

  const buttonStyle = {
    color: 'black',
    fontWeight: "bold",
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={undefined}
        aria-haspopup="true"
        aria-expanded={undefined}
        onClick={handleClick}
        style={buttonStyle} // Apply button style
      >
        Technician Registration
      </Button>
    </div>
  );
}
